import React, { useEffect, useRef, useState } from 'react';
import { Badge, Box, Button, Group, Radio, Text } from '@mantine/core';
import {
  IconCaretLeftFilled,
  IconCaretRightFilled,
  IconCheck,
  IconChevronLeft,
  IconChevronRight,
  IconExclamationCircle,
  IconX,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { discussWithAi } from './discussWithAi';
import { callPOSTAPI } from '../../utils/NetworkUtil';
import { userStore } from '../../stores/userStore';
import { ReactComponent as MeaningBased } from '../../assets/images/meaning_based.svg';
import { Howl } from 'howler';
import correctSoundFile from '../../assets/raw/correct.mp3';
import wrongSoundFile from '../../assets/raw/wrong.mp3';
import ReportModaContent from '../../molecules/ReportQuestion';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useTheme } from '../../context/themeProvider';
import { ArrowLeftDarkIcon, ArrowLeftFilled, ArrowRightDarkIcon, ArrowRightFilled } from '../../assets/images';

const PrelimsContent = ({ prelimsQuestions }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionStates, setQuestionStates] = useState(
    prelimsQuestions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null }))
  );
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const containerRef = useRef(null);
  const currentQuestionRef = useRef(null);
  const navigate = useNavigate();
  const authUserDetails = userStore((state) => state.userData);
  const windowHeight = window.innerHeight;
  const isMobile = window.innerWidth <= 2500; // Adjust for mobile view
  const isResponsiveMobiles = window.innerHeight <= 900 && window.innerWidth <= 400;
  const { colors, colorScheme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [reportData, setReportData] = useState('');
  const [reportDetails, setReportDetails] = useState('');
  const [isReportSubmitted, setIsReportSubmitted] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleReportOpen = () => setIsOpen(true);

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsReportSubmitted(false); // Reset the submission state
  };

  const handleReportSubmit = (option, comment) => {
    setIsReportSubmitted(true);
    // Handle the report submission logic here
    setTimeout(() => handleCloseModal(), 3000); // Close modal after showing success animation
  };

  // Initialize sounds
  const correctSound = new Howl({
    src: [correctSoundFile], // Add the correct answer sound file path
  });
  const wrongSound = new Howl({
    src: [wrongSoundFile], // Add the wrong answer sound file path
  });

  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      const questionIndex = currentQuestionIndex - 1;
      setCurrentQuestionIndex(questionIndex);
      if (questionStates[questionIndex].isSubmitted) {
        setSelectedAnswerIndex(questionStates[questionIndex].answerIndex);
      }
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < prelimsQuestions.length - 1) {
      const questionIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(questionIndex);
      if (questionStates[questionIndex].isSubmitted) {
        setSelectedAnswerIndex(questionStates[questionIndex].answerIndex);
      }
    }
  };

  const handleOptionChange = (value) => {
    if (!questionStates[currentQuestionIndex].isSubmitted) {
      const selectedIndex = prelimsQuestions[currentQuestionIndex].options.findIndex((option) => option === value);
      setSelectedAnswerIndex(selectedIndex);
      setQuestionStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[currentQuestionIndex].selectedOption = value;
        newStates[currentQuestionIndex].answerIndex = selectedIndex;
        return newStates;
      });
    }
    setSelectedOption(value);
  };

  const handleSubmit = () => {
    const currentQuestion = prelimsQuestions[currentQuestionIndex];

    // Ensure question_id and correct_answer are valid
    if (!currentQuestion || typeof currentQuestion.question_id === 'undefined' || typeof currentQuestion.correct_answer === 'undefined') {
      console.error('Invalid question data. Cannot submit.');
      return;
    }

    const correctAnswerIndex = currentQuestion.correct_answer;
    const selectedAnswerIndex = questionStates[currentQuestionIndex].answerIndex;
    const isAnswerCorrect = selectedAnswerIndex === correctAnswerIndex;

    setQuestionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[currentQuestionIndex].isSubmitted = true;
      newStates[currentQuestionIndex].isCorrect = isAnswerCorrect;
      return newStates;
    });

    if (isAnswerCorrect) {
      correctSound.play();
    } else {
      wrongSound.play();
    }

    // Convert all necessary fields to strings to avoid the int-related error
    const payload = {
      question_id: String(currentQuestion.question_id), // Convert to string
      username: String(authUserDetails?.username), // Already a string
      answer: selectedAnswerIndex !== null ? String(selectedAnswerIndex) : null, // Convert to string if not null
      correct_answer: String(correctAnswerIndex), // Convert to string
      category: String(currentQuestion.category), // Already a string
    };

    // Validate the payload before making the API call
    if (payload.question_id && payload.answer !== null && payload.correct_answer !== null) {
      callPOSTAPI(
        `${process.env.REACT_APP_BASE_URL}/api/pyq-question-submit/`,
        JSON.stringify(payload),
        (resp) => {
          console.log('Answer submitted successfully', resp);
        },
        (error) => {
          console.error('Error submitting answer', error);
        }
      );
    } else {
      console.error('Invalid payload:', payload); // Add error log if the payload is invalid
    }
  };

  const [marginLeft, setMarginLeft] = useState('7rem'); // Default value for desktop

  // Update marginLeft based on screen size
  useEffect(() => {
    const updateMarginLeft = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 375) {
        setMarginLeft('1rem'); // For small screens (mobile)
      } else if (screenWidth < 450) {
        setMarginLeft('1rem'); // For small screens (mobile)
      } else if (screenWidth < 500) {
        setMarginLeft('1rem'); // For small screens (mobile)
      } else if (screenWidth >= 600 && screenWidth < 768) {
        setMarginLeft('1rem'); // For tablets
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setMarginLeft('1rem'); // For small laptops
      } else {
        setMarginLeft('1rem'); // For desktops and larger screens
      }
    };

    // Call the function once to set the margin initially
    updateMarginLeft();

    // Add event listener to update on window resize
    window.addEventListener('resize', updateMarginLeft);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateMarginLeft);
    };
  }, []);

  const QuestionNavigation = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '7px',
      }}
    >
      <img
        src={colorScheme === 'dark' ? ArrowLeftDarkIcon : ArrowLeftFilled}
        className={`rounded-lg border bg-cardBackground border-border p-[7px_5px] 
          ${currentQuestionIndex === 0
            ? 'text-gray cursor-not-allowed opacity-50'
            : `text-${colors.arrowColor} cursor-pointer`
          }`}
        onClick={currentQuestionIndex === 0 ? null : handlePrevQuestion}
        alt="Arrow Left"
      />

      <div
        ref={containerRef}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '10px',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          flex: 1,
          margin: '0 10px',
        }}
      >
        {prelimsQuestions.map((_, index) => (
          <Text
            key={index}
            data-index={index}
            style={{
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              cursor: 'pointer',
              flexShrink: 0,
              backgroundColor: index === currentQuestionIndex
                ? (colorScheme === 'dark' ? '#2A2E4F' : '#EBF3FF')
                : 'transparent',
              color: index === currentQuestionIndex ? '#6374FA' : '#1A1A1As',
              fontSize: '14px',
              fontFamily: 'Poppins',
              fontWeight: '500',
            }}
            onClick={() => setCurrentQuestionIndex(index)}
          >
            Q{index + 1}
          </Text>
        ))}
      </div>
      <img
        src={colorScheme === 'dark' ? ArrowRightDarkIcon : ArrowRightFilled}
        className={`rounded-lg border bg-cardBackground border-border p-[7px_5px] 
            ${currentQuestionIndex === 0
            ? 'text-gray cursor-not-allowed'
            : `text-${colors.arrowColor} cursor-pointer`
          }`}
        onClick={currentQuestionIndex === prelimsQuestions.length - 1 ? null : handleNextQuestion}
        alt="Arrow Right"
      />

    </div>
  );

  useEffect(() => {
    if (containerRef.current) {
      // Ensure the scroll starts at the very beginning of the container on mount
      containerRef.current.scrollTo({
        left: 0, // Scroll all the way to the left
        behavior: 'auto', // No smooth scroll for initial load
      });
    }
  }, []);

  useEffect(() => {
    if (currentQuestionRef.current && containerRef.current) {
      const currentEl = currentQuestionRef.current;
      const containerEl = containerRef.current;

      const offsetLeft = currentEl.offsetLeft;
      const containerScrollLeft = containerEl.scrollLeft;
      const containerWidth = containerEl.clientWidth;

      // Ensure the current question is visible, scrolling if needed
      if (offsetLeft < containerScrollLeft || offsetLeft + currentEl.offsetWidth > containerScrollLeft + containerWidth) {
        containerEl.scrollTo({
          left: offsetLeft - containerWidth / 6, // Adjust for better alignment to center
          behavior: 'smooth',
        });
      }

      // Ensure the first question (Q1) is always visible
      if (currentQuestionIndex === 0) {
        containerEl.scrollTo({
          left: 0, // Scroll to the start
          behavior: 'smooth',
        });
      }
    }
  }, [currentQuestionIndex]);

  const handleDiscussWithAi = (question, selectedOption) => {
    const aiTutorParams = discussWithAi(question, selectedOption);
    navigate('/chat/new', { state: { aiTutorParams } });
    MixpanelEvent('discuss_with_ai', { source: 'pyq_search' });
    sendPostHogEvent('discuss_with_ai', { source: 'pyq_search' });
  };

  const [containerHeight, setContainerHeight] = useState('auto');

  useEffect(() => {
    const updateHeight = () => {
      const viewportHeight = window.innerHeight;

      // Get the pixel equivalent of 9rem by multiplying by the root font-size
      const remInPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
      const marginToSubtract = 25 * remInPixels; // Convert 9rem to pixels

      const calculatedHeight = viewportHeight - marginToSubtract;
      setContainerHeight(`${calculatedHeight}px`);
    };

    // Update the height when the component mounts and on window resize
    updateHeight();
    window.addEventListener('resize', updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <div class="min-h-screen h-full flex flex-col relative bg-background">

      {prelimsQuestions.length > 0 ? (
        <>
          <QuestionNavigation />
          <div class="relative border border-border rounded-lg m-1 h-[calc(100dvh-180px)] overflow-hidden bg-cardBackground mb-20">
            <div
              style={{
                height: '83%',
                overflowY: 'auto',
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                WebkitOverflowScrolling: 'touch',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                padding: '15px',
              }}
            >
              <div className="mb-3 flex gap-2">
                <div
                  className={`${colorScheme === 'dark' ? 'bg-[#404044]' : 'bg-[#F3F3F3]'
                    } border border-border text-gray-500 rounded px-2 py-1 text-sm font-medium shadow-md`}
                >
                  {prelimsQuestions[currentQuestionIndex]?.pyq_year}
                </div>
                <div
                  className={`${colorScheme === 'dark' ? 'bg-[#404044]' : 'bg-[#F3F3F3]'
                    } border border-border text-gray-500 rounded px-2 py-1 text-sm font-medium shadow-md`}
                >
                  {prelimsQuestions[currentQuestionIndex]?.pyq_exam}
                </div>
                <div
                  className={`${colorScheme === 'dark' ? 'bg-[#404044]' : 'bg-[#F3F3F3]'
                    } border border-border text-gray-500 rounded px-2 py-1 text-sm font-medium shadow-md`}
                >
                  {prelimsQuestions[currentQuestionIndex]?.category}
                </div>
              </div>



              <div className="text-left font-poppins font-medium text-text mb-5">
                {prelimsQuestions[currentQuestionIndex]?.question.includes('\n')
                  ? prelimsQuestions[currentQuestionIndex].question.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))
                  : prelimsQuestions[currentQuestionIndex]?.question}
              </div>


              <div className="flex flex-col gap-2">
                {prelimsQuestions[currentQuestionIndex]?.options.map((option, index) => {
                  const currentState = questionStates[currentQuestionIndex];
                  const isSubmitted = currentState?.isSubmitted;
                  const isCorrectAnswer = index === prelimsQuestions[currentQuestionIndex].correct_answer;
                  const isSelectedAnswer = index === currentState?.answerIndex;

                  return (
                    <div
                      key={index}
                      onClick={() => !isSubmitted && handleOptionChange(option)}
                      className={`
                        rounded-lg p-4 cursor-pointer border flex items-center gap-1.5 
                        ${isSubmitted
                          ? isCorrectAnswer
                            ? 'bg-[#2F6545] border-[#2F6545]'
                            : isSelectedAnswer
                              ? 'bg-[#9F4749] border-[#9F4749]'
                              : 'bg-cardBackground border-border'
                          : isSelectedAnswer
                            ? 'bg-selectedOptionBackground border-selectedOptionBackground'
                            : 'bg-cardBackground border-border'
                        }
                      `}
                    >
                      <div
                        style={{
                          width: '24px',
                          height: '24px',
                          borderRadius: '50%',
                          backgroundColor: isSubmitted ? (isCorrectAnswer ? '#34d399' : isSelectedAnswer ? '#ff9999' : 'transparent') : 'transparent',
                          border: isSubmitted && (isCorrectAnswer || isSelectedAnswer) ? 'none' : '2px solid #6374FA',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {isSelectedAnswer && !isSubmitted && (
                          <div
                            style={{
                              width: '14.5px',
                              height: '14.5px',
                              backgroundColor: '#6374FA',
                              borderRadius: '50%',
                            }}
                          />
                        )}
                        {isSubmitted &&
                          (isCorrectAnswer ? <IconCheck size={14} color="white" /> : isSelectedAnswer ? <IconX size={14} color="white" /> : null)}
                      </div>

                      <Text
                        style={{
                          color: '#6374FA',
                          width: '19px',
                          textAlign: 'left',
                          fontSize: '16px',
                          fontFamily: 'Poppins',
                          fontWeight: '500',
                        }}
                      >
                        {String.fromCharCode(65 + index)}
                      </Text>

                      <Text className="flex-1 text-text text-base font-poppins text-left font-medium">
                        {option}
                      </Text>

                    </div>
                  );
                })}
                {/* Footer */}
                <div className="flex justify-between p-4 shadow-[0px_-4px_24px_0px_rgba(0,0,0,0.12)] w-full bg-cardBackground border border-border absolute bottom-0 left-0 right-0 z-50 box-border mb-4">
                  <button
                    className="border border-[#D95353] text-[#D95353] flex items-center justify-center gap-1 py-2 px-4 rounded-full font-medium  focus:outline-none"
                    onClick={handleReportOpen}
                  >
                    <IconExclamationCircle size={16} />
                    Report
                  </button>

                  {!questionStates[currentQuestionIndex].isSubmitted ? (
                    <button
                      disabled={!questionStates[currentQuestionIndex].selectedOption}
                      className={`${questionStates[currentQuestionIndex].selectedOption
                        ? (colorScheme === 'dark' ? 'bg-white text-black' : 'bg-black text-white')
                        : 'bg-transparent text-[#B9B9B9] cursor-not-allowed border border-[#D1D1D1] hover:bg-transparent transition-all duration-300 opacity-50'
                        } rounded-full py-2 px-6 h-[2.5rem] text-sm font-medium`}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>


                  ) : (
                    <button
                      className="bg-white text-black border border-black rounded-full py-2 px-6 h-[2.5rem] text-sm font-medium hover:bg-[#F3F3F3] transition duration-300"
                      onClick={() => handleDiscussWithAi(prelimsQuestions[currentQuestionIndex], selectedAnswerIndex)}
                    >
                      Discuss with AI
                    </button>
                  )}
                </div>


              </div>
            </div>
          </div>
        </>
      ) : (
        <Text>No questions available</Text>
      )}
      <ReportModaContent
        isOpen={isOpen}
        closeModal={handleCloseModal}
        resportData={reportData}
        setResportData={setReportData}
        details={reportDetails}
        onChange={(e) => setReportDetails(e.currentTarget.value)}
        handleResportSubmit={handleReportSubmit}
        isReportSubmited={isReportSubmitted}
        isMobile={isMobile}
      />
    </div>
  );
};

export default PrelimsContent;
