import React, { useState, useEffect } from 'react';
import { CrownGeneral, VirsusBlue } from '../../../assets/images';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../../stores/userStore';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';
import { useTheme } from '../../../context/themeProvider';

const FriendsMatching = ({ currentUser, opponent, topic, isAccepted }) => {
  const userData = userStore((state) => state.userData);
  const [matchStatus, setMatchStatus] = useState('waiting');
  const navigate = useNavigate();
  const isResponsiveMobiles = window.innerHeight <= 900 && window.innerWidth <= 400;
  const {colorScheme } = useTheme();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (matchStatus === 'waiting') {
        setMatchStatus('timeout');
      }
    }, 120000);

    return () => clearTimeout(timeoutId);
  }, [matchStatus]);

  useEffect(() => {
    if (matchStatus === 'timeout') {
      const redirectId = setTimeout(() => {
        navigate('/duel');
      }, 3000); 

      return () => clearTimeout(redirectId); 
    }
  }, [matchStatus, navigate]);


  useEffect(() => {
    if (isAccepted) {
      setMatchStatus('accepted');
    }
  }, [isAccepted]);

  const handleCancel = () => {
    MixpanelEvent('duel_cancel', {
      category: topic,
      mode: 'friends',
      cancel_time: 'pre_initiate',
    });
    sendPostHogEvent('duel_cancel', {
      category: topic,
      mode: 'friends',
      cancel_time: 'pre_initiate',
    });
    navigate('/duel');
  };

  const getInitial = (name) => {
    if (!name) return '-';
    return name.charAt(0).toUpperCase();
  }

  const getStatusText = () => {
    switch (matchStatus) {
      case 'waiting':
        return 'Waiting for Friend to Accept';
      case 'accepted':
        return 'Invite Accepted';
      case 'timeout':
        return <span className="text-[#FF4B4B]">Opponent didn't Join</span>;
      default:
        return 'Waiting for Friend to Accept';
    }
  };


  return (
    <div className="text-center font-sans text-text flex flex-col items-center h-[100dvh] bg-background overflow-y-auto scrollbar-none">
      <div className={`text-[#747474] font-medium text-sm ${isResponsiveMobiles ? 'mt-4' : 'mt-10'}`}>
        DUEL WITH FRIENDS
      </div>
      
      <div className="my-[5px] font-bold text-[22px] text-text">
        {getStatusText()}
      </div>

      <div className={`flex flex-col justify-center items-center p-5 rounded-2xl my-6 w-[90%]  min-h-[400px]
          ${colorScheme === 'dark' ? 'bg-[#1B3260]' : 'bg-[#EBF3FF]'}`}
              >  
        <div className="text-center my-2.5 relative w-full">
          <div className="relative w-16 h-16 mx-auto bg-[#f0f0f0] rounded-full flex justify-center items-center">
            {currentUser.photo ? (
              <img
                src={currentUser.photo}
                alt="User"
                className="w-full h-full rounded-[25%]"
              />
            ) : (
              <div className="w-full h-full rounded-[25%] bg-[#E1EBFF] text-[#555] text-2xl font-bold flex justify-center items-center">
                {getInitial(currentUser.name)}
              </div>
            )}
            <span className="absolute -top-2 -right-2 bg-[#6374FA] text-white px-2 py-0.5 rounded-2xl text-xs">
              Me
            </span>
          </div>

          <p className="font-bold text-base text-text my-2.5">
            {currentUser.name || userData.name}
          </p>

          {currentUser.elo && (
            <div className="flex items-center justify-center gap-1.5">
              <img src={CrownGeneral} alt="Crown" className="w-8" />
              <p className="text-xl text-text m-0">{currentUser.elo}</p>
            </div>
          )}
        </div>

        {matchStatus !== 'timeout' && (
          <>
            <div className="my-5">
              <img src={VirsusBlue} alt="VS" />
            </div>

            <div className="text-center my-2.5 relative w-full">
              {matchStatus === 'accepted' ? (
                <>
                  <div className="w-16 h-16 mx-auto">
                    {opponent.photo ? (
                      <img
                        src={opponent.photo}
                        alt="Opponent"
                        className="w-full h-full rounded-[25%]"
                      />
                    ) : (
                      <div className="w-full h-full rounded-[25%] bg-[#E1EBFF] text-[#555] text-2xl font-bold flex justify-center items-center">
                        {getInitial(opponent.name)}
                      </div>
                    )}
                  </div>
                  <p className="font-bold text-base text-text my-2.5">
                    {opponent.name || 'Friend'}
                  </p>
                  <div className="flex items-center justify-center gap-1.5">
                    <img src={CrownGeneral} alt="Crown" className="w-8" />
                    <p className="text-xl text-text m-0">
                      {opponent.elo || 'N/A'}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-16 h-16 mx-auto">
                    <div className="w-full h-full rounded-[25%] bg-[#E1EBFF] text-[#555] text-2xl font-bold flex justify-center items-center">
                      -
                    </div>
                  </div>
                  <p className="mt-8 text-sm text-text">Waiting for Friend...</p>
                </>
              )}
            </div>
          </>
        )}
      </div>

      <div className="w-[90%] max-w-[300px]">
        <h3 className="m-0 mt-5 font-bold text-center text-base">Duel Tips</h3>
        <p className="text-[#747474] text-sm text-center tracking-wider leading-relaxed">
          Read the questions carefully: Take your time to thoroughly read each question before selecting an answer.
        </p>
      </div>

      <div className="flex flex-col w-full mt-4 h-full">
        {matchStatus === 'waiting' && (
          <div className="text-center">
            <button
              className="bg-white text-black w-[328px] h-[56px] px-[24px] py-[16px] rounded-[20px] border-[1px] border-[#070519] opacity-100 hover:opacity-100 transition"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        )}

        {matchStatus === 'accepted' && (
          <>
            <span className="spinner mr-2 w-5 h-5 border-4 border-white border-t-[#6374FA] rounded-full animate-spin" />
            Loading Duel...
          </>
        )}

        {matchStatus === 'timeout' && (
          <div className="text-red-500 text-center mt-10">
            <p>Opponent didn't accept invite</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FriendsMatching;