import React, { useState } from 'react';
import { DuelOnlineEmpty, DuelFriendsEmpty, HelpCircle } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import DuelInfoTips from './InfoTip/DuelOnlineInfoTip';
import DuelFriendsInfoTips from './InfoTip/DuelFriendsInfoTip';

export default function DuelFirstTime({ state }) {
  const navigate = useNavigate();
  const [showInfoTips, setShowInfoTips] = useState(false);

  const handleInfoTip = () => {
    setShowInfoTips(true);
  };

  const closeModal = () => {
    setShowInfoTips(false);
  };

  // Determine content based on the `state` prop
  const isFriends = state === 'friends';
  const title = isFriends ? 'Duel with Friends' : 'Duel Online';
  const description = isFriends
    ? 'Challenge your friends to a 1-on-1 UPSC prelims quizzes.'
    : 'Compete online against other UPSC aspirants in 1-on-1 UPSC prelims quizzes.';
  const imageSrc = isFriends ? DuelFriendsEmpty : DuelOnlineEmpty;
  const navigateTo = isFriends ? '/duel-friends' : '/online-match-game';

  return (
    <div className="flex items-center justify-center h-[60vh] sm:h-[70vh] bg-background">
      <div className="flex flex-col items-center justify-center max-w-md w-full px-4 py-4">
        <div className="flex flex-col text-center mt-4">
          <h2 className={`m-0 p-0 flex font-bold justify-center text-text text-3xl items-center`}>
            {title}
            <img src={HelpCircle} alt="Help icon" className="ml-2 w-5 h-5 cursor-pointer" onClick={handleInfoTip} />
          </h2>
          <p className={`text-text m-[5px]`}>{description}</p>
        </div>
        <div className="rounded-2xl w-full h-[224px] mt-4">
          <img src={imageSrc} alt="Content" className="w-full h-full object-cover rounded-2xl mb-[10px]" />
          <button className={`px-4 py-5 rounded-[24px] text-background border-none w-full bg-text`} onClick={() => navigate(navigateTo)}>
            Play Now
          </button>
        </div>
        {isFriends ? <DuelFriendsInfoTips opened={showInfoTips} onClose={closeModal} /> : <DuelInfoTips opened={showInfoTips} onClose={closeModal} />}
      </div>
    </div>
  );
}
