import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconCheck, IconInfoCircle, IconX } from '@tabler/icons-react';
import { ArrowLeft, ArrowLeftDark, BlueCrown, RedCrown, Timer, Versus2 } from '../../../assets/images';
import { Button, Divider, Flex, Group, Radio, Stack, Text, Textarea, Title } from '@mantine/core';
import ReportQuestionModal from './ReportQuestionModal';
import { useTheme } from '../../../context/themeProvider';
import ReconnectingWebSocket from 'reconnecting-websocket';
import Lottie from 'react-lottie';
import * as successAnimation from '../../../assets/json/report_success.json';
import { MarkdownWithLatex } from '../../../molecules/RichText/MarkdownLatex';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';
import correctSoundFile from '../../../assets/raw/correct.mp3';
import wrongSoundFile from '../../../assets/raw/wrong.mp3';
import FriendsMatching from '../MatchGame/FriendsMatching'

const DuelStart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { colors, colorScheme } = useTheme();
  const { bot, opponent, duel_mode, currentUser, topic, room_id } = location.state || {};

  const socketRef = useRef(null);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [loaderText, setLoaderText] = useState([]);
  const [timer, setTimer] = useState(60);
  const [userAnswered, setUserAnswered] = useState(false);
  const [opponentAnswered, setOpponentAnswered] = useState(false);
  const [botAnswerTime, setBotAnswerTime] = useState(null);
  const [botAnswer, setBotAnswer] = useState(null);
  const [gameEnded, setGameEnded] = useState(false);
  const userId = currentUser.user;
  const [answerStatus, setAnswerStatus] = useState(Array(5).fill(null));
  const [opponentAnswerStatus, setOpponentAnswerStatus] = useState(Array(5).fill(null));
  const [userCorrectAnswers, setUserCorrectAnswers] = useState(0);
  const [botCorrectAnswers, setBotCorrectAnswers] = useState(0);
  const [eloData, setEloData] = useState(null);
  const [userEloChange, setUserEloChange] = useState(0);
  const [botEloChange, setBotEloChange] = useState(0);
  const [isSpinnerLoader, setSpinnerLoader] = useState(false);
  const [opponentAnswer, setOpponentAnswer] = useState(null);
  const [opponentCorrectAnswers, setOpponentCorrectAnswers] = useState(0);
  const [hasExited, setHasExited] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const correctSound = new Audio(correctSoundFile);
  const wrongSound = new Audio(wrongSoundFile);
  const currentQuestion = questions[currentQuestionIndex];
  const isResponsiveMobile =
    window.innerHeight <= 700 && window.innerWidth <= 400 ? '23em' : window.innerWidth > 400 && window.innerWidth < 900 ? '26em' : '28em';
  const [opened, setOpened] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isReportModal, setReportModal] = useState(false);
  const isMobile = window.innerWidth <= 2500;
  const [details, setDetails] = useState('');
  const [reportData, setReportData] = useState('');
  const [isReportSubmited, setReportSubmit] = useState(false);
  const [selectedQuestion, setSelectedQuestionId] = useState('');
  const [opponentQuestionIndex, setOpponentQuestionIndex] = useState(0);
  const opponentCurrentQIndex = useRef(opponentQuestionIndex);
  const [showFriendsMatching, setShowFriendsMatching] = useState(false);

  useEffect(() => {
    opponentCurrentQIndex.current = opponentQuestionIndex;
  }, [opponentQuestionIndex]);

  const storeQuestionData = (question) => {
    const storedData = JSON.parse(localStorage.getItem('questionData')) || [];
    const updatedData = [
      ...storedData,
      {
        question_id: question.question_id,
        question: question.question,
        user_option: question.user_answer,
        user_answer: question.user_option,
        correct_answer: question.correct_answer,
        opponent_answer: question.opponent_answer,
        bot_time: question.bot_time,
        choices: [{ a: question.choices[0] }, { b: question.choices[1] }, { c: question.choices[2] }, { d: question.choices[3] }],
        remaining_time: timer,
        explanation: question.explanation || '',
      },
    ];
    const limitedData = updatedData.slice(-5);
    localStorage.setItem('questionData', JSON.stringify(limitedData));
  };

  const generateWebSocketUrl = (isBot) => {
    const baseUrl = `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/one-vs-one`;
    return isBot
      ? `${baseUrl}/bot/${userId}/${room_id}/?category=${topic}&render_type=text`
      : `${baseUrl}/${room_id}/${userId}/?category=${topic}&render_type=text`;
  };

  useEffect(() => {
    const isBotUser = bot && Object.keys(bot).length > 0;
    const wssUrl = generateWebSocketUrl(isBotUser);
    socketRef.current = new ReconnectingWebSocket(wssUrl);

    socketRef.current.onopen = () => {
      setStartTime(Date.now());
      console.log('WebSocket connected');
      if (isBotUser) {
        const message = {
          bot_data: bot,
          isBot: true,
          challenge: false,
          userId,
        };
        socketRef.current.send(JSON.stringify(message));
      }
      localStorage.setItem('user_current_question', 0);
    };
    const handleNewQuestion = () => {
      if (topic === 'csat') {
        setTimer(180);
      } else {
        setTimer(60);
      }
    };

    socketRef.current.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.question_id && data.question && data.options) {
          setTimeout(() => {
            setShowFriendsMatching(false);
          }, 3000);
          setQuestions((prev) => [
            ...prev,
            {
              id: data.question_id,
              text: data.question,
              options: data.options,
              correctAnswer: data.correct_answer,
              bot_time: data.bot_time,
              bot_answer: data.bot_answer,
              choices: [{ a: data.options[0] }, { b: data.options[1] }, { c: data.options[2] }, { d: data.options[3] }],
            },
          ]);
          handleNewQuestion();
          setAnswerStatus((prev) => [...prev, null]);
          setOpponentAnswerStatus((prev) => [...prev, null]);
          setUserAnswered(false);
          setOpponentAnswered(false);
        }
        if (data.message_type === 'answer') {
          const currentQuestionNumber = parseInt(localStorage.getItem('user_current_question')) || 0;

          const isCorrect = data.user_answer === data.correct_answer;

          if (data.sender_id === userId) {
            setAnswerStatus((prevStatus) => {
              const newStatus = [...prevStatus];
              newStatus[currentQuestionNumber] = isCorrect;
              return newStatus;
            });
            setUserAnswered(true);
          } else {
            setOpponentAnswer(data.user_answer);
            setOpponentAnswerStatus((prevStatus) => {
              const newStatus = [...prevStatus];
              newStatus[opponentCurrentQIndex.current] = isCorrect;
              return newStatus;
            });
            setOpponentQuestionIndex(opponentCurrentQIndex.current + 1);
            if (isCorrect) {
              setOpponentCorrectAnswers((prev) => prev + 1);
            }
            setOpponentAnswered(true);
          }
        } else {
          setAnswerStatus((prev) => [...prev, null]);
          setOpponentAnswerStatus((prev) => [...prev, null]);
        }
        if (data.message_type === 'elo' || data.message_type === 'end') {
          const userElo = data.elo[userId];
          const opponentId = Object.keys(data.elo).find((id) => id !== userId);
          const opponentElo = data.elo[opponentId];

          const userScoreChange = data.score[userId];
          const opponentScoreChange = data.score[opponentId];
          if (bot) {
            setEloData({ userElo, botElo: opponentElo });
          } else {
            setEloData({ userElo, opponentElo });
          }

          setUserEloChange(userScoreChange);
          setBotEloChange(opponentScoreChange);
          setGameEnded(true);
        }
        if (data.message_type === 'end') {
          setHasExited(true);
        }
      } catch (error) {
        console.error('Error parsing WebSocket data:', error);
      }
    };

    socketRef.current.onclose = () => {
      console.log('WebSocket closed');
    };

    socketRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      localStorage.removeItem('questionData');
      localStorage.removeItem('user_current_question');
    };
  }, [bot, currentUser, room_id, userId, topic]);

  const updateQuestionTracking = (questionIndex, userAnswer, opponentAnswer) => {
    localStorage.setItem('user_current_question', questionIndex);

    // Update the question data in localStorage
    const storedQuestions = JSON.parse(localStorage.getItem('questionData')) || [];
    if (storedQuestions[questionIndex]) {
      storedQuestions[questionIndex] = {
        ...storedQuestions[questionIndex],
        user_answer: userAnswer,
        opponent_answer: opponentAnswer,
      };
      localStorage.setItem('questionData', JSON.stringify(storedQuestions));
    }
  };

  useEffect(() => {
    if (gameEnded && eloData) {
      const competitorType = bot && Object.keys(bot).length > 0 ? 'bot' : 'real';
      MixpanelEvent('duel_completed', { category: topic, mode: 'online', competitor_type: competitorType });
      sendPostHogEvent('duel_completed', { category: topic, mode: 'online', competitor_type: competitorType });
      saveHistoryData(opponentCorrectAnswers)
        .then((responseData) => {
          navigate('/duel-results', {
            state: {
              userProfile: currentUser,
              botProfile: bot,
              userCorrectAnswers,
              botCorrectAnswers,
              userAnswerStatus: answerStatus,
              opponentAnswerStatus: opponentAnswerStatus,
              duel: responseData,
              userElo: eloData.userElo,
              botElo: eloData.botElo,
              userEloChange: userEloChange,
              botEloChange: botEloChange,
              hasExited,
            },
          });
        })
        .catch((error) => {
          console.error('Error while saving history data:', error);
        });
    }
  }, [gameEnded, eloData]);

  const handleExit = () => {
    const isBotUser = bot && Object.keys(bot).length > 0;
    const opponentId = isBotUser ? bot.id : opponent.user;
    const endTime = Date.now();
    const loadingTime = Math.round((endTime - startTime) / 1000);
    MixpanelEvent('duel_cancel', {
      category: topic,
      mode: 'online',
      loading_time: loadingTime,
      cancel_time: 'post_initiate',
      no_ques: currentQuestionIndex,
    });
    sendPostHogEvent('duel_cancel', {
      category: topic,
      mode: 'online',
      loading_time: loadingTime,
      cancel_time: 'post_initiate',
      no_ques: currentQuestionIndex,
    });
    setHasExited(true);
    if (socketRef.current) {
      const exitMessage = {
        message_type: 'end',
        id: opponentId,
        render_type: 'text',
      };
      socketRef.current.send(JSON.stringify(exitMessage));
    }
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const saveHistoryData = async (opponentCorrectAnswers) => {
    let userScore = userCorrectAnswers;
    const isBotUser = bot && Object.keys(bot).length > 0;
    const opponentScore = isBotUser ? botCorrectAnswers : opponentCorrectAnswers;
    const storedQuestions = JSON.parse(localStorage.getItem('questionData')) || [];

    if (storedQuestions.length === 0) {
      console.error('No question data available. Ensure questions are stored in local storage.');
      return;
    }

    const formattedQuestionData = storedQuestions.map((question) => {
      const formattedQuestion = {
        answer: question.correct_answer,
        choices: question.choices,
        category: topic,
        question: question.question,
        explanation: question.explanation,
        question_id: question.question_id,
        user_answer: question.user_answer || '',
        user_option: question.user_option || '',
        correct_answer: question.correct_answer,
        question_split: [],
        remaining_time: question.remaining_time,
      };
      return formattedQuestion;
    });

    const formattedOpponentQuestionData = storedQuestions.map((question, index) => ({
      question_id: question.question_id,
      user_option: question.opponent_answer,
      correct_answer: question.correct_answer,
    }));

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/postgame/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
        body: JSON.stringify({
          profile: currentUser.user,
          eloScore: Number(eloData?.userElo || currentUser.elo),
          quizScore: userEloChange,
          category: topic,
          questionData: formattedQuestionData,
          history_type: duel_mode ? 'duel_with_friend' : '1v1',
          victory: userScore > botEloChange,
          created_at: new Date().toISOString(),
          correct_answers: userScore,
          user_info: {
            elo: Number(eloData?.userElo || currentUser.elo),
            name: currentUser.name,
            image: currentUser.image,
            name: currentUser?.name || 'User',
            image: currentUser?.image || '',
          },
          opponent_info: {
            elo: bot ?
              Number(eloData?.botElo || bot.elo) :
              Number(eloData?.opponentElo || opponent?.elo || 1200),
            name: bot?.name || opponent?.name || 'Opponent',
            image: bot?.image || opponent?.image || '',
          },
          opponent_question_data: formattedOpponentQuestionData,
          user_correct_answer: userScore,
          opponent_correct_answer: opponentScore,
          render_type: 'text',
        }),
      });

      const json = await response.json();
      if (response.ok) {
        localStorage.removeItem('questionData');
        localStorage.removeItem('user_current_question');
        console.log('Post game data saved and localStorage cleared');
        return json;
      } else {
        console.error('Error saving post game data:', json);
        throw new Error(json);
      }
    } catch (err) {
      console.error('Error saving post game data', err);
      throw err;
    }
  };

  useEffect(() => {
    if (currentQuestion) {
      setBotAnswerTime(currentQuestion.bot_time);
      setBotAnswer(currentQuestion.bot_answer);
    }
  }, [currentQuestion]);

  const sendAnswerData = () => {
    let selectedIndex = currentQuestion.options.indexOf(selectedOption);
    if (selectedIndex === -1) {
      selectedIndex = 9;
    }
    const timeElapsed = topic === 'csat' ? 180 - timer : 60 - timer;
    const data = {
      time: timeElapsed,
      render_type: 'text',
      name: currentUser.name,
      answer: selectedIndex,
    };

    if (socketRef.current) {
      try {
        socketRef.current.send(JSON.stringify(data));
      } catch (error) {
        console.error('WebSocket send error:', error);
      }
    } else {
      console.error('WebSocket is not open');
    }
  };

  useEffect(() => {
    if (!questions.length || timer <= 0) return;

    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTime) => {
          const newTime = prevTime - 1;
          const botAnswerThreshold = topic === 'csat' ? 180 : 60;
          if (newTime === botAnswerThreshold - botAnswerTime && !opponentAnswered) {
            handleBotAnswer();
          }
          if (newTime === 0 && !userAnswered) {
            handleSubmit();
          }
          checkBothAnswered();
          return newTime;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer, botAnswerTime, userAnswered, opponentAnswered, topic]);

  useEffect(() => {
    if (timer === 1) {
      setUserAnswered(true);
      setAnswerStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[currentQuestionIndex] = false;
        return newStatus;
      });
      setIsSubmitted(true);
      setSpinnerLoader(false);
    }
  }, [timer, currentQuestionIndex]);

  const handleBotAnswer = () => {
    if (!opponentAnswered) {
      const opponentIsCorrect = botAnswer === currentQuestion.correctAnswer;

      setOpponentAnswerStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[currentQuestionIndex] = opponentIsCorrect;
        return newStatus;
      });

      if (opponentIsCorrect) {
        setBotCorrectAnswers((prev) => prev + 1);
      }

      setOpponentAnswered(true);
      checkBothAnswered();
    }
  };

  const checkBothAnswered = () => {
    const isBotOpponent = bot && Object.keys(bot).length > 0;

    if (userAnswered && (isBotOpponent ? opponentAnswered : true)) {
      updateQuestionTracking(currentQuestionIndex, selectedOption, opponentAnswer);

      const selectedIndex = currentQuestion.options.indexOf(selectedOption);
      const answerMaps = ['a', 'b', 'c', 'd'];

      const questionData = {
        question_id: currentQuestion.id,
        question: currentQuestion.text,
        user_option: selectedOption,
        user_answer: answerMaps[selectedIndex],
        correct_answer: answerMaps[currentQuestion.correctAnswer],
        opponent_answer: isBotOpponent ? answerMaps[currentQuestion.bot_answer] : answerMaps[opponentAnswer] || null,
        choices: currentQuestion.options,
        bot_time: isBotOpponent ? currentQuestion.bot_time : null,
        remaining_time: timer,
      };

      storeQuestionData(questionData);
      setTimer(0);
      let countdownValue = 3;
      sendAnswerData();

      const countdownInterval = setInterval(() => {
        setLoaderText(`Next Question in ${countdownValue} seconds...`);
        if (countdownValue <= 0) {
          setSpinnerLoader(true);
          setTimeout(() => {
            countdownValue = 3;
            setSpinnerLoader(false);
            clearInterval(countdownInterval);
            setShowLoader(false);
            setIsSubmitted(false);
            setUserAnswered(false);
            setOpponentAnswered(false);
            if (currentQuestionIndex < 4) {
              setCurrentQuestionIndex(currentQuestionIndex + 1);
              localStorage.setItem('user_current_question', currentQuestionIndex + 1);
              const newTimer = topic === 'csat' ? 180 : 60;
              setTimer(newTimer);
            } else {
              console.log('Quiz ended');
              setSpinnerLoader(true);
              setTimeout(() => {
                requestEloScore();
              }, 2000);
            }
          }, 1000);
        }
        countdownValue -= 1;
      }, 1000);
    }
  };

  const requestEloScore = () => {
    if (socketRef.current) {
      const isBotUser = bot && Object.keys(bot).length > 0;
      const userCompleted = currentQuestionIndex >= 4 && userAnswered;
      const opponentCompleted = isBotUser || (opponentQuestionIndex >= 4 && opponentAnswered);

      if (userCompleted && opponentCompleted) {
        const opponentId = isBotUser ? bot.id : opponent.user;
        if (socketRef.current.readyState === WebSocket.OPEN) {
          const exitMessage = {
            message_type: 'end',
            id: opponentId,
            render_type: 'text',
          };
          socketRef.current.send(JSON.stringify(exitMessage));
          console.log("ELO score request sent.");
        } else {
          console.error("WebSocket is not open. Current state:", socketRef.current.readyState);
        }
        setTimeout(() => {
          if (!eloData) {
            console.warn("ELO score not received within the wait time.");
          } else {
            console.log("ELO data received:", eloData);
          }
        }, 4000);
      }
    }
  };

  useEffect(() => {
    if (duel_mode) {
      setShowFriendsMatching(true);
    } else {
      setShowFriendsMatching(false);
    }
  }, [currentUser, opponent, bot]);

  const handleSubmit = () => {
    if (selectedOption) {
      setIsSubmitted(true);
      setShowLoader(true);
      if (!opponentAnswered) {
        setLoaderText('Waiting for Opponent to answer...');
      }

      const selectedIndex = currentQuestion.options.indexOf(selectedOption);
      const isCorrect = selectedIndex === currentQuestion.correctAnswer;

      setAnswerStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[currentQuestionIndex] = isCorrect;
        return newStatus;
      });

      if (isCorrect) {
        correctSound.play().catch((error) => console.log('Error playing sound:', error));
        setUserCorrectAnswers((prev) => prev + 1);
      } else {
        wrongSound.play().catch((error) => console.log('Error playing sound:', error));
      }

      setUserAnswered(true);
      checkBothAnswered();
    }
  };
  if (showFriendsMatching) {
    return (
      <FriendsMatching
        currentUser={currentUser}
        opponent={opponent}
        topic={topic}
        isAccepted={questions.length > 0}
      />
    );
  }


  const renderLoader = () => (
    <div>
    <button
  className={`absolute bottom-0 w-full py-6 border-none cursor-pointer flex justify-center items-center text-lg font-bold 
    ${
      colorScheme === 'dark'
        ? 'bg-[#171573] text-white' 
        : 'bg-blue-500 text-white'
    }`}
>
  <>
    <span className="border-8 border-t-8 border-gray-300 border-t-blue-500 mr-2 rounded-full w-8 h-8 animate-spin"></span>
    {loaderText}
  </>
</button>

    </div>
  );

  if (!currentQuestion) {
    const isBotUser = bot && Object.keys(bot).length > 0;
    const hasQuestions = questions && questions.length > 0;

    return (
      <div className="absolute top-1/2 left-1/2 h-screen w-full flex flex-col justify-center items-center mt-16 transform -translate-x-1/2 -translate-y-1/2 bg-background">
        <div className="border-8 border-t-8 border-gray-300 border-t-blue-500 rounded-full w-5 h-5 animate-spin"></div>
        {!isBotUser && hasQuestions ?
          <div className="text-text font-bold mt-4">
            Waiting for opponent answer.
          </div>
          : null}
      </div>
    );
  }

  const getInitial = (name) => {
    if (!name) return '-';
    return name.charAt(0).toUpperCase();
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setDetails('');
  };

  const handleReport = (prelimsQuestions) => {
    setIsOpen(true);
    setSelectedQuestionId(prelimsQuestions);
    setReportSubmit(false);
    setReportData('');
  };

  const handleResportSubmit = async () => {
    setReportSubmit(true);
    let postPayload = {
      flow: 'duel',
      question_id: selectedQuestion,
      user: userId,
      option: reportData,
      reason: '',
    };

    if (reportData === 'Other') {
      postPayload.reason = details;
    }
    MixpanelEvent('submit_feedback', {
      selection: reportData,
      question_id: selectedQuestion,
      category: topic,
      source: 'duel_quiz',
    });
    sendPostHogEvent('submit_feedback', {
      selection: reportData,
      question_id: selectedQuestion,
      category: topic,
      source: 'duel_quiz',
    });

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();
      setTimeout(() => {
        setIsOpen(false);
        setReportModal(false);
      }, 3000);
    } catch (error) { }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div>
      <Flex align="center" className="p-4 text-text bg-background">
        <img src={colorScheme === 'dark' ? ArrowLeftDark : ArrowLeft} alt="Arrow Left" className="cursor-pointer" onClick={() => setOpened(true)} />
        <text fz="xl" className="ml-4">
          Online Duel
        </text>
      </Flex>
      <div className="h-[75vh] md:h-[85vh] p-5 font-sans text-text max-w-[600px] mx-auto relative bg-background overflow-y-auto scrollbar-hidden">

        {/* Header Section */}
        <div className="border border-border rounded-lg py-4">
          <div className="flex justify-between items-center px-4">
            {/* Players Container */}
            <div className="flex items-center gap-2">
              {currentUser.photo ? (
                <img
                  src={currentUser.photo}
                  alt="User"
                  className="w-10 h-10 rounded-md"
                />
              ) : (
                <div className="w-10 h-10 rounded-md bg-blue-100 text-gray-700 flex items-center justify-center font-bold text-2xl">
                  {getInitial(currentUser.name)}
                </div>
              )}
              <div>
                <div className="font-bold">
                  {currentUser?.name ?
                    (currentUser.name.length > 12 ?
                      `${currentUser.name.slice(0, 12)}...` :
                      currentUser.name) :
                    'UserName'
                  }
                </div>
                <div className="text-sm text-text flex items-center gap-1">
                  <img src={BlueCrown} alt="Crown" className="w-4 h-4" />
                  <span>{currentUser?.elo || '1980'}</span>
                </div>
              </div>
            </div>

            {/* VS Wrapper */}
            <div className="relative inline-block w-10 h-10">
              <img src={Versus2} alt="VS" className="w-full h-full" />
              <div className="absolute top-1/2 left-1/2 w-[1.47px] h-20 bg-blue-100 transform -translate-x-1/2 -translate-y-1/2 rotate-[14deg] opacity-70 z-2"></div>
            </div>

            <div className="flex items-center gap-1.5">
            <div>
                <div className="font-bold text-right">
                  {bot?.name
                    ? bot.name.length > 12
                      ? `${bot.name.slice(0, 12)}...`
                      : bot.name
                    : opponent?.name
                      ? opponent.name.length > 12
                        ? `${opponent.name.slice(0, 12)}...`
                        : opponent.name
                      : 'Opponent'}
                </div>
                <div className="text-sm text-text flex items-center gap-1 justify-end">
                  <span>{bot?.elo || opponent?.elo || '1980'}</span>
                  <img src={RedCrown} alt="Crown" className="w-4 h-4" />
                </div>
              </div>
              {bot?.photo ? (
                <img src={bot.photo} alt="User" className="w-10 h-10 rounded-md" />
              ) : opponent?.photo ? (
                <img src={opponent.photo} alt="User" className="w-10 h-10 rounded-md" />
              ) : (
                <div className="w-10 h-10 rounded-md bg-blue-100 text-gray-700 flex items-center justify-center font-bold text-2xl">
                  {getInitial(bot?.name || opponent?.name || 'Opponent')}
                </div>
              )}
            </div>
          </div>
          {/* Divider */}
          <div className="my-4 border-t border-border"></div>

          <div className="flex justify-center gap-2">
            {answerStatus.slice(0, 5).map((status, index) => (
             <div
                key={index}
                className={`flex items-center justify-center w-5  h-5 rounded-full text-xs font-bold ${
                  status === null ? 'bg-transparent' : status ? 'bg-green-400' : 'bg-red-200'
                }`}
              >
                {status === null ? (
                  <span>
                    {index < 10 ? 0 : ''}
                    {index + 1}
                  </span>
                ) : status ? (
                  <IconCheck color={colorScheme === 'dark' ? '#000000' : '#ffffff'} size={14} />
                ) : (
                  <IconX color={colorScheme === 'dark' ? '#000000' : '#ffffff'} size={14} />
                )}
              </div>
            ))}

            <div style={{ width: '10%' }} />

            <div className="flex gap-2">
              {opponentAnswerStatus.slice(0, 5).map((status, index) => (
               <div
                  key={index}
                  className={`flex items-center justify-center w-5 h-5 rounded-full text-xs font-bold ${
                    status === null ? 'bg-transparent' : status ? 'bg-green-400' : 'bg-red-200'
                  }`}
                >
                  {status === null ? (
                    <span>
                      {index < 10 ? 0 : ''}
                      {index + 1}
                    </span>
                  ) : status ? (
                     <IconCheck color={colorScheme === 'dark' ? '#000000' : '#ffffff'} size={14} />
                ) : (
                  <IconX color={colorScheme === 'dark' ? '#000000' : '#ffffff'} size={14} />
                )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Current Question Info */}
        <div className="flex justify-between items-center my-4">
          <p className="text-sm font-bold text-text uppercase">
            {topic.toUpperCase()} • Q {currentQuestionIndex + 1}/5
          </p>
          {!isSubmitted && (
            <div className="flex items-center justify-center bg-cardBackground px-2 py-1 rounded-lg">
              <img src={Timer} alt="timer" className="w-6 h-6 mr-2" />
              <span className="text-base font-bold text-text">{timer}</span>
            </div>
          )}
        </div>

        {/* Question Text */}
        <div className="text-base font-bold mb-5 text-text">
          <MarkdownWithLatex content={currentQuestion.text} />
        </div>
        {/* Options Section */}
        <div className="flex flex-col gap-3 mb-5">
          {currentQuestion.options.map((option, index) => {
            const isSelected = selectedOption === option;
            return (
              <label
                key={index}
                className={`flex items-center p-5 rounded-lg gap-2 cursor-pointer text-center ${
                  isSelected ? 'border-none' : 'border border-border'
                } transition-all duration-200`}
                style={{
                  backgroundColor: isSelected
                    ? 'var(--selectedOptionBackground)'
                    : 'var(--background)',
                }}
              >
                <input
                  type="radio"
                  name="option"
                  value={option}
                  checked={isSelected}
                  onChange={() => handleOptionChange(option)}
                  disabled={isSubmitted}
                  className="mr-2 transform scale-150"
                  />
                  <div className="mt-1 flex items-center gap-2">
                  <span className="text-[#92A1FF] mr-1">
                    {String.fromCharCode(65 + index)}&nbsp;</span>
                    <div className="text-text text-base font-bold max-w-full text-left">
                    <MarkdownWithLatex content={option} />
                  </div>
                </div>
              </label>
            );
          })}
        </div>
      </div>
      {showLoader ? (
        renderLoader()
      ) : (
        <div
          className={`flex justify-between p-2.5 fixed bottom-0 gap-6 bg-background shadow-[0_-4px_10px_rgba(0,0,0,0.1)]`}
          style={{ width: isResponsiveMobile }}
        >
          <button
            className="flex items-center px-4 py-2.5 text-red-500 cursor-pointer text-sm font-bold  border-none"
            onClick={() => setReportModal(true)}
          >
            <IconInfoCircle style={{ marginRight: '4px' }} />
            Report
          </button>
          <button
            onClick={handleSubmit}
            className={`flex items-center justify-center w-3/5 p-5 rounded-2xl text-sm font-bold transition-all duration-200 
              ${selectedOption ? 'bg-[#070519] text-white cursor-pointer border-none' : 'bg-[#F3F3F3] text-[#B9B9B9] cursor-not-allowed border border-[#D1D1D1]'}
            `}
            disabled={isSubmitted}
          >

            Submit Answer
          </button>
        </div>
      )}
      {opened && (
        <div className="fixed top-0 left-0 w-full h-full bg-black/50 flex justify-center items-end z-50">
          <div className="bg-cardBackground rounded-t-xl w-full max-w-[400px] p-6 text-center shadow-lg">
            <h2 className="text-xl font-semibold mb-3 text-text">Exit Duel?</h2>
            <p className="text-text mb-6 text-sm leading-relaxed">
              Are you sure you want to exit the ongoing Duel? Your earned rating will be lost.
            </p>
            <div className="flex justify-between gap-3">
              <button
                onClick={() => setOpened(false)}
                className="flex-1 py-3 rounded-full text-base font-medium  text-text border border-border active:scale-[0.98] transition-transform"
              >
                Cancel
              </button>
              <button
                  onClick={handleExit}
                  className={`flex-1 py-3 rounded-full text-base font-medium 
                    ${colorScheme === 'dark' ? 'bg-white text-black' : 'bg-black text-white'} 
                    active:scale-[0.98] transition-transform`}
                >
                  Yes, Exit
                </button>

            </div>
          </div>
        </div>
      )}
      <ReportQuestionModal isOpened={isReportModal} onCancel={() => setReportModal(false)} onReport={() => handleReport(currentQuestion.id)} />
      {isSpinnerLoader && (
        <div className="absolute top-1/2 left-1/2 h-screen w-full flex flex-col justify-center items-center mt-16 transform -translate-x-1/2 -translate-y-1/2 bg-background">
          <div className="border-8 border-t-8 border-gray-300 border-t-blue-500 rounded-full w-16 h-16 animate-spin"></div>
        </div>
      )}

      <ReportModaContent
        isMobile={isMobile}
        isOpen={isOpen}
        closeModal={handleCloseModal}
        reportData={reportData}
        details={details}
        handleResportSubmit={handleResportSubmit}
        setReportData={setReportData}
        isReportSubmited={isReportSubmited}
        onChange={(e) => setDetails(e.currentTarget.value)}
        defaultOptions={defaultOptions}
      />
    </div>
  );
};

const ReportModaContent = ({
  isOpen,
  isMobile,
  closeModal,
  isReportSubmited,
  reportData,
  setReportData,
  details,
  onChange,
  handleResportSubmit,
  defaultOptions,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black/50 flex justify-center items-end z-[1000]">
      <div className={`bg-cardBackground p-5 rounded-tl-lg rounded-tr-lg w-full text-center shadow-lg ${isMobile ? 'max-w-[28rem]' : 'max-w-[22vw]'}`}>
        <div className="flex items-center justify-center w-full">
          <span className="block w-[89px] h-[6px] bg-[#E8E8E8] rounded-full cursor-pointer" onClick={closeModal}></span>
        </div>
        <button
          onClick={closeModal}
          className="relative right-1.5 border-none bg-none cursor-pointer text-[20px] font-bold text-black w-full flex justify-end"
        >
          &times;
        </button>

        {!isReportSubmited ? (
          <>
            <Title className="text-left text-2xl mt-4" fz="h3" m="1em">
              Report Question
            </Title>
            <Stack spacing="lg">
            <Radio.Group value={reportData} onChange={setReportData} size="lg" >
                {[
                  'Wrong category',
                  'Answer is incorrect',
                  'Incomplete question',
                  'Mistake in question',
                  'Mistake in options',
                  'Poorly formatted question',
                  'Poorly formatted options',
                  'Other',
                ].map((item, index) => (
                  <Radio key={index} value={item} label={item}  p="md" />
                ))}
              </Radio.Group>

              {reportData === 'Other' && (
                <>
                  <Text className="text-left">Other reason*</Text>
                  <Textarea
                    placeholder="Provide details about your issue here..."
                    value={details}
                    rows={4}
                    radius="sm"
                    className="mt-2 text-base"
                    onChange={onChange}
                  />
                </>
              )}
            </Stack>

            <Group position="center" mt="lg">
              <Button
                fullWidth
                radius="xl"
                size="md"
                variant="filled"
                color="dark"
                disabled={!reportData}
                className={`bg-background text-white font-medium ${!reportData && 'bg-gray-400'}`}
                onClick={handleResportSubmit}
              >
                Submit Feedback
              </Button>
            </Group>
          </>
        ) : (
          <>
            <Title className="text-2xl mt-4" fz="h3" m="1em">
              Report Submitted
            </Title>
            <Title className="mt-4 text-lg" m="1em" fz="md">
              Thank you! Your report has been submitted.
            </Title>
            <Lottie options={defaultOptions} height={200} width={200} />
          </>
        )}
      </div>
    </div>
  );
};

export default DuelStart;
