import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Paper, Box, Image, Text, Flex, Space, Button, ScrollArea, Stack, Modal, ActionIcon, Title, Menu, Notification } from '@mantine/core';
import {
  ProfilePic,
  Copy,
  TelegramLogo,
  ShareAppLogo,
  Logout,
  Trash,
  FeedbackIcon,
  Edit2,
  ArrowCircleRight,
  Instagram,
  XLogo,
  YouTube,
  Option,
  ArrowLeft,
  ArrowLeftDark,
  ThreeDotDark,
} from '../../assets/images';
import { userStore } from '../../stores/userStore';
import { useClipboard } from '@mantine/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { callDELETEAPI } from '../../utils/NetworkUtil';
import { useTheme } from '../../context/themeProvider';
import DeletingAccount from './DeletingAccount'; // Import the new component
import FeedbackForm from './FeedbackForm'; // Import the new FeedbackForm component
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

const ProfilePage = () => {
  const clipboard = useClipboard({ timeout: 500 });
  const navigate = useNavigate();
  const userData = userStore((state) => state.userData);
  const setUserData = userStore((state) => state.setUserData);
  const [activeButton, setActiveButton] = useState(0);
  const buttonRefs = useRef([]);
  // const [selectedSubject, setSelectedSubject] = useState('Current Affairs');
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  // const { colors } = useTheme();
  const location = useLocation();
  const { colors, colorScheme } = useTheme(); // Always call useTheme at the top
  const isNewsRoute = location.pathname.startsWith('/news'); // Check if it's a news route
  const appliedColors = isNewsRoute ? colors : { text: '#000', cardBackground: '#f9f9f9' }; // Conditionally apply colors
  const closeLogoutModal = () => setLogoutModalOpen(false);
  const scrollContainerRef = useRef(null);
  const [MenuModalOpen, setMenuModalOpen] = useState(false);
  const openMenuModal = () => setMenuModalOpen(true);
  const closeMenuModal = () => setMenuModalOpen(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const accountModalClose = () => setAccountModalOpen(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isFeedbackFormVisible, setIsFeedbackFormVisible] = useState(false);
  const [profilePic, setProfilePic] = useState(userData.photo || ProfilePic);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleShareFeedClick = () => {
    setIsFeedbackFormVisible(true);
    MixpanelEvent('profile_share_clicked');
    sendPostHogEvent('profile_share_clicked');
  };

  const handleCopyClick = () => {
    clipboard.copy(userData.username);
    setNotificationMessage('User ID copied');
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };

  const handleLogout = () => {
    MixpanelEvent('profile_log_out_clicked');
    sendPostHogEvent('profile_log_out_clicked');
    setMenuModalOpen(false);
    setUserData({});
    localStorage.clear();
    navigate('/login');
    window.location.reload();
  };
  const openLogoutModal = () => {
    setMenuModalOpen(false);
    setLogoutModalOpen(true);
  };
  const openAccountDeleteModal = () => {
    openLogoutModal(false);
    setLogoutModalOpen(false);
    setAccountModalOpen(true);
  };
  const buttons = useMemo(
    () => [
      { icon: TelegramLogo, text: 'Telegram', link: 'https://t.me/Padhai_Daily_News' },
      { icon: Instagram, text: 'Instagram', link: 'https://www.instagram.com/padhai_upsc_official/' },
      { icon: XLogo, text: 'X', link: 'https://x.com/padhai_upsc' },
      { icon: YouTube, text: 'YouTube', link: 'https://www.youtube.com/@UPSCPadhAI2024' },
      { icon: ShareAppLogo, text: 'Website', link: 'https://padhai.ai/' },
    ],
    []
  );

  const handleButtonClick = (index) => {
    setActiveButton(index);
    if (buttonRefs.current[index]) {
      buttonRefs.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  };

  const handleDeleteAccount = () => {
    setIsDeleting(true);
    const url = `${process.env.REACT_APP_API_URL}/admin/delete-account/?uuid=${userData.user}`;
    callDELETEAPI(
      url,
      (response) => {
        localStorage.clear();
        window.location.reload();
      },
      (error) => {
        setIsDeleting(false);
        console.error('An error occurred while deleting the account:', error);
      }
    );
    MixpanelEvent('profile_delete_account_clicked');
    sendPostHogEvent('profile_delete_account_clicked');
  };

  const backClick = () => {
    navigate(-1);
    MixpanelEvent('navigation_back', { source_screen: 'profile' });
    sendPostHogEvent('navigation_back', { source_screen: 'profile' });
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const scrollButtons = () => {
      if (scrollContainer) {
        scrollContainer.scrollLeft += 1;
        if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
          scrollContainer.scrollLeft = 0;
        }
      }
    };
    const scrollInterval = setInterval(scrollButtons, 25);
    return () => clearInterval(scrollInterval);
  }, []);

  if (isDeleting) {
    return <DeletingAccount />;
  }

  return (
    <Stack h={'100%'} className={colorScheme === 'dark' ? 'bg-background' : 'bg-white'}>
      {/* Notification */}
      {showNotification && (
        <Notification
          color="green"
          style={{
            position: 'fixed',
            top: '10%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 100,
            width: '300px',
            textAlign: 'center',
          }}
        >
          {notificationMessage}
        </Notification>
      )}

      {/* Profile Header */}
      <div
        className={`flex justify-between items-center w-full h-[3.9em] px-0 ${colorScheme === 'dark'
            ? 'bg-cardBackground text-text'
            : 'bg-white text-black'
          } absolute top-0 z-10`}
      >
     <div className="flex items-center">
     <img 
          onClick={backClick} 
          src={colorScheme === 'dark' ? ArrowLeftDark : ArrowLeft} 
          alt="Arrow Left" 
          className="cursor-pointer ml-4" 
        />

        <p className="text-xl ml-2">My Profile</p>
      </div>

        {/* Option Icon */}
        <Menu
          width={200}
          radius="md"
          shadow="md"
          position="bottom-end"
          withArrow
          transition="pop"
          styles={(theme) => ({
            dropdown: {
              backgroundColor: colorScheme === 'dark' ? '#2C2C2C' : '#fff',
            },
            arrow: {
              backgroundColor: colorScheme === 'dark' ? '#2C2C2C' : '#fff',
            },
          })}
        >
          {/* Trigger button (three dots) */}
          <Menu.Target>
          {colorScheme === 'dark' ? (
            <img src={ThreeDotDark} alt="Edit" className="cursor-pointer mr-4" />
          ) : (
            <img src={Option} alt="Edit" className="cursor-pointer mr-4" />
          )}
          </Menu.Target>

          <Menu.Dropdown>
            <div className="space-y-4">
              <Menu.Item onClick={openLogoutModal}>
                <div className="inline-flex items-center">
                  <img src={Logout} alt="Log out" width={20} height={20} className="mr-2" />
                  <p className="font-medium text-base text-[#e53935]">Log Out</p>
                </div>
              </Menu.Item>

              <Menu.Item onClick={openAccountDeleteModal}>
                <div className="inline-flex items-center">
                  <img src={Trash} alt="Delete my account" width={20} height={20} className="mr-2" />
                  <p className="font-medium text-base text-[#e53935]">Delete my account</p>
                </div>
              </Menu.Item>
            </div>
          </Menu.Dropdown>
        </Menu>
      </div>

      {/* Profile details */}
      <div className="flex flex-col  w-full items-center justify-center mt-[5em]">
        <div className={`shadow-sm rounded-lg p-4 w-[90%] ${colorScheme === 'dark' ? 'bg-cardBackground' : 'bg-white'
          }`}>
          <div className="flex items-center">
            <div className="mr-4">
              <img src={profilePic} alt="Profile" width={60} height={60} />
            </div>

            <div className="flex-1">
              <p className={`font-semibold text-lg ${colorScheme === 'dark' ? 'text-text' : 'text-black'
                }`}>
                {userData.name || 'User Name'}
              </p>
              <div className={`flex items-center mt-2 w-4/5 p-2 rounded-md ${colorScheme === 'dark' ? 'bg-background' : 'bg-gray-100'
                }`}>
                <p className={`text-sm mr-4 ${colorScheme === 'dark' ? 'text-subtleText' : 'text-gray-500'
                  }`}>
                  USER {userData.username || ''}
                </p>
                <button className="transition-colors ease-in-out cursor-pointer" onClick={handleCopyClick}>
                  <img src={Copy} alt="Copy" width={16} height={16} />
                </button>
              </div>
            </div>
            <button
              className="self-start cursor-pointer"
              onClick={() => {
                navigate('/edit-profile');
                MixpanelEvent('profile_edit_clicked');
                sendPostHogEvent('profile_edit_clicked');
              }}
            >
              <img src={Edit2} alt="Edit" width={24} height={24} />
            </button>
          </div>
        </div>
      </div>

      {/* Connect with us section */}
      <Box className="text-center flex items-center justify-center">
        <hr className={`border-0 border-t flex-1 mx-2.5 ${colorScheme === 'dark' ? 'border-borderColor' : 'border-gray-300'
          }`} />
        <Text
          color={colorScheme === 'dark' ? 'white' : 'dark'}
          className="mx-4"
        >
          Connect with us
        </Text>
        <hr className={`border-0 border-t flex-1 mx-2.5 ${colorScheme === 'dark' ? 'border-borderColor' : 'border-gray-300'
          }`} />
      </Box>

      {/* Horizontally scrollable buttons */}
      <Box className="w-full overflow-hidden">
        <ScrollArea type="never" viewportRef={scrollContainerRef}>
          <Flex
            className="gap-2 px-4"
            style={{
              width: 'max-content',
              justifyContent: 'center',
              animation: 'scroll 1s linear infinite',
            }}
          >
            {buttons.map((button, index) => (
              <Box
                key={index}
                ref={(el) => (buttonRefs.current[index] = el)}
                onClick={() => handleButtonClick(index)}
                className={`rounded-full border flex items-center cursor-pointer px-4 py-2 ${colorScheme === 'dark'
                    ? 'border-borderColor bg-cardBackground'
                    : 'border-borderColor bg-cardBackground'
                  }`}
              >
                <Flex
                  className="items-center justify-center"
                  onClick={() => {
                    window.open(button.link, '_blank');
                    MixpanelEvent('profile_social_link_clicked', { platform: button.text });
                    sendPostHogEvent('profile_social_link_clicked', { platform: button.text });
                  }}
                >
                  <img className="w-5" src={button.icon} alt={button.text} />
                  <Space className="w-2" />
                  <Text
                    className="font-medium text-base"
                    style={{ color: colorScheme === 'dark' ? '#FFFFFF' : '#000000' }}
                  >
                    {button.text}
                  </Text>
                  <Space className="w-2" />
                </Flex>
              </Box>
            ))}
          </Flex>
        </ScrollArea>
      </Box>

      {/* Share feedback section */}
      <div className="text-center">
        <button
          onClick={handleShareFeedClick}
          className={`border-none rounded-md px-6 py-3 cursor-pointer flex items-center justify-between w-11/12 max-w-md mx-auto ${colorScheme === 'dark' ? 'bg-[#1A4731]' : 'bg-green-100'
            }`}
        >
          <div className="flex items-center">
            <img src={FeedbackIcon} alt="Feedback Icon" />
            <span className={`ml-2 mr-20 font-poppins text-sm leading-[1.6] text-left font-medium ${colorScheme === 'dark' ? 'text-text' : 'text-black'
              }`}>
              Share your feedback
            </span>
          </div>
          <img src={ArrowCircleRight} alt="Feedback Arrow Icon" className="w-6 h-6" />
        </button>
      </div>

      {/* Menu modal */}
      <Modal
        size="auto"
        radius={'1.5em'}
        withCloseButton={false}
        opened={MenuModalOpen}
        onClose={closeMenuModal}
        className={colorScheme === 'dark' ? 'bg-cardBackground' : ''}
      >
        <Stack spacing="md">
          <Text align="left" className="text-[1.1em] font-[500] text-[#e53935]">
            <div className="inline-flex items-center cursor-pointer" onClick={openLogoutModal}>
              <Image src={Logout} alt="Log out" className="w-[20px] h-[20px] mr-[8px]" />
              Log Out
            </div>
          </Text>
          <Text align="left" className="text-[1.1em] font-[500] text-[#e53935]" onClick={openAccountDeleteModal}>
            <div className="inline-flex items-center cursor-pointer">
              <Image src={Trash} alt="Delete my account" className="w-[20px] h-[20px] mr-[8px]" />
              Delete my account
            </div>
          </Text>
        </Stack>
      </Modal>

      {/* Logout Modal */}
      <div className={`${logoutModalOpen ? 'fixed inset-0 flex items-center justify-center z-50 bg-black/50' : 'hidden'}`}>
        <div className={`rounded-[20px] w-[280px] p-6 ${colorScheme === 'dark' ? 'bg-cardBackground' : 'bg-white'
          }`}>
          <div className="text-center mb-4">
            <h3 className={`text-[18px] font-semibold mb-2 ${colorScheme === 'dark' ? 'text-text' : ''
              }`}>
              Log out?
            </h3>
            <p className={`text-[14px] ${colorScheme === 'dark' ? 'text-subtleText' : 'text-[#666666]'
              }`}>
              Are you sure you want to log out?
            </p>
          </div>
          <div className="flex gap-3">
            <button
              onClick={closeLogoutModal}
              className={`flex-1 py-[10px] rounded-full border ${colorScheme === 'dark'
                  ? 'border-borderColor text-text bg-cardBackground'
                  : 'border-[#E6E6E6] text-black bg-white'
                }`}
            >
              Cancel
            </button>
            <button
              onClick={handleLogout}
              className="flex-1 py-[10px] rounded-full bg-[#E45454] text-white"
            >
              Yes, Log out
            </button>
          </div>
        </div>
      </div>

      {/* Delete Account Modal */}
      <div className={`${accountModalOpen ? 'fixed inset-0 flex items-center justify-center z-50 bg-black/50' : 'hidden'}`}>
        <div className={`rounded-[20px] w-[380px] p-6 ${colorScheme === 'dark' ? 'bg-cardBackground' : 'bg-white'
          }`}>
          <div className="text-center mb-4">
            <h3 className={`text-[18px] font-semibold mb-2 ${colorScheme === 'dark' ? 'text-text' : ''
              }`}>
              Permanently Delete Account?
            </h3>
            <p className={`text-[14px] ${colorScheme === 'dark' ? 'text-subtleText' : 'text-[#666666]'
              }`}>
              Are you sure you want to permanently delete your account? This action cannot be undone.
            </p>
          </div>
          <div className={`border-l-2 border-[#E45454] p-4 mb-4 ${colorScheme === 'dark' ? 'bg-[#2C1515]' : 'bg-[#FFF1F1]'
            }`}>
            <p className="text-[#E45454] text-[14px] mb-2">All your data will be permanently removed:</p>
            <ul className="text-[#E45454] text-[14px] list-none">
              <li className="mb-1">• Profile information such as Phone number, email id, name, password</li>
              <li className="mb-1">• Chat history</li>
              <li className="mb-1">• All bookmarks/notes</li>
              <li className="mb-1">• Duel Bank points</li>
              <li className="mb-1">• Added friends</li>
              <li className="mb-1">• Saved PadHai lists</li>
            </ul>
            <p className="text-[#E45454] text-[14px] mt-2">Please confirm if you wish to proceed.</p>
          </div>
          <div className="flex gap-3">
            <button
              onClick={accountModalClose}
              className={`flex-1 py-[10px] rounded-full border ${colorScheme === 'dark'
                  ? 'border-borderColor text-text bg-cardBackground'
                  : 'border-[#E6E6E6] text-black bg-white'
                }`}
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteAccount}
              className="flex-1 py-[10px] rounded-full bg-[#E45454] text-white"
            >
              Yes, Delete
            </button>
          </div>
        </div>
      </div>

      {isFeedbackFormVisible && (
        <FeedbackForm isVisible={isFeedbackFormVisible} onClose={() => setIsFeedbackFormVisible(false)} userData={userData} />
      )}
    </Stack>
  );
};

export default ProfilePage;
