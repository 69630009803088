import React from 'react';

const TimeSwitch = ({ activeTime = 'all', onTimeChange }) => {
  return (
    <div className="flex justify-between items-center w-[300px] rounded-md mx-auto mb-2">
      {[
        { label: 'ALL TIME', value: 'all' },
        { label: 'WEEKLY', value: 'weekly' },
        { label: 'DAILY', value: 'daily' },
      ].map(({ label, value }) => (
        <button
          key={value}
          onClick={() => onTimeChange(value)}
          className={`
            px-4 py-2 text-sm font-medium transition-all duration-300
            ${activeTime === value
              ? 'text-text font-bold underline'
              : 'text-gray-500'
            }
          `}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default TimeSwitch;