import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import {  Image } from '@mantine/core';
import { welcomeSliderStore } from '../../stores/welcomeSliderStore';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from '../../assets/images';
import { getSlides } from '../../constants/slides';
import { useTheme } from '../../context/themeProvider';

const OnboardingPage = () => {
  const currentSlide = welcomeSliderStore((state) => state.currentSlide);
  const setCurrentSlide = welcomeSliderStore((state) => state.setCurrentSlide);
  const resetSliderState = welcomeSliderStore((state) => state.resetSliderState);

  const navigate = useNavigate();
  const { colorScheme } = useTheme(); 
  const slides = getSlides(colorScheme);

  const handleNextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const getStarted = () => {
    navigate('/login');
    resetSliderState();
  };

  return (
    <div className={`h-screen w-full ${colorScheme === 'dark' ? 'bg-background' : 'bg-background'}`}>
      <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        selectedItem={currentSlide}
        onChange={(index) => setCurrentSlide(index)}
        swipeable
        emulateTouch
        infiniteLoop={false}
        autoPlay={false}
        dynamicHeight={false}
        renderIndicator={(clickHandler, isSelected, index, label) => (
          <li
            className={`w-8 h-1 rounded-full inline-block mx-[6px] relative top-[20px] 
              ${isSelected ? 'bg-text' : 'bg-subtleText'}`}
            onClick={clickHandler}
            onKeyDown={clickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            aria-label={`${label} ${index + 1}`}
          />
        )}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className="h-screen flex flex-col justify-center items-center"
            style={{
              background:
                colorScheme === 'dark'
                  ? `linear-gradient(180deg, ${slide.svgBgColor[1]} 35%, ${slide.svgBgColor[0]} 95%)`
                  : `linear-gradient(180deg, ${slide.svgBgColor[0]} 35%, ${slide.svgBgColor[1]} 95%)`,
            }}            
          >
            <Image w={'5em'} src={slide.icon || ''} alt="icon" />
            <p className="text-center text-[1.5em] mt-[0.5em] font-[500] text-text">
              {slide.title}
            </p>
            <p className="text-center font-[400] text-[1em] mt-[0.5em] text-text">
              {slide.description}
            </p>
            <div className="mt-[10px] w-full">
              <Image src={slide.component} alt={`Slide ${index + 1}`} />
            </div>
          </div>
        ))}
      </Carousel>

      {/* Navigation Buttons */}
      <div
        className={`absolute bottom-[24px] left-[16px] right-[16px] flex gap-[20px] ${
          currentSlide === slides.length - 1 ? 'justify-center' : 'justify-between'
        }`}
      >
        {currentSlide === slides.length - 1 ? (
          <button
            className="w-[75%] h-[48px] bg-[#070519] rounded-[10em] text-white font-[500] z-10 flex items-center justify-center"
            onClick={getStarted}
          >
            Get Started <Image src={ArrowRight} />
          </button>
        ) : (
          <>
            <button
              className="font-[500] z-10 flex justify-center items-center w-[30%] h-[3em] bg-[#070519] rounded-[10em] text-white"
              onClick={getStarted}
            >
              Skip
            </button>
            <button
              className="w-[70%] h-[3em] bg-[#070519] rounded-[10em] text-white font-[500] z-10 flex justify-center items-center"
              onClick={handleNextSlide}
            >
              Next <Image src={ArrowRight} />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default OnboardingPage;
