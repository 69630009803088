import React from 'react';
import { ComingSoon } from '../../assets/images';
import { useTheme } from '../../context/themeProvider';

const NoHighlightPage = () => {
  const { colors } = useTheme();

  return (
    <div className="relative z-50 overflow-hidden">
      <div 
        className="flex items-center justify-center h-[72vh] overflow-scroll"
        style={{ 
          backgroundColor: colors.background 
        }}
      >
        <div className="flex flex-col items-center space-y-4 text-center">
          <img 
            src={ComingSoon} 
            alt="Nothing here" 
            className="w-[80%] max-w-[600px]" 
          />
          
          <h2 
            className="font-poppins text-base font-semibold"
            style={{ color: colors.text }}
          >
            Nothing here!
          </h2>
          
          <p 
            className="font-poppins text-sm font-medium whitespace-pre-line"
            style={{ color: colors.subtleText }}
          >
            Highlight your favourite News {'\n'} Summary details for personalized Notes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoHighlightPage;