import React, { useState, useRef } from 'react';
import { BlueCrown, BlueCheck } from '../../assets/images';
import { useTheme } from '../../context/themeProvider';

const FriendsContainer = () => (
  <div className="bg-white rounded-md p-4 mx-auto border border-gray-300 border-l-4 border-l-green-500 mb-5">
    <p className="text-sm font-bold text-gray-800 mb-0 uppercase">You’ve not added any friends</p>
    <p className="text-xs text-gray-500 mt-2">To add friends, play ‘Duel with Friends’ & view rankings on the Friends Leaderboard.</p>
  </div>
);

const LeaderBoardListModal = ({ globalLeaderboard, userRanking, activeTab }) => {
  const [isOpen] = useState(true);
  const [isFullyOpen, setIsFullyOpen] = useState(false);
  const modalRef = useRef(null);
  const isRespMobile = window.innerWidth <= 1400 && window.innerHeight <= 700;
  const backdropTop = activeTab === 'Performance' ? '350px' : '200px';
  const { colorScheme } = useTheme();

  const toggleModalHeight = () => {
    setIsFullyOpen(!isFullyOpen);
  };

  const getInitial = (name) => {
    if (!name) return '-';
    return name.charAt(0).toUpperCase();
  };

  const calculateUserRank = () => {
    return userRanking && userRanking.top ? `Top ${userRanking.top}%` : 'Top 0%';
  };

  const userRankGlobal = calculateUserRank();

  const getDisplayValue = (item) => {
    if (activeTab === 'Performance') {
      return item.totalcorr || 'N/A';
    }
    return item.score || 'N/A';
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-x-0 bottom-0 flex justify-center items-end" style={{ top: backdropTop }}>
          <div
            onClick={toggleModalHeight}
            ref={modalRef}
            className="bg-cardBackground rounded-t-xl w-full max-w-[28em] flex flex-col border border-border border-b-0"
            style={{ height: isFullyOpen ? '100%' : isRespMobile ? '36%' : '50%', transition: 'height 0.3s ease-in-out' }}
          >
            {globalLeaderboard && globalLeaderboard.length > 0 && (
              <div className="flex items-center justify-center w-full cursor-pointer py-2" onClick={toggleModalHeight}>
               
               <span
                  className={`h-2 w-24 rounded-full ${
                    colorScheme === 'dark' ? 'bg-[#57575B]' : 'bg-[#bdcbff]'
                  }`}
                ></span>               
              </div>
            )}

            <div className="flex-grow p-4 overflow-y-auto" style={{ overflowY: globalLeaderboard && globalLeaderboard.length > 0 ? 'auto' : 'hidden' }}>
              {!globalLeaderboard || globalLeaderboard.length === 0 ? (
                <FriendsContainer />
              ) : (
                globalLeaderboard.map((item, index) => (
                  <div key={index} className="flex items-center py-2">
                    <span className="w-8">{index + 1}</span>
                    <div className="w-10 h-10 text-black rounded-md bg-[#BDCBFF] flex items-center justify-center font-bold mr-2">
                      {getInitial(item.name)}
                    </div>
                    <span className="text-sm flex-grow text-text">{item.name || ''}</span>
                    <span className="w-12 mr-6 flex items-center">
                      <img
                        src={activeTab === 'Performance' ? BlueCheck : BlueCrown}
                        alt={activeTab === 'Performance' ? 'Check Icon' : 'Crown Icon'}
                        className="w-5 h-5 mr-1"
                      />
                      {getDisplayValue(item)}
                    </span>
                  </div>
                ))
              )}
            </div>


            <div
              className={`flex items-center p-2`}
              style={{ backgroundColor: colorScheme === 'dark' ? '#404044' : '#EBF3FF' }}
            >   
              <span className="text-xs w-8">{userRanking ? userRankGlobal : 'N/A'}</span>
              <div className="w-10 h-10 ml-4 rounded-md text-black rounded-md bg-[#BDCBFF] flex items-center justify-center font-bold">
                {userRanking ? getInitial(userRanking.name) : '-'}
              </div>
              <span className="flex-grow ml-2">
                <span className="bg-indigo-600 text-white py-1 px-2 rounded-md text-xs mr-1">Me</span>
                <span className="text-sm">
                  {userRanking && userRanking.name ? (userRanking.name.length > 10 ? `${userRanking.name.slice(0, 10)}...` : userRanking.name) : ''}
                </span>
              </span>
              <span className="mr-5 flex items-center">
                <img
                  src={activeTab === 'Performance' ? BlueCheck : BlueCrown}
                  alt={activeTab === 'Performance' ? 'Check Icon' : 'Crown Icon'}
                  className="w-5 h-5 mr-1"
                />
                {userRanking ? getDisplayValue(userRanking) : 'N/A'}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};



export default LeaderBoardListModal;