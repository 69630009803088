import React, { useEffect, useState } from 'react';
import { Text, Modal, Stack, Button, Paper, Flex, Loader } from '@mantine/core';
import { Google, LineOrLine, Whatsapp, LoginHomePageImg, LoginHomeLogo,LoginHomeLogoDark } from '../../assets/images';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { SignInWithSupabasePostGoogleSignIn, SignInWithSupabasePostWhatsAppSignIn } from '../../utils/AuthUtils';
import { userStore } from '../../stores/userStore';
import { initOTPless } from '../../utils/initOtpless';
import axios from 'axios';
import { useTheme } from '../../context/themeProvider';

const Login = () => {
  const navigate = useNavigate();
  const userData = userStore((state) => state.userData);
  const setUserData = userStore((state) => state.setUserData);
  const clearUserData = userStore((state) => state.clearUserData);
  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isResponsiveMobile = window.innerHeight <= 700 && window.innerWidth <= 400;
  const { colorScheme } = useTheme();

  useEffect(() => {
    const img = new Image();
    img.src = LoginHomePageImg;
    img.onload = () => setIsLoading(false);
  }, []);

  const handleOtplessUser = (otplessUser) => {
    clearUserData();
    const identities = otplessUser.identities;
    if (identities && identities.length > 0) {
      let mobileItem = identities.find((item) => item.identityType === 'MOBILE' && item.identityValue !== null && item.identityValue !== undefined);
      if (mobileItem && mobileItem.identityValue) {
        SignInWithSupabasePostWhatsAppSignIn({
          name: mobileItem.name ?? '',
          number: mobileItem.identityValue ?? '',
          setUserData,
        });
      }
    } else {
      console.log('No identities found');
    }

    setUserData(localStorage.getItem('userData'));

    setTimeout(() => {
      navigate('/news');
      window.location.reload();
    }, 2000);
  };

  const openModalAndInitializeOtpless = () => {
    setOpened(true);
    setTimeout(() => {
      initOTPless(handleOtplessUser);
    }, 300);
  };

  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      try {
        const tokens = await exchangeCodeForTokens(codeResponse.code);
        await SignInWithSupabasePostGoogleSignIn(tokens.id_token, 'google', tokens.access_token, setUserData, navigate);
      } catch (error) {
        console.log('Login Failed:', error);
      }
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  const exchangeCodeForTokens = async (code) => {
    const response = await axios.post('https://oauth2.googleapis.com/token', {
      code,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: window.location.origin,
      grant_type: 'authorization_code',
    });
    return response.data;
  };

  useEffect(() => {
    if (userData) {
      console.log('User Data', userData);
    }
  }, [userData]);

  if (isLoading) {
    return (
      <Stack justify="center" align="center" className="h-screen">
        <Loader size="xl" />
      </Stack>
    );
  }
  return (
    <div className={`w-full h-screen flex flex-col items-center relative overflow-hidden ${
      colorScheme === 'dark' ? 'bg-background text-text' : 'bg-white'
    }`}>
      {/* Logo Header */}
      <div className={`absolute ${isResponsiveMobile ? 'top-4' : 'top-8'} left-0 right-0 z-[1000] flex justify-center`}>
      <img src={colorScheme === 'dark' ? LoginHomeLogoDark : LoginHomeLogo} alt="PadhAI Logo" />
      </div>

      <div className="absolute inset-0 scrollbar-none">
        {/* Main Image Section */}
        <div className={`${isResponsiveMobile ? 'mt-16' : 'mt-24'} p-5`}>
          <img
            src={LoginHomePageImg}
            alt="Home Banner"
            className="w-full"
            loading="eager"
          />
        </div>

        {/* Login Section */}
        <div className={`w-full rounded-t-[1.25em] relative z-10 ${
          colorScheme === 'dark' ? 'bg-background' : 'bg-white'
        }`}>
          <div className="w-full px-5 flex flex-col items-center space-y-6">
            <p className="font-[500] text-[20px] leading-[30px] w-[320px] text-center font-poppins">
              Study smarter with AI in UPSC PadhAI
            </p>

            <button
              onClick={openModalAndInitializeOtpless}
              className={`w-full h-[3.2em] rounded-full flex items-center justify-center gap-2 ${
                colorScheme === 'dark' 
                  ? 'bg-background border border-gray-700 hover:bg-gray-800'
                  : 'bg-white shadow-[0px_1px_1px_0px_#0000002B,0px_0px_4px_0px_#00000015] hover:bg-gray-50'
              }`}
            >
              <img src={Whatsapp} alt="WhatsApp Logo"  />
              <span className="text-[0.9em] text-subtleText font-normal">
                Login with WhatsApp
              </span>
            </button>

            <img src={LineOrLine} alt="Or Line" className="w-full" />

            <button
              onClick={() => handleGoogleLogin()}
              className={`w-full h-[3.2em] rounded-full flex items-center justify-center gap-2 ${
                colorScheme === 'dark'
                  ? 'bg-background border border-gray-700 hover:bg-gray-800'
                  : 'bg-white shadow-[0px_1px_1px_0px_#0000002B,0px_0px_4px_0px_#00000015] hover:bg-gray-50'
              }`}
            >
              <img src={Google} alt="Google Logo"  />
              <span className="text-[0.9em] text-subtleText font-normal">
                Login with Google
              </span>
            </button>
          </div>
        </div>
      </div>

      <Modal 
        size={'26em'} 
        opened={opened} 
        onClose={() => setOpened(false)} 
        title="Login with WhatsApp" 
        centered
      >
        <div id="otpless-login-page"></div>
      </Modal>
    </div>
  );
};

export default Login;