import React from 'react';
import { PracticeCommingSoon } from '../../assets/images';

export default function Tournaments({ 
  img = PracticeCommingSoon, 
  description = "Exciting tournaments are on their way! Stay tuned for more details." 
}) {
  return (
    <div className="h-[80vh] bg-background">
      <div className="flex justify-center items-center h-full">
        <div className="w-[90%] max-w-[600px] text-center">
          <img
            src={img}
            alt="Coming Soon"
            className="w-full object-cover mb-4"
          />
          <h2 className="font-normal text-text font-poppins mb-2">
            Coming Soon
          </h2>
          <p className="font-poppins text-sm mx-8  leading-loose text-gray-500">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}