import React from 'react';
import { icons } from '../../../constants/iconData'; // Ensure this is the correct path to your iconData
import { BlueCrown } from '../../../assets/images';

const DuelInviteModal = ({
  onAccept,
  onDecline,
  friendName, 
  friendScore, 
  topic,
  friendPhoto
}) => {
  const initial = friendName ? friendName.charAt(0).toUpperCase() : '';
  const subjects = [
    { value: 'csat', label: 'CSAT' },
    { value: 'history', label: 'History' },
    { value: 'geography', label: 'Geography' },
    { value: 'polity', label: 'Polity' },
    { value: 'current_affairs', label: 'Current Affairs' },
    { value: 'economy', label: 'Economy' },
    { value: 'science', label: 'Science' },
    { value: 'environment', label: 'Environment' },
  ];
  console.log(topic, 'topic')
  const matchingSubject = subjects.find(subject => subject.label === topic);
  const icon = matchingSubject ? icons[matchingSubject.value] : icons['all_subjects'];
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
      <div className="bg-cardBackground rounded-3xl p-6 w-96 shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-semibold text-center mb-2">
          Duel Invite from Friend
        </h2>
        
        <p className="text-text text-center mb-6">
          Your Friend has invited your for a Duel!
        </p>

        {/* User Profile Container */}
        <div className="bg-cardBackground rounded-2xl p-4 mb-4 shadow-sm border border-border">
          <div className="flex flex-col items-center">
          <div className="bg-blue-100 rounded-xl w-16 h-16 mb-2 flex items-center justify-center">
              {friendPhoto ? (
                <img
                  src={friendPhoto}
                  alt="User avatar"
                  className="w-full h-full object-cover rounded-xl"
                />
              ) : (
                <span className="text-blue-600 text-2xl font-bold">
                  {initial}
                </span>
              )}
            </div>

            <p className="text-lg font-medium mb-2">{friendName}</p>
            <div className="bg-blue-50 px-4 py-1 rounded-full flex items-center gap-1">
             <img src={BlueCrown} alt='crown' className='w-5 h-5'/>
              <span className="text-[#3C45CD]">{friendScore}</span>
            </div>
          </div>
        </div>

        {/* Duel Topic Container */}
        <div className="bg-cardBackground rounded-2xl p-4 mb-6 shadow-sm border border-border">
          <p className="text-gray-500 text-sm text-center mb-2">DUEL TOPIC</p>
          <div className="flex items-center justify-center gap-2">
            {icon}
            <span className="text-text font-medium">{topic}</span>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex gap-3">
          <button
            onClick={onDecline}
            className="flex-1 py-3 px-4 rounded-full border border-buttonBorder text-text font-medium "
          >
            Decline
          </button>
          <button
            onClick={onAccept}
            className="flex-1 py-3 px-4 rounded-full bg-gray-900 text-white font-medium"
          >
            Accept Invite
          </button>
        </div>
      </div>
    </div>
  );
};

export default DuelInviteModal;