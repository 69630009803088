import React, { useState, useEffect, useCallback, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Box, Text, useMantineTheme } from '@mantine/core';
import {
  DuelTab,
  DuelTabActive,
  NewsTab,
  NewsTabActive,
  ChatTab,
  ChatTabActive,
  SearchTab,
  SearchTabActive,
  PracticeTab,
  PracticeTabActive,
} from '../../assets/images';
import { useTheme } from '../../context/themeProvider';
import './styles.css';
import UniversalVideoModal from '../../molecules/VideoModal';
import { userStore } from '../../stores/userStore';
import { callGETAPI, callPOSTAPI } from '../../utils/NetworkUtil';

const Navbar = () => {
  const theme = useMantineTheme();
  const location = useLocation();
  const userData = userStore((state) => state.userData);
  const userId = userData?.user || null;
  const { colors, colorScheme } = useTheme();
  const isNewsRoute = location.pathname.startsWith('/news');
  const appliedColors = isNewsRoute ? colors : { cardText: '#000', cardBackground: '#f9f9f9' };
  const [showModal, setShowModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState(location.pathname);
  const [intendedTab, setIntendedTab] = useState(null);
  const [canNavigate, setCanNavigate] = useState(true);
  const navigationTimerRef = useRef(null);
  const [pageEntryTime, setPageEntryTime] = useState(Date.now());
  const [showVideoEligible, setShowVideoEligible] = useState(true);
  const isNewUser = localStorage.getItem('new_user') === 'true';

  const videoUrls = {
    duel: 'https://d25d0jk908q61f.cloudfront.net/Duel%20-%20Moving%20Away.mp4',
    chat: 'https://d25d0jk908q61f.cloudfront.net/Ask%20a%20Book%20-%20Moving%20Away.mp4',
    news: 'https://d25d0jk908q61f.cloudfront.net/News%20-%20Moving%20Away.mp4',
    practice: 'https://d25d0jk908q61f.cloudfront.net/Practise%20-%20Moving%20Away.mp4',
    pyq: 'https://d25d0jk908q61f.cloudfront.net/PYQ%20-%20Moving%20Away.mp4',
  };

  const tabOrder = ['/duel', '/news', '/chat', '/pyq', '/practice'];

  const [featureCounts, setFeatureCounts] = useState({});

  const fetchVideoCount = useCallback(() => {
    if (userId) {
      callGETAPI(
        `${process.env.REACT_APP_BASE_URL}/api/fetch-all-feature-count/?user_id=${userId}`,
        (resp) => {
          setFeatureCounts(resp.data);
        },
        (err) => {
          console.error('Error fetching video count:', err);
        }
      );
    }
  }, [userId]);

  useEffect(() => {
    fetchVideoCount();
  }, [fetchVideoCount]);

  useEffect(() => {
    setCurrentTab(location.pathname);
    setPageEntryTime(Date.now());
    setShowVideoEligible(true);
    setCanNavigate(false);

    if (navigationTimerRef.current) {
      clearTimeout(navigationTimerRef.current);
    }

    navigationTimerRef.current = setTimeout(() => {
      setCanNavigate(true);
      setShowVideoEligible(false);
    }, 15000);

    return () => {
      if (navigationTimerRef.current) {
        clearTimeout(navigationTimerRef.current);
      }
    };
  }, [location.pathname]);

  const updateFeatureCount = async (feature) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/update-feature-count/`;
    const payload = {
      user_id: userId,
      feature: feature,
    };

    try {
      await callPOSTAPI(
        url,
        payload,
        () => {
          fetchVideoCount();
        },
        (error) => {
          console.error('Error updating feature count:', error);
        }
      );
    } catch (error) {
      console.error('Error in API call:', error);
    }
  };

  const handleClick = (e, to) => {
    e.preventDefault();

    const now = Date.now();
    const timeDiff = now - pageEntryTime;
    const currentFeature = currentTab.slice(1);
    const newFeature = to.slice(1);

    if (isNewUser) {
      if (timeDiff >= 15000) {
        navigate(to);
        setCurrentTab(to);
        setPageEntryTime(now);
        setShowVideoEligible(false);
      } else {
        if ((featureCounts[currentFeature] || 0) < 3 && showVideoEligible) {
          setCurrentVideo(videoUrls[currentFeature] || '');
          setShowModal(true);
          updateFeatureCount(currentFeature);
          setIntendedTab(to);
        } else {
          navigate(to);
          setCurrentTab(to);
          setPageEntryTime(now);
          setShowVideoEligible(true);
        }
      }
    } else {
      navigate(to);
      setCurrentTab(to);
      setPageEntryTime(now);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIntendedTab(null);
  };

  const isActive = (path) => {
    return location.pathname === path || (path === '/pyq' && location.pathname.startsWith('/pyq'));
  };

  const linkStyle = {
    textDecoration: 'none',
    color: (path) => (isActive(path) ? (theme.colorScheme === 'dark' ? '#ffffff' : '#000000') : '#808080'),
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0',
  };

  const getIcon = (isActive, icon, activeIcon) => {
    return <img src={isActive ? activeIcon : icon} alt="icon" />;
  };
  const boxClass = `flex justify-around py-2.5 bg-background border-t ${
    theme.colorScheme === 'dark' ? 'border-[#2E2E2E]' : ''
  } shadow-[0px_-1px_4px_0px_#0000001F] dark:shadow-[0_-2px_10px_rgba(0,_0,_0,_0.1)] absolute bottom-0 left-0 right-0 w-full z-[999]`;

  return (
    <>
      <Box className={boxClass}>
        <NavLink
          to="/duel"
          style={{ ...linkStyle, color: linkStyle.color('/duel') }}
          className={isActive('/duel') ? (theme.colorScheme === 'dark' ? 'dark-theme-active' : 'light-theme-active') : ''}
          onClick={(e) => handleClick(e, '/duel')}
        >
          {getIcon(isActive('/duel'), DuelTab, DuelTabActive)}
          <Text size="xs" className="label">
            Duel
          </Text>
        </NavLink>
        <NavLink
          to="/news"
          style={{ ...linkStyle, color: linkStyle.color('/news') }}
          className={isActive('/news') ? (theme.colorScheme === 'dark' ? 'dark-theme-active' : 'light-theme-active') : ''}
          onClick={(e) => handleClick(e, '/news')}
        >
          {getIcon(isActive('/news'), NewsTab, NewsTabActive)}
          <Text size="xs" className="label">
            News
          </Text>
        </NavLink>
        <NavLink
          to="/chat"
          style={{ ...linkStyle, color: linkStyle.color('/chat') }}
          className={isActive('/chat') ? (theme.colorScheme === 'dark' ? 'dark-theme-active' : 'light-theme-active') : ''}
          onClick={(e) => handleClick(e, '/chat')}
        >
          {getIcon(isActive('/chat'), ChatTab, ChatTabActive)}
          <Text size="xs" className="label">
            Chat
          </Text>
        </NavLink>
        <NavLink
          to="/pyq"
          style={{ ...linkStyle, color: linkStyle.color('/pyq') }}
          className={isActive('/pyq') ? (theme.colorScheme === 'dark' ? 'dark-theme-active' : 'light-theme-active') : ''}
          onClick={(e) => handleClick(e, '/pyq')}
        >
          {getIcon(isActive('/pyq'), SearchTab, SearchTabActive)}
          <Text size="xs" className="label light-theme-active">
            PYQs
          </Text>
        </NavLink>
        <NavLink
          to="/practice"
          style={{ ...linkStyle, color: linkStyle.color('/practice') }}
          className={isActive('/practice') ? (theme.colorScheme === 'dark' ? 'dark-theme-active' : 'light-theme-active') : ''}
          onClick={(e) => handleClick(e, '/practice')}
        >
          {getIcon(isActive('/practice'), PracticeTab, PracticeTabActive)}
          <Text size="xs" className="label">
            Practice
          </Text>
        </NavLink>
      </Box>

      {showModal && <UniversalVideoModal isOpen={showModal} onClose={handleCloseModal} videoUrl={currentVideo} title="You might be missing out!" />}
    </>
  );
};

export default Navbar;
