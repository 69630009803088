import React, { useState } from 'react';
import { useTheme } from '../../context/themeProvider';
import Bookmarks from './bookmarks';
import MyNotes from './myNotes';

const SavedTab = () => {
    const { colors, colorScheme } = useTheme();
    const windowHeight = window.innerHeight;
    const isResponsiveMobile = windowHeight <= 700;
    const [activeTab, setActiveTab] = useState('bookmarks');
    const isDarkTheme = colorScheme === 'dark';

    return (
        <div
            className={`flex flex-col ${
                windowHeight > 900 ? 'h-screen' : isResponsiveMobile ? 'h-[33em]' : 'h-[45em]'
            }`}
            style={{ backgroundColor: colors.background, color: colors.text }}
        >
            <HeaderTab activeTab={activeTab} setActiveTab={setActiveTab} isDarkTheme={isDarkTheme} />
            {activeTab === 'bookmarks' ? <Bookmarks /> : <MyNotes />}
        </div>
    );
};

const HeaderTab = ({ activeTab, setActiveTab, isDarkTheme }) => {
    return (
        <div
            className={`flex gap-[10px] p-[8px] rounded-[25px] my-[0.8em] mx-[0.8em] ${isDarkTheme ? 'bg-[#303034]' : 'bg-[#E8E8E8]'}`}
        >
            <button
                className={`
                    py-[8px] px-[16px] w-[50%] h-[40px] rounded-[20px] cursor-pointer text-[14px] font-poppins border-none
                    ${
                        activeTab === 'bookmarks'
                            ? `${isDarkTheme ? 'bg-[#57575B] text-[#FFFFFF]' : 'bg-white text-[#070519] shadow-md'}`
                            : 'bg-transparent text-[#747474]'
                    }
                `}
                onClick={() => setActiveTab('bookmarks')}
            >
                Bookmarks
            </button>
            <button
                className={`
                    py-[8px] px-[16px] w-[50%] h-[40px] rounded-[20px] cursor-pointer text-[14px] font-poppins border-none
                    ${
                        activeTab === 'notes'
                            ? `${isDarkTheme ? 'bg-[#57575B] text-[#FFFFFF]' : 'bg-white text-[#070519] shadow-md'}`
                            : 'bg-transparent text-[#747474]'
                    }
                `}
                onClick={() => setActiveTab('notes')}
            >
                My notes
            </button>
        </div>
    );
};

export default SavedTab;
