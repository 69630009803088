import React, { useState, useEffect, useRef } from 'react';
import { CrownGeneral, VirsusBlue } from '../../../assets/images';
import { useLocation, useNavigate } from 'react-router-dom';
import { userStore } from '../../../stores/userStore';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';
import { useTheme } from '../../../context/themeProvider';

const Matching = () => {
  const location = useLocation();
  const topic = location.state?.topic;
  const userData = userStore((state) => state.userData);
  const userId = userData.user;
  const {colorScheme } = useTheme();
  const [matchStatus, setMatchStatus] = useState('searching');
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const socketRef = useRef(null);
  const [opponent, setOpponent] = useState({});
  const [bot, setBot] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [startTime, setStartTime] = useState(null);
  const endTime = Date.now();
  let loadingTime = Math.round((endTime - startTime) / 1000); // Calculate loading time in seconds
  const isResponsiveMobiles = window.innerHeight <= 900 && window.innerWidth <= 400;

  useEffect(() => {
    const matchingURL = `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/matchmaking/${userId}/?category=${topic}`;
    console.log(matchingURL, 'Connecting to WebSocket...');

    socketRef.current = new WebSocket(matchingURL);
    let findMatchAttempts = 0;
    let findMatchInterval;

    const sendFindMatchRequest = () => {
      if (Object.keys(bot).length > 0 || Object.keys(opponent).length > 0) {
        clearInterval(findMatchInterval);
      } else {
        if (findMatchAttempts < 5) {
          if (socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify({ find_match: true }));
            findMatchAttempts++;
            setProgress((prevProgress) => Math.min(prevProgress + 20, 100));
          }
        } else {
          clearInterval(findMatchInterval);
          if (socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify({ fetch_bot: true }));
          }
        }
      }
    };

    socketRef.current.onopen = () => {
      console.log('WebSocket connection established');
      setStartTime(Date.now());
      findMatchInterval = setInterval(sendFindMatchRequest, 1000);
    };

    socketRef.current.onmessage = (e) => {
      if (e && e.data) {
        let parsedJson = JSON.parse(e.data);
        console.log('matching onmessage parsedJson', parsedJson);
        if (Object.keys(bot).length > 0 || Object.keys(opponent).length > 0) {
          console.log('already matched');
        } else {
          if (parsedJson.status === true) {
            setMatchStatus('found');
            if (parsedJson.bot === true) {
              setBot({ ...parsedJson.data, room_id: parsedJson.room_id });
            } else {
              setOpponent(parsedJson.data);
            }
            if (parsedJson.current_user) {
              setCurrentUser(parsedJson.current_user);
            }
            socketRef.current.close();
          }
        }
      }
    };

    socketRef.current.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      clearInterval(findMatchInterval);
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [userId, topic]);

  useEffect(() => {
    if (matchStatus === 'found') {
      const competitorType = Object.keys(bot).length > 0 ? 'bot' : 'real';

      MixpanelEvent('duel_start', {
        category: topic,
        competitor_type: competitorType,
        mode: 'online',
        loading_time: loadingTime,
      });
      sendPostHogEvent('duel_start', {
        category: topic,
        competitor_type: competitorType,
        mode: 'online',
        loading_time: loadingTime,
      });
      const timer = setTimeout(() => {
        if (socketRef.current) {
          socketRef.current.close();
        }
        navigate('/duel-start', {
          state: {
            bot: bot,
            opponent: opponent,
            currentUser: currentUser,
            topic: topic,
            room_id: bot.room_id || opponent.room_id,
          },
        });
      }, 3000); // Wait for 3 seconds before navigating

      return () => clearTimeout(timer);
    }
  }, [matchStatus, bot, opponent, startTime, topic, navigate, currentUser]);

  const handleCancel = () => {
    MixpanelEvent('duel_cancel', {
      category: topic,
      mode: 'online',
      loading_time: loadingTime,
      cancel_time: 'pre_initiate',
    });
    sendPostHogEvent('duel_cancel', {
      category: topic,
      mode: 'online',
      loading_time: loadingTime,
      cancel_time: 'pre_initiate',
    });
    if (socketRef.current) {
      socketRef.current.close();
    }
    navigate('/duel');
  };

  const getInitial = (name) => {
    if (!name) return '-';
    return name.charAt(0).toUpperCase();
  };


  return (
    <div className="flex flex-col items-center min-h-screen bg-background text-text overflow-y-auto scrollbar-none p-5">
      <div className={`text-[#747474] font-medium text-sm ${isResponsiveMobiles ? 'mt-4' : 'mt-10'}`}>
        ONLINE DUEL
      </div>

      <div className="font-bold text-2xl my-1">
        {matchStatus === 'searching' ? 'Searching for opponent' : 'Match Found'}
      </div>

      <div className={`flex flex-col justify-center items-center p-5 rounded-2xl my-6 w-[90%] 
  ${colorScheme === 'dark' ? 'bg-[#1B3260]' : 'bg-[#EBF3FF]'}`}
      >        {/* Current User Box */}
        <div className="text-center my-2.5 relative w-full">
          <div className="relative w-16 h-16 mx-auto bg-[#f0f0f0] rounded-full flex justify-center items-center">
            {currentUser.photo ? (
              <img
                src={currentUser.photo}
                alt="User"
                className="w-full h-full rounded-[25%]"
              />
            ) : (
              <div className="w-full h-full rounded-[25%] bg-[#E1EBFF] text-[#555] text-2xl font-bold flex justify-center items-center">
                {getInitial(currentUser.name)}
              </div>
            )}
            <span className="absolute -top-2 -right-2 bg-[#6374FA] text-white px-2 py-0.5 rounded-2xl text-xs">
              Me
            </span>
          </div>

          <p className="font-bold text-base text-text my-2.5">
            {currentUser.name || userData.name}
          </p>

          {currentUser.elo && (
            <div className="flex items-center justify-center gap-1.5">
              <img src={CrownGeneral} alt="Crown" className="w-8" />
              <p className="text-xl text-text m-0">{currentUser.elo || 'N/A'}</p>
            </div>
          )}
        </div>

        {/* VS Icon */}
        <div className="my-5">
          <img src={VirsusBlue} alt="VS" className="text-blue-500" />
        </div>

        {/* Opponent Box */}
        <div className="text-center my-2.5 relative w-full">
          {matchStatus === 'searching' ? (
            <>
              <div className="w-16 h-16 mx-auto">
                <div className="w-full h-full rounded-[25%] bg-[#E1EBFF] text-[#555] flex justify-center items-center">
                  -
                </div>
              </div>
              <p className="text-sm text-text">Searching for Opponent...</p>
              <div className="w-full h-1.5 bg-[#E0E0E0] rounded overflow-hidden mt-2">
                <div
                  className="h-full bg-[#6200ea] transition-all duration-300"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="w-16 h-16 mx-auto">
                {opponent.photo ? (
                  <img
                    src={opponent.photo}
                    alt="Opponent"
                    className="w-full h-full rounded-[25%]"
                  />
                ) : (
                  <div className="w-full h-full rounded-[25%] bg-[#E1EBFF] text-[#555] text-2xl font-bold flex justify-center items-center">
                    {getInitial(opponent.name || bot.name)}
                  </div>
                )}
              </div>
              <p className="font-bold text-base text-text my-2.5">
                {opponent.name || bot.name || 'Opponent'}
              </p>
              <div className="flex items-center justify-center gap-1.5">
                <img src={CrownGeneral} alt="Crown" className="w-8" />
                <p className="text-xl text-text m-0">
                  {opponent.elo || bot.elo || 'N/A'}
                </p>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Duel Tips */}
      <div className={`w-[90%] max-w-[300px] ${isResponsiveMobiles ? 'mb-12 pt-4' : 'mb-0 pt-12'}`}>
        <h3 className="m-0 mt-5 font-bold text-center text-base">Duel Tips</h3>
        <p className="text-[#747474] text-sm text-center tracking-wider leading-relaxed">
          Read the questions carefully: Take your time to thoroughly read each question before selecting an answer.
        </p>
      </div>

      {/* Bottom Button */}
      <button
        onClick={handleCancel}
        disabled={matchStatus === 'found'}
        className={`absolute bottom-4 w-[90%] rounded-full font-bold text-base cursor-pointer
          ${
            matchStatus === 'found'
              ? colorScheme === 'dark'
                ? 'bg-[#171573] text-white p-6 flex justify-center items-center'
                : 'bg-[#6374FA] text-white p-6 flex justify-center items-center'
              : 'bg-white text-black border-2 border-black p-3'
          }`}
      >
        {matchStatus === 'found' ? (
          <>
            <span className="spinner mr-2 w-5 h-5 border-4 border-white border-t-[#6374FA] rounded-full animate-spin" />
            Loading Duel...
          </>
        ) : (
          'Cancel'
        )}
      </button>
    </div>
  );
};

export default Matching;
