import React, { useState, useEffect } from 'react';
import LeaderBoardListModal from './LeaderBoardList';
import LeaderBoardTower from './LeaderBoardTower';
import TimeSwitch from './TimeSwitch';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

export default function LeaderboardPage({ selectedSubject, userId }) {
  const [globalLeaderboard, setGlobalLeaderboard] = useState([]);
  const [userRanking, setUserRanking] = useState(null);
  const [activeTab, setActiveTab] = useState('Rating');
  const [activeTimeFrame, setActiveTimeFrame] = useState('all');
  const [loading, setLoading] = useState(false);
  const isRespMobile = window.innerHeight <= 975;

  const fetchLeaderboardData = async (endpoint) => {
    setLoading(true); // Set loading to true before fetching
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`);
      const data = await response.json();
      if (endpoint.includes('performance')) {
        setGlobalLeaderboard(data.leaderboard);
        setUserRanking(data.current_user_rank);
      } else {
        setGlobalLeaderboard(data.global || data.leaderboard);
        setUserRanking(data.user_ranking || data.current_user_rank);
      }
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const fetchData = (tab) => {
    let endpoint;
    if (tab === 'Rating') {
      endpoint = `/api/leaderboard/user-global-ranking/${userId}/?category=${selectedSubject}`;
    } else if (tab === 'Performance') {
      endpoint = `/api/leaderboard/performance/${userId}/?category=${selectedSubject}&date_filter=${activeTimeFrame}`;
    } else if (tab === 'Friends') {
      endpoint = `/api/leaderboard/user-global-ranking/${userId}/?category=${selectedSubject}&friends_only=true`;
    }

    if (endpoint) {
      fetchLeaderboardData(endpoint);
    }
  };

  const handleTimeFrameChange = (timeFrame) => {
    setActiveTimeFrame(timeFrame);
    if (activeTab === 'Performance') {
      fetchLeaderboardData(`/api/leaderboard/performance/${userId}/?category=${selectedSubject}&date_filter=${timeFrame}`);
    }
  };

  const handleTabChange = (tab) => {
    const isDefaultSelection = tab === 'Rating' && activeTab !== 'Rating';
    setActiveTab(tab);
    MixpanelEvent('leaderboard_show', {
      category: selectedSubject,
      tab: tab.toLowerCase(),
      default_selection: isDefaultSelection ? 'true' : 'false',
    });
    sendPostHogEvent('leaderboard_show', {
      category: selectedSubject,
      tab: tab.toLowerCase(),
      default_selection: isDefaultSelection ? 'true' : 'false',
    });
    fetchData(tab);
  };

  useEffect(() => {
    fetchData(activeTab);
  }, [selectedSubject, userId, activeTab, activeTimeFrame]);



  return (
    <>
      {activeTab === 'Rating' && (
        <>
          <div className="absolute top-[210px] left-0 right-0 text-center z-0 flex justify-center items-center h-[calc(100vh-210px-50px)]">
            {loading ? (
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
                <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid border-gray-300 w-16 h-16"></div>
              </div>
            ) : (
              <LeaderBoardTower globalLeaderboard={globalLeaderboard} userRanking={userRanking} activeTab={activeTab} />
            )}
          </div>
          {loading ? null : <LeaderBoardListModal globalLeaderboard={globalLeaderboard} userRanking={userRanking} activeTab={activeTab} />}
        </>
      )}

      <div className="mx-6 bg-[#f0f2f5] rounded-[200px] overflow-hidden z-10">
        <div className="flex justify-evenly bg-tabContinerBackground p-2 z-10">
          {['Rating', 'Performance', 'Friends'].map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabChange(tab)}
              className={`px-4 py-2 text-sm text-center cursor-pointer ${activeTab === tab ? 'bg-tabButtonBackground rounded-[20px] text-text' : 'bg-transparent text-[#64748b]'}`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>


      {activeTab === 'Performance' && (
        <>
          <div className="absolute left-0 right-0 text-center z-0" style={{ ...(isRespMobile && { height: '550px', overflow: 'scroll', scrollbarWidth: 'none' }) }}>
            <div className="px-4 py-5">
              <TimeSwitch activeTime={activeTimeFrame} onTimeChange={handleTimeFrameChange} />
              <div className="bg-background rounded-md p-2 mx-2 my-5 border border-border border-l-4 border-l-green-500">
                <p className="text-xs font-bold text-text mb-0 uppercase">Performance Leaderboard</p>
                <p className="text-xs text-gray-500 mt-0">Find out how many questions you answered correctly amongst others.</p>
              </div>

              {loading ? (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-10 flex justify-center items-center">
                  <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid border-gray-300 w-16 h-16"></div>
                </div>
              ) : (
                <LeaderBoardTower globalLeaderboard={globalLeaderboard} userRanking={userRanking} activeTab={activeTab} />
              )}
            </div>
          </div>
          {loading ? null : <LeaderBoardListModal globalLeaderboard={globalLeaderboard} userRanking={userRanking} activeTab={activeTab} />}
        </>
      )}

      {activeTab === 'Friends' && (
        <>
          <div className="absolute top-[210px] left-0 right-0 text-center z-0 flex justify-center items-center h-[calc(100vh-210px-50px)]">
            {loading ? (
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
                <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid border-gray-300 w-16 h-16"></div>
              </div>
            ) : (
              <LeaderBoardTower globalLeaderboard={globalLeaderboard} userRanking={userRanking} activeTab={activeTab} />
            )}
          </div>
          {loading ? null : <LeaderBoardListModal globalLeaderboard={globalLeaderboard} userRanking={userRanking} activeTab={activeTab} />}
        </>
      )}
    </>
  );
}