import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import '@mantine/core/styles.css';
import { Container, Group, Button, Select, Title, Text, Paper, Divider } from '@mantine/core';
import { IconSearch, IconArrowLeft, IconX } from '@tabler/icons-react';
import { callPOSTAPI, callGETAPI } from '../../utils/NetworkUtil';
import { userStore } from '../../stores/userStore';
import PrelimsContent from './PrelimContent';
import { ReactComponent as NoDataImage } from '../../assets/images/NoData.svg';
import Lottie from 'react-lottie';
import pyqLoadingAnimation from '../../assets/json/pyq_loading.json';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import CustomSelect from '../../molecules/CustomMultiSelect';
import { wordList } from '../../constants/wordList';
import { CustomDivider } from '../../molecules/OR';
import moment from 'moment/moment';
import { ReactComponent as IconFilterCheck } from '../../assets/images/IconFilterCheck.svg';
import { ArrowLeft, ArrowLeftDark, CloseSquare, FilterRemove, SearchDark, SearchNormal, SendIcon } from '../../assets/images';
import { useTheme } from '../../context/themeProvider';

const PyqSearch = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Use location to get passed state
  const authUserDetails = userStore((state) => state.userData);
  const [recentSearches, setRecentSearches] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchInitiated, setIsSearchInitiated] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showWordList, setShowWordList] = useState(true);
  const windowHeight = window.innerHeight;
  const isMobile = window.innerWidth <= 768;
  const isResponsiveMobile = windowHeight <= 700;
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { colors, colorScheme } = useTheme();

  // Detect if there's a passed state from AutoScrollLists
  useEffect(() => {
    if (location.state?.selectedWord) {
      const word = location.state.selectedWord;
      setSearchTerm(word); // Set the passed word as search term
      handleSearch(word); // Trigger the search
    }
  }, [location.state]);

  useEffect(() => {
    if (authUserDetails?.username) {
      callGETAPI(
        `${process.env.REACT_APP_BASE_URL}/api/pyq-recent-searches/?username=${authUserDetails?.username}`,
        (resp) => {
          setRecentSearches(resp.data.data.recent_searches || []);
        },
        () => {
          setRecentSearches([]);
        }
      );
    }
  }, [authUserDetails?.username]);

  const handleSearch = async (term = searchTerm) => {
    if (!term.trim()) {
      setSearchResults([]); // If search term is empty, clear the results
      return;
    }

    MixpanelEvent('pyq_retrieved', {
      question_id: searchResults[0]?.id,
      action: isSearchInitiated ? 'search_success' : 'submit',
      year: selectedYear,
      search_id: authUserDetails.username,
      page_num: searchResults.length,
      search_query: searchTerm,
      last_pyq: searchResults.length === 0 ? false : true,
    });
    sendPostHogEvent('pyq_retrieved', {
      question_id: searchResults[0]?.id,
      action: isSearchInitiated ? 'search_success' : 'submit',
      year: selectedYear,
      search_id: authUserDetails.username,
      page_num: searchResults.length,
      search_query: searchTerm,
      last_pyq: searchResults.length === 0 ? false : true,
    });
    setIsSearchInitiated(true);
    setLoading(true);
    setShowWordList(false);
    setError(null);

    const payload = {
      query: term, // Use passed term or current searchTerm
      username: authUserDetails.username,
      category: selectedCategory.length > 0 ? selectedCategory : [], // Include category if selected
      year: selectedYear || undefined, // Include year if selected
    };

    callPOSTAPI(
      `${process.env.REACT_APP_BASE_URL}/api/pyq/`,
      JSON.stringify(payload),
      (resp) => {
        setSearchResults(resp?.data.data?.high_relevance_questions || []);
        setLoading(false);
      },
      (error) => {
        console.error('Error:', error);
        setError('Error fetching data');
        setLoading(false);
      }
    );
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setSearchResults([]); // Clear search results
    setIsSearchInitiated(false); // Hide PrelimsContent and stop showing results
    setShowWordList(false); // Clear selected year
  };

  const handleClearFilter = () => {
    setSelectedCategory([]); // Clear selected categories
    setSelectedYear(null);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const topicsData = [
    { value: 'history', label: 'History' },
    { value: 'geography', label: 'Geography' },
    { value: 'polity', label: 'Polity' },
    { value: 'current_affairs', label: 'Current Affairs' },
    { value: 'economy', label: 'Economy' },
    { value: 'science', label: 'Science' },
    { value: 'environment', label: 'Environment' },
  ];

  const currentDate = moment();
  const currentYear = currentDate.year();
  const lastYear = currentYear - 1;
  const last3Years = `${currentYear - 3}-${lastYear}`;
  const last5Years = `${currentYear - 5}-${lastYear}`;
  const last10Years = `${currentYear - 10}-${lastYear}`;

  if (window.ResizeObserver) {
    const resizeObserverErrSilencer = () => {
      const resizeObserverErr = window.ResizeObserver;
      window.ResizeObserver = function (callback) {
        const ro = new resizeObserverErr(callback);
        ro.observe = (target) => {
          try {
            resizeObserverErr.prototype.observe.call(ro, target);
          } catch (e) {
            // Ignore error
          }
        };
        return ro;
      };
    };
    resizeObserverErrSilencer();
  }

  useEffect(() => {
    if (!searchTerm.trim()) {
      setSearchResults([]);
      setIsSearchInitiated(false);
    }
  }, [searchTerm]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: pyqLoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const list1Ref = useRef(null);
  const list2Ref = useRef(null);
  const list3Ref = useRef(null);

  // Splitting the word list into 3 different groups
  const list1Words = wordList.slice(0, 5);
  const list2Words = wordList.slice(5, 10);
  const list3Words = wordList.slice(10, 15);

  useEffect(() => {
    const scrollSpeed1 = 1;
    const scrollSpeed2 = 1;
    const scrollSpeed3 = 1;

    const scrollHorizontally = (list, speed) => {
      if (list) {
        list.scrollLeft += speed;
        if (list.scrollLeft >= list.scrollWidth - list.clientWidth) {
          list.scrollLeft = 0; // Reset to the beginning for infinite scrolling
        }
      }
    };

    const interval1 = setInterval(() => scrollHorizontally(list1Ref.current, scrollSpeed1), 50);
    const interval2 = setInterval(() => scrollHorizontally(list2Ref.current, scrollSpeed2), 50);
    const interval3 = setInterval(() => scrollHorizontally(list3Ref.current, scrollSpeed3), 50);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, []);

  const handleWordClick = (word) => {
    // Navigate to the /pyqSearch route and pass the word as a parameter
    navigate('/pyqSearch', { state: { selectedWord: word } });
  };

  const renderWordItems = (listRef, words) => (
    <Paper
      ref={listRef}
      style={{
        overflowX: 'scroll',
        display: 'flex',
        whiteSpace: 'nowrap',
        backgroundColor: 'transparent',
        gap: 2,
        flexWrap: 'nowrap',
        width: '100%',
      }}
    >
      {/* Render words and clone them for infinite scrolling */}
      {[...words, ...words].map((item, index) => (
        <div
          key={index}
          className={`
            mr-[16px] 
            ${colorScheme === 'dark' ? 'bg-[#1B3260] text-white' : 'bg-[#EBF3FF] text-[#3C45CD]'}
            p-[8px_12px_8px_16px] 
            rounded-[25px] 
            flex items-center 
            whitespace-nowrap 
            font-[500] 
            text-[12.5px] 
            cursor-pointer 
            font-poppins
          `}
          onClick={() => handleWordClick(item)}
        >
          {item}
          <img
            src={SendIcon}
            alt="Send Icon"
            className="ml-[4px] mr-[8px] w-[16px] h-[16px]"
          />
        </div>
      ))}
    </Paper>
  );

  const LowerSection = () => (
    <>
      <div className="flex justify-center items-center w-full flex-col">
        <CustomDivider />
        <div className="text-[16px] font-semibold text-text mb-[20px]">
          Try any of the topics below
        </div>
      </div>

      <Group position="apart" noWrap style={{ display: 'flex', gap: 0, flexDirection: 'column', overflowX: 'auto' }}>
        {renderWordItems(list1Ref, list1Words)}
        {renderWordItems(list2Ref, list2Words)}
        {renderWordItems(list3Ref, list3Words)}
      </Group>
    </>
  );

  const toggleTooltip = () => {
    setTooltipVisible((prev) => !prev);
  };

  return (
    <Container
      size="sm"
      py="lg"
      style={{
        textAlign: 'center',
        minHeight: '100dvh', // Added to ensure full height
        position: 'relative',
      }}
    >
      {isSearchInitiated && searchResults.length > 0 && tooltipVisible && (
        <div style={{ position: 'relative', width: '100%' }}>
          {/* Custom Tooltip */}
          {tooltipVisible && (
            <div
              style={{
                position: 'absolute',
                top: '15dvh', // Changed from '7rem'
                right: '0', // Align with the button's right
                backgroundColor: '#2B2B40', // Dark background for tooltip
                color: '#fff',
                padding: '12px',
                borderRadius: '8px',
                width: '260px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                zIndex: 1000, // Ensure it is above other elements
              }}
            >
              <Group position="apart" style={{ marginBottom: '8px' }}>
                {selectedCategory.length + (selectedYear ? 1 : 0) > 0 ? (
                  <>
                    <Text weight={500} color="white" style={{ width: '80%' }}>
                      Applied Filters
                    </Text>
                    <IconX size={16} style={{ cursor: 'pointer' }} onClick={toggleTooltip} />
                  </>
                ) : (
                  <>
                    <Text weight={500} color="white" style={{ width: '80%' }}>
                      No Filters Applied
                    </Text>
                    <IconX size={16} style={{ cursor: 'pointer' }} onClick={toggleTooltip} />
                  </>
                )}
              </Group>
              {selectedCategory.length > 0 && (
                <div>
                  <Text size="sm" weight={400} color="white">
                    Topics: {selectedCategory.join(', ')}
                  </Text>
                </div>
              )}
              {selectedYear && (
                <div>
                  <Text size="sm" weight={400} color="white" style={{ marginTop: '8px' }}>
                    Year: {selectedYear}
                  </Text>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {isSearchInitiated && searchResults.length === 0 && (
        <div className="absolute top-[40dvh] left-0 right-0 bottom-0 z-0 text-center">
          <p className="text-lg font-medium">No PYQs found for</p>
          <p className="text-sm text-gray-500 mb-5">{searchTerm}</p>
          <NoDataImage className="mb-5 w-full" />
          <p className="text-lg font-medium">Try searching something else</p>
        </div>

      )}
      {loading && (
        <Lottie
          options={defaultOptions}
          height="100dvh"
          width="100%"
          className="absolute inset-0 z-0"
        />
      )}

      {/* Show "No PYQs found" only if search is initiated, there are no results, and it's not loading */}
      {isSearchInitiated && searchResults.length === 0 && !loading && (
        <div className="absolute top-[40dvh] left-0 right-0 bottom-0 z-0 text-center">
          <p className="text-lg font-medium">No PYQs found for</p>
          <p className="text-sm text-gray-500 mb-5">{searchTerm}</p>
          <NoDataImage className="mb-5 w-full" />
          <p className="text-lg font-medium">Try searching something else</p>
        </div>
      )}
      <div className="flex  items-center mb-6">
        <button
          onClick={() => {
            navigate('/pyq');
            MixpanelEvent('navigation_back', { source_screen: searchResults.length > 0 ? 'search_results' : 'search_pyqs' });
            sendPostHogEvent('navigation_back', { source_screen: searchResults.length > 0 ? 'search_results' : 'search_pyqs' });
          }}
          className="text-black p-0"
        >
          <img
            src={colorScheme === 'dark' ? ArrowLeftDark : ArrowLeft}
            alt="Arrow Left"
            className="cursor-pointer mr-2.5"
          />
        </button>
        <h2 className="m-0 text-[20px] font-medium z-[2] font-poppins">
          {searchResults.length > 0 ? 'Search Results' : 'Search PYQs'}
        </h2>
      </div>

      <div className="flex items-center w-full mb-4">
        <div className="relative flex items-center flex-1">
          <div className="absolute left-3 flex items-center justify-center bg-[#6374FA] rounded-full p-2">
            <img src={SearchNormal} className="w-full" />
          </div>

          {/* Input field */}
          <input
            ref={inputRef}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
            className={`w-full px-12 py-3 bg-cardBackground text-base border-2 border-border rounded-lg outline-none font-poppins'}`}
          />

          {/* Cross Icon (Clear Search Icon) */}
          {searchTerm && (
            <div
              className="absolute right-2 flex items-center cursor-pointer"
              onClick={handleClearSearch}
            >
              <img src={CloseSquare} className="w-full" />
            </div>
          )}
        </div>

        {/* Filter Button (outside the input box) */}
        {isSearchInitiated && searchResults.length > 0 && (
          <Button
            variant="light"
            radius="sm"
            size="xs"
            className="ml-2 bg-transparent text-[#61BD85] border border-lightgray h-[50px] w-[50px] p-1"
            onClick={toggleTooltip}
          >
            {/* Show Filter Count */}
            {selectedCategory.length > 0 || selectedYear ? (
              <div className="absolute right-[1.8rem] text-gray-400 rounded-full p-1 text-sm w-5 h-5 flex justify-center items-center ml-1">
                {selectedCategory.length + (selectedYear ? 1 : 0)}
              </div>
            ) : null}
            <IconFilterCheck width={20} height={20} className="ml-1" />
          </Button>
        )}
      </div>


      {loading ||
        (searchResults.length === 0 && (
          <>
            <Group mb="lg" className="flex gap-2.5 items-center z-100">
              <CustomSelect options={topicsData} onChange={setSelectedCategory} value={selectedCategory} />
              <Select
                placeholder="Select Year"
                data={[
                  { label: 'Last year', value: lastYear.toString() },
                  { label: 'Last 3 years', value: last3Years },
                  { label: 'Last 5 years', value: last5Years },
                  { label: 'Last 10 years', value: last10Years },
                ]}
                radius="md"
                className="flex-1"
                clearable
                styles={() => ({
                  input: {
                    borderColor: '#D1D1D1',
                    fontFamily: 'Poppins',
                    backgroundColor: colorScheme === 'dark' ? '#333333' : '#ffffff',
                    color: colorScheme === 'dark' ? '#ffffff' : '#000000',
                  },
                  dropdown: {
                    border: 'none',
                    backgroundColor: colorScheme === 'dark' ? '#333333' : '#ffffff',
                    color: colorScheme === 'dark' ? '#ffffff' : '#000000',
                  }
                })}
                value={selectedYear}
                onChange={setSelectedYear}
              />
              <Button
                variant="light"
                className="bg-[#FFEDED] text-[#D95353] border border-[#FFB6B6] rounded-lg p-1.5"
                onClick={handleClearFilter}
              >
                <img src={FilterRemove} alt="filter remove" className="w-full" />
              </Button>
            </Group>

            <button
              className="w-full bg-text text-background font-bold rounded-full py-3 text-lg disabled:opacity-50"
              onClick={() => handleSearch()}
              disabled={!searchTerm.trim()}
            >
              Search
            </button>

          </>
        ))}
      {searchResults.length > 0 && <PrelimsContent prelimsQuestions={searchResults} />}
      {!isSearchInitiated && !loading && searchResults.length === 0 && (
        <>
          {recentSearches.length > 0 && (
            <>
              <Divider className="my-8" />
              <Title order={6} className="my-5 text-[#747474] text-start font-poppins">
                RECENT SEARCHES
              </Title>
            </>
          )}

          <Group
            direction="column"
            spacing="lg"
            position="center"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: recentSearches.length > 0 ? 'column' : '',
              alignItems: 'flex-start',
              height: recentSearches.length > 0 ? 'calc(70dvh - 180px)' : 'auto',
              overflowY: 'auto',
              padding: '0px',
              flexWrap: recentSearches.length > 0 ? 'nowrap' : '',
              gap: recentSearches.length > 0 ? '6px' : '',
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {recentSearches.length > 0 ? (
              recentSearches.map((searchItem, index) => (
                <Group
                  key={index}
                  className="cursor-pointer w-full p-2.5"
                  onClick={() => {
                    setSearchTerm(searchItem);
                    handleSearch(searchItem);
                    setIsSearchInitiated(true);
                  }}
                >
                  <img src={SearchDark} alt="Search icon" className="w-[5%]" />
                  <Text className="font-poppins text-[#070519]">{searchItem}</Text>
                </Group>
              ))
            ) : (
              <LowerSection />
            )}
          </Group>
        </>
      )}
    </Container>
  );
};

export default PyqSearch;
