import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Flex, Text, Image, Stack, Box, ScrollArea } from '@mantine/core';
import { ArrowLeft, ArrowLeftDark, DiagonalPolygon, Vector } from '../../../assets/images';
import { IconX } from '@tabler/icons-react';
import { useTheme } from '../../../context/themeProvider';

const SelectBook = () => {
    const { colorScheme } = useTheme();
  
  const navigate = useNavigate();
  const location = useLocation();
  const { bookFlowType } = location.state || {};
  const [allBooks, setAllBooks] = useState([]);
  const [bookData, setBookData] = useState({
    bookList: [],
    classList: [],
    loader: true
  });
  const windowHeight = window.innerHeight;
  const isResponsiveMobile = windowHeight <= 700;
  const [activeClass, setActiveClass] = useState('')


  const handleBookClick = (book) => {
    navigate(`/book/${book.book_id}`, { state: { book, flowType: bookFlowType } });
  };

  function findAvailableClasses(books) {
    let classes = [];

    for (let book of books) {
      if (book.metadata && book.metadata.class && !classes.includes(book.metadata.class)) {
        classes.push(book.metadata.class);
      }
    }

    return classes;
  }

  useEffect(() => {
    const getBooks = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/bookrequest/?category=${bookFlowType || bookFlowType === 'book'}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const json = await response.json();
        let classes = findAvailableClasses(json)
        setAllBooks(json)
        setBookData({
          loader: false,
          classList: classes,
          bookList: json
        })
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };
    getBooks();
  }, [bookFlowType]);

  const BookCardColor = (bookid) => {
    switch (bookid) {
      case 0:
        return "#CBF2D8"
      case 1:
        return "#D5ECFA"
      case 2:
        return "#FFE5BD"
      case 3:
        return "#FFEDAC"
      case 4:
        return "#D4D7FF"
      case 5:
        return "#C1E5FF"
      case 6:
        return "#F2D7CB"
      case 7:
        return "#FFEDAC"
      case 8:
        return "#C1E5FF"
      case 9:
        return "#D5E4FA"
      case 10:
        return "#D4D7FF"
      case 11:
        return "#F5DAC3"
      case 12:
        return "#D2D3D7"
      case 13:
        return "#E5C8CD"
      case 14:
        return "#FFE5BD"
      case 15:
        return "#D5ECFA"
      case 16:
        return "#FFDA98"
      default:
        return "#FFE5BD"
    }
  }

  const handleClassClick = (selectedClass) => {
    setActiveClass((prevClass) => (prevClass === selectedClass ? '' : selectedClass));
    setBookData((prevData) => ({
      ...prevData,
      bookList: selectedClass === activeClass
        ? allBooks
        : allBooks.filter((book) => book.metadata?.class === selectedClass),
    }));
  }

  const BookItem = ({ book_id, book_name, book_description, image, color, onClick, metadata }) => (
    <Flex direction="column" style={{ ...styles.bookItem, backgroundColor: color, zIndex: 5 }} onClick={onClick}>
      <Flex style={{ height: '100%' }}>
        <Box style={styles.bookImage}>
          <img
            src={image}
            width={100}
            height={140}
            fit="contain"
            style={{
              transform: 'rotate(15deg)',
              transformOrigin: 'center',
            }}
          />
          {metadata.class && (
            <>
              <div style={styles.newTag}>
                {`Class ${metadata.class}`}
              </div>
              <div style={styles.polImg}>
                <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 8.5L10 0H0V8.5Z" fill="#B6B5B5" />
                </svg>
              </div>
            </>
          )}
        </Box>
        <div className="flex-1 flex flex-col justify-center">
            <h3 className="mb-1.5 font-medium text-[0.95em] font-poppins">
              {book_name}
            </h3>
            <p className="text-[#464646] text-[0.75em] leading-tight font-poppins">
              {book_description}
            </p>
          </div>
          <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center self-end">
            <img src={Vector} alt='Icon' className="w-full" />
          </div>
      </Flex>
    </Flex>
  );

  return (
    <>
      <style>
        {bookFlowType === "ncert" && `
          .m_c0783ff9:where([data-scrollbars='xy'], [data-scrollbars='y']):where([data-offset-scrollbars='xy'], [data-offset-scrollbars='y']) {
           
          }
        `}
        {bookFlowType === "upsc" && `
          .m_c0783ff9:where([data-scrollbars='xy'], [data-scrollbars='y']):where([data-offset-scrollbars='xy'], [data-offset-scrollbars='y']) {
            padding-inline-end: 0;
          }
        `}
      </style>

      {bookData.loader && (
        <div className="absolute top-1/2 left-1/2 h-screen w-full flex justify-center items-center bg-background transform -translate-x-1/2 -translate-y-1/2">
          <div className="border-8 border-gray-200 border-t-8 border-t-blue-500 rounded-full w-[60px] h-[60px] animate-spin"></div>
        </div>
      )}

      {!bookData.loader && (
        <div className="max-w-[28rem] mx-auto bg-background h-screen flex flex-col font-arial">
          <div className="flex items-center mt-2 p-4">
            <img 
              src={colorScheme === 'dark' ? ArrowLeftDark : ArrowLeft} 
              alt="Arrow Left" 
              className="w-5 h-5 mr-2.5 cursor-pointer" 
              onClick={() => navigate(-1)} 
            />
            <h2 className="font-medium text-base font-poppins">
              {bookFlowType === "ncert" ? "Select a NCERT Book" : "Select Book"}
            </h2>
          </div>

          {bookFlowType === "ncert" && bookData.classList.length > 0 && (
            <div className="flex items-center p-4 gap-4">
              {bookData.classList.map((item, index) => (
                <div
                  key={index}
                  className={`border px-3.5 py-2 rounded-lg font-roboto font-medium text-base cursor-pointer flex items-center gap-2 
                    ${activeClass === item 
                      ? 'text-[#6374FA] bg-[#EBF3FF] border-[#6374FA]' 
                      : 'text-text border-text bg-transparent'}`}
                  onClick={() => handleClassClick(item)}
                >
                  {`Class ${item}`}
                  {activeClass === item && <IconX color="#6374FA" size={16} className="mb-0.5" />}
                </div>
              ))}
            </div>
          )}

            <div 
              className="overflow-y-auto px-4 pb-8 bg-background"
              style={{
                maxHeight: windowHeight > 900 
                  ? '100vh' 
                  : isResponsiveMobile 
                    ? '33em' 
                    : '45em',
                overflowY: 'auto',
                padding: "1em",
              }}
            >
            <div className="space-y-2">
              {bookData.bookList.map((book) => (
                <BookItem 
                  key={book.book_id} 
                  {...book} 
                  color={BookCardColor(book.book_id)} 
                  onClick={() => handleBookClick(book)} 
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {

  bookItem: {
    padding: '15px',
    borderRadius: '10px',
    marginBottom: '10px',
    height: '150px',
    position: 'relative',
    cursor: 'pointer',
  },
  bookImage: {
    flex: '0 0 auto',
    marginRight: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  newTag: {
    position: 'absolute',
    top: '0px',
    right: '-0.8em',
    backgroundColor: '#FFFFFF',
    color: '#000',
    padding: '0.3em 0.6em',
    fontSize: '0.8em',
    fontWeight: '500',
    fontFamily: 'Poppins',
    zIndex: 8,
    border: "1px solid #B6B5B5",
    borderRadius: "4px 4px 0px 4px"
  },
  polImg: {
    position: 'absolute',
    top: '1.35em',
    right: '-0.7em',
    width: "3%",
  },

};

export default SelectBook;
