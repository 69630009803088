import React, { useState, useEffect } from 'react';
import { Button, Flex, Image, Text, Box, Popover, ScrollArea } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { BookChatUpsc, BookChatNcrt, HelpCircle, ArrowDown, NoHistory, Book1, Book2, ArrowDownDarKIcon } from '../../../assets/images';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTheme } from '../../../context/themeProvider';

const BookChatHistory = ({ chatHistorys, handleCallBack }) => {
  const navigate = useNavigate();
  const [showInfoTips, setShowInfoTips] = useState(false);
  const [chatHistory, setChatHistory] = useState(chatHistorys);
  const [filteredChats, setFilteredChats] = useState(chatHistorys);
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { colors, colorScheme } = useTheme();
  const windowHeight = window.innerHeight;

  const isResponsiveMobile = windowHeight <= 700;

  // Date Range State
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    if (!isFilterApplied) {
      setFilteredChats(chatHistory);
    }
  }, [chatHistory, isFilterApplied]);

  const handleDateRangeChange = (ranges) => {
    const { selection } = ranges;
    setDateRange([selection]);
  };

  const formatDateRange = (startDate, endDate) => {
    const optionsWithoutYear = { day: '2-digit', month: 'short' };
    const optionsWithYear = { day: '2-digit', month: 'short', year: 'numeric' };

    const formattedStartDate = new Date(startDate).toLocaleDateString('en-US', optionsWithoutYear);
    const formattedEndDate = new Date(endDate).toLocaleDateString('en-US', optionsWithYear);

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const handleApplyFilter = () => {
    const { startDate, endDate } = dateRange[0];
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    if (startDate.getTime() === endDate.getTime()) {
      const filtered = chatHistory.filter((chat) => {
        const chatDate = new Date(chat.created_at);
        return chatDate.toDateString() === startDate.toDateString();
      });
      setFilteredChats(filtered);
    } else {
      const filtered = chatHistory.filter((chat) => {
        const chatDate = new Date(chat.created_at);
        return chatDate >= startDate && chatDate <= adjustedEndDate;
      });
      setFilteredChats(filtered);
    }

    setIsFilterApplied(true);
    setPopoverOpened(false);
  };

  const handleClearFilter = () => {
    setFilteredChats(chatHistory);
    setIsFilterApplied(false);
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  const handleCancel = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    setPopoverOpened(false);
  };

  const handle = (bookid, chatId, chatCategory, chatIndex) => {
    const url = `/book/${bookid}`;
    navigate(url, {
      state: {
        chatHistoryId: chatId,
        flowType: chatCategory,
        chatIndex,
      },
    });
  };

  const BookQueryCard = ({ isNcert }) => {
    return (
      <div
        onClick={() => navigate('/book-chat/select-book', { state: { bookFlowType: isNcert ? 'ncert' : 'upsc' } })}
        className="flex flex-col items-center bg-cardBackground rounded-xl shadow-md cursor-pointer relative flex-1 min-w-[150px] max-w-[250px]"
      >
        <img
          src={isNcert ? Book2 : Book1}
          alt={isNcert ? 'NCERT Book' : 'UPSC Book'}
          className="w-full h-auto rounded-xl object-cover"
        />
        {isNcert && (
          <>
            <div className="absolute -top-[2px] -left-[13px] bg-[#EBF3FF] text-[#6374FA] 
              px-[0.8em] py-[0.3em] text-[0.8em] font-medium font-poppins 
              clip-path-[polygon(0_0,100%_0,100%_100%,15%_100%,0_85%)] 
              rounded-br-none rounded-md z-10">
              New
            </div>
            <div className="absolute top-[1.35em] -left-[0.8em] w-[11%] rotate-[80deg]">
              <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 8.5L10 0H0V8.5Z" fill="#6374FA" />
              </svg>
            </div>
          </>
        )}
        <div className="p-2.5">
        <button className="w-[9em] h-[3em] rounded-[5em] border border-text text-text flex items-center justify-center">
          <div className="flex items-center space-x-2">
            <span className="text-2xl leading-none">+</span>
            <span className="font-poppins font-medium text-[1em] leading-none">New Query</span>
          </div>
        </button>
      </div>

      </div>
    );
  };


  // Component for rendering individual chat history cards
  const ChatHistoryCard = ({ chat, reverseIndex }) => {
    return (
      <div
        className="bg-cardBackground rounded-2xl p-5 mb-4 border border-border"
        onClick={() => handle(chat?.book_id, chat?.id, chat?.book_category, reverseIndex)}
      >
        <Flex direction="column" gap="md">
          <Flex justify="space-between" align="center">
          <div
              className={`bg-selectedOptionBackground px-3 py-2 rounded-lg w-full text-sm font-medium ${colorScheme === 'dark' ? 'text-[#92A1FF]' : 'text-[#055160]'}`}
            >      
                    {chat.querystatus}
            </div>
          </Flex>
          {!chat.user_seen && (
            <button className="flex items-center justify-center relative p-2.5 w-[60px] text-[#6374FA] bg-[#EBF3FF] rounded-md">
              New
            </button>
          )}

          <Flex justify="space-between" align="center">
            <div className="text-sm font-semibold text-[#747474]">
              QUERY #{reverseIndex}
            </div>
            <div className="text-sm font-semibold text-[#747474]">
              {new Date(chat.created_at).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </div>
          </Flex>

          <div class="text-text line-clamp-2 text-md">
            {chat.query[chat.query.length - 1]}
          </div>


          <Flex justify="space-between" align="center">
            <Flex gap="md" align="center">
              <div 
                className={`
                  ${chat.book_category === 'upsc' ? 'bg-[#A76319]' : 'bg-[rgb(8_105_196)]'} 
                  text-white px-3 py-1 rounded-md text-sm
                `}
              >
                {chat.book_category.toUpperCase()}
              </div>
              <div
                size="sm"
                className="max-w-[220px] break-words whitespace-normal text-gray-500"
              >
                {chat.book_name}
              </div>
            </Flex>
            <Button
              variant="subtle"
              className="p-0 h-auto text-[#6374FA] text-2xl"
            >
              →
            </Button>
          </Flex>
        </Flex>
      </div>
    );
  };

  return (
    <div
      style={{
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100dvh - 215px)',
        maxHeight: '85dvh',
        minHeight: '400px',
        overflowX: 'hidden',
        overflowY: 'scroll',
        msOverflowStyle: 'none', // For IE and Edge
        scrollbarWidth: 'none', // For Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // For Chrome, Safari, and Opera
        },
      }}
    >
     <div class="flex flex-col items-center mb-3 mt-3 w-full">
       <div class="flex items-center gap-2 w-full justify-center flex-wrap">
        <div class="font-medium text-xl text-center">
            What if you could talk to a book?
          </div>
          <img src={HelpCircle} alt="Help icon"  class="w-5 cursor-pointer" onClick={handleCallBack} />
        </div>
      </div>

      <div class="flex gap-4 justify-center m-2.5 mb-1.25 w-[97%] flex-wrap">
        <BookQueryCard isNcert={true} />
        <BookQueryCard isNcert={false} />
      </div>

      <Flex
        justify="space-between"
        align="center"
        mb="10px"
        mt="10px"
        style={{
          padding: '0 1rem',
          color: '#747474',
          width: '100%',
          flexWrap: 'wrap',
          gap: '0.5rem',
        }}
      >
        <Text fw={500} size="12px">
          ASK A BOOK HISTORY
        </Text>
        {isFilterApplied ? (
          <Button
            style={{
              backgroundColor: 'white',
              borderColor: '#E5E7EB',
              color: '#1A1A1A',
              border: '1px solid #E5E7EB',
              padding: '0.5em 1em',
            }}
            onClick={handleClearFilter}
          >
            {formatDateRange(dateRange[0].startDate, dateRange[0].endDate)}
            <Text ml="xs" style={{ color: 'red' }}>
              ×
            </Text>
          </Button>
        ) : (
          <Popover width={300} position="bottom" withArrow shadow="md" opened={popoverOpened} onChange={setPopoverOpened}>
            <Popover.Target>
              <button
                onClick={() => setPopoverOpened((o) => !o)}
                fullWidth
                  className="w-auto h-9 px-4 py-2 bg-cardBackground text-text border border-border rounded-lg hover:bg-transparent flex items-center"
              >
                Sort by Date
                 <img src={colorScheme === 'dark' ? ArrowDownDarKIcon : ArrowDown} alt="" className="icon ml-2.5 h-3.5 w-3.5" />
              </button>
            </Popover.Target>
            <Popover.Dropdown
              style={{
                zIndex: 1001,
                position: 'fixed',
                top: '60%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: window.innerWidth <= 400 ? '22em' : '28em',
                boxSizing: 'border-box',
                padding: '1em',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <DateRangePicker
                ranges={dateRange}
                onChange={handleDateRangeChange}
                moveRangeOnFirstSelection={false}
                rangeColors={[colors.coloredButton]}
                maxDate={new Date()}
              />
              <Flex mt="md" justify="space-between" w="100%">
                <Button
                  variant="outline"
                  color="gray"
                  onClick={handleCancel}
                  style={{
                    flex: 1,
                    marginRight: '8px',
                    color: 'black',
                    border: 'none',
                    padding: '0.5em',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleApplyFilter}
                  style={{
                    flex: 1,
                    backgroundColor: 'black',
                    borderRadius: '200px',
                    color: 'white',
                    padding: '0.5em',
                  }}
                >
                  Select Range
                </Button>
              </Flex>
            </Popover.Dropdown>
          </Popover>
        )}
      </Flex>
      <div class="flex-1">
          {filteredChats.length === 0 ? (
            <div class="mt-8 flex flex-col items-center justify-center">
              <img src={NoHistory} class="w-[150px] h-[150px]" alt="No history" />
              <div class="flex flex-col text-center">
                <h3 class="m-0 p-0">No history found</h3>
                <h4 class="text-gray-500 mt-[5px]">You've not had any chat for the selected topic & date</h4>
              </div>
            </div>
          ) : (
            filteredChats.slice().map((chat, index) => (
              <ChatHistoryCard key={index} chat={chat} reverseIndex={filteredChats.length - index} />
            ))
          )}
        </div>


      {showInfoTips && <div>Info Tips Modal</div>}
    </div>
  );
};
export default BookChatHistory;
