import React, { useState, useRef, useEffect } from 'react';
import { ArrowLeft, ArrowDown, HelpCircle, ArrowLeftDark, ArrowDownDarKIcon } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../constants/iconData';
import InfoTips from './InfoTip/DuelStateInfoTip';
import LeaderBoard from './LeaderBoads';
import MyStats from './MyStats';
import { userStore } from '../../stores/userStore';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useTheme } from '../../context/themeProvider';

export default function DuelPage() {
  const [activeTab, setActiveTab] = useState('leaderboards');
  const [selectedSubject, setSelectedSubject] = useState('polity');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showInfoTips, setShowInfoTips] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const userData = userStore((state) => state.userData);
  const { colorScheme } = useTheme();

  const backClick = () => {
    navigate(-1);
    MixpanelEvent('navigation_back', { source_screen: 'duel_stats' });
    sendPostHogEvent('navigation_back', { source_screen: 'duel_stats' });
  };

  const handleSubjectChange = (subject) => {
    setSelectedSubject(subject.toLowerCase());
    setIsDropdownOpen(false);
  };

  const handleInfoTip = () => {
    MixpanelEvent('info_tip', { type: 'duel_stats', source: 'clicked' });
    sendPostHogEvent('info_tip', { type: 'duel_stats', source: 'clicked' });
    setShowInfoTips(true);
  };

  const closeModal = () => {
    MixpanelEvent('screen_closed', { source_screen: 'duel_stats', close_type: 'modal' });
    sendPostHogEvent('screen_closed', { source_screen: 'duel_stats', close_type: 'modal' });
    setShowInfoTips(false);
  };

  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  const subjects = [
    { value: 'csat', label: 'CSAT' },
    { value: 'history', label: 'History' },
    { value: 'geography', label: 'Geography' },
    { value: 'polity', label: 'Polity' },
    { value: 'current_affairs', label: 'Current Affairs' },
    { value: 'economy', label: 'Economy' },
    { value: 'science', label: 'Science' },
    { value: 'environment', label: 'Environment' },
  ];

  const displaySubject = subjects.find((subject) => subject.value === selectedSubject)?.label || 'Subject';

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="max-w-[28em] mx-auto relative">
      <div className="flex justify-between items-center h-[3.9em] py-2 px-0 bg-background text-text absolute top-0 w-full z-2">
        <div className="flex items-center min-w-[160px]">
          <img onClick={backClick}
            src={colorScheme === 'dark' ? ArrowLeftDark : ArrowLeft}
            alt="Arrow Left" className="cursor-pointer ml-4" />
          <span className="text-[16px] ml-2 flex items-center">
            Duel Stats
            <img
              src={HelpCircle}
              alt="Help icon"
              className="ml-2 w-5 h-5 cursor-pointer"
              onClick={handleInfoTip}
            />
          </span>
        </div>

        <div ref={dropdownRef} className="relative mr-4 w-[300px]">
          <div
            onClick={toggleDropdown}
            className="text-[1em] rounded-md bg-background cursor-pointer flex items-center justify-end"
          >
            <span>{displaySubject}</span>
            <img src={colorScheme === 'dark' ? ArrowDownDarKIcon : ArrowDown} className="ml-2 opacity-70" alt="arrow down" />
          </div>
          {isDropdownOpen && (
            <div className="absolute bg-cardBackground border border-border rounded-md z-10 w-full min-h-[360px] shadow-[0_2px_5px_rgba(0,0,0,0.1)] top-[calc(100%+0.25rem)] left-0">
              {Object.entries(icons)
                .filter(([key]) => key !== 'all_subjects')
                .map(([key, icon]) => (
                  <div
                    key={key}
                    onClick={() => handleSubjectChange(key)}
                    className={`flex items-center cursor-pointer p-3 bg-transparent ${
                      colorScheme === 'dark' ? 'hover:bg-gray-500' : 'hover:bg-gray-100'
                    }`}
                    >
                    <div className="mr-3 w-6 h-6">{icon}</div>
                    <span className="flex-1 ml-1 mt-2">
                      {subjects.find((subject) => subject.value === key)?.label || key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')}
                    </span>
                    {selectedSubject === key && (
                      <div className="ml-3 w-6 h-6 rounded-full bg-indigo-500 flex items-center justify-center relative">
                        <div className="w-4 h-4 rounded-full absolute"></div>
                        <div className="text-white relative z-1">✓</div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>


      <div className="flex justify-between items-center w-full bg-background text-black absolute top-14 z-1 overflow-hidden">
        <div className="flex justify-evenly w-full">
          <div
            onClick={() => setActiveTab('leaderboards')}
            className={`text-sm cursor-pointer py-2 px-5 relative flex justify-center items-center flex-1  border-b-2 ${activeTab === 'leaderboards' ? 'border-indigo-500' : 'border-border'} text-${activeTab === 'leaderboards' ? 'text' : 'gray-500'}`}
          >
            <span>Leaderboards</span>
          </div>
          <div
            onClick={() => setActiveTab('mystats')}
            className={`text-sm cursor-pointer py-2 px-5 relative flex justify-center items-center flex-1 border-b-2 ${activeTab === 'mystats' ? 'border-indigo-500' : 'border-border'} text-${activeTab === 'mystats' ? 'text' : 'gray-500'}`}
          >
            <span>My Stats</span>
          </div>
        </div>
      </div>

      <div style={{ paddingTop: '120px' }}>
        {activeTab === 'leaderboards' && <LeaderBoard selectedSubject={selectedSubject} userId={userData.user} />}
        {activeTab === 'mystats' && <MyStats selectedSubject={selectedSubject} userId={userData.user} />}
      </div>
      <InfoTips opened={showInfoTips} onClose={closeModal} />
    </div>
  );
}