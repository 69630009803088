import React from 'react';
import { ArrowLeftDarkIcon, ArrowRightDarkIcon, ArrowLeftFilled, ArrowRightFilled } from '../../assets/images';
import { useTheme } from '../../context/themeProvider';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const { colorScheme } = useTheme();


  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(renderPageButton(i));
      }
    } else {
      if (currentPage === 1) {
        for (let i = 1; i <= 3; i++) {
          pageNumbers.push(renderPageButton(i));
        }
        pageNumbers.push(
          <span key="ellipsis-end" className="px-2 text-black font-bold">
            ...
          </span>
        );
        pageNumbers.push(renderPageButton(totalPages));
      } else if (currentPage === totalPages) {
        pageNumbers.push(renderPageButton(1));
        pageNumbers.push(
          <span key="ellipsis-start" className="px-2 text-black font-bold">
            ...
          </span>
        );
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pageNumbers.push(renderPageButton(i));
        }
      } else {
        pageNumbers.push(renderPageButton(1));

        if (currentPage > 2) {
          pageNumbers.push(
            <span key="ellipsis-start" className="px-2 text-black font-bold">
              ...
            </span>
          );
          pageNumbers.push(renderPageButton(currentPage - 1));
        }

        pageNumbers.push(renderPageButton(currentPage));

        if (currentPage < totalPages) {
          pageNumbers.push(
            <span key="ellipsis-end" className="px-2 text-black font-bold">
              ...
            </span>
          );
        }

        pageNumbers.push(renderPageButton(totalPages));
      }
    }

    return pageNumbers;
  };

  const renderPageButton = (pageNumber) => (
    <button
      key={pageNumber}
      onClick={() => onPageChange(pageNumber)}
      className={`w-10 h-10 flex items-center justify-center mx-1 text-sm border border-gray-300 rounded-md ${
        currentPage === pageNumber ? 'bg-[#6374FA] text-white' : 'bg-white text-black'
      }`}
    >
      {pageNumber}
    </button>
  );

  return (
    <div className="flex items-center justify-center my-4">
      <button
        className={`w-10 h-10 flex items-center justify-center border border-gray-300 rounded-md ${currentPage === 1 ? 'opacity-50' : ''}`}
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <img
          src={
            currentPage === 1
              ? colorScheme === 'dark'
                ? ArrowLeftDarkIcon
                : ArrowLeftFilled
              : colorScheme === 'dark'
              ? ArrowLeftDarkIcon
              : ArrowLeftFilled
          }
          alt="Previous"
          className="w-5 h-5"
        />
      </button>

      <div className="flex items-center justify-center mx-4">{renderPageNumbers()}</div>

      <button
        className={`w-10 h-10 flex items-center justify-center border border-gray-300 rounded-md ${currentPage === totalPages ? 'opacity-50' : ''}`}
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <img
          src={
            currentPage === totalPages
              ? colorScheme === 'dark'
                ? ArrowRightDarkIcon
                : ArrowRightFilled
              : colorScheme === 'dark'
              ? ArrowRightDarkIcon
              : ArrowRightFilled
          }
          alt="Next"
          className="w-5 h-5"
        />
      </button>
    </div>
  );
};

export default Pagination;