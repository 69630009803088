import React, { useState, useEffect, useRef, useMemo } from 'react';
import { IconHeart, IconMessageCircle2, IconArrowLeft, IconExclamationCircle, IconCheck, IconX } from '@tabler/icons-react';
import { Button, Flex, Text, Badge, Title, Stack, Radio, Textarea, Group } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useNews } from '../../context/newsProvide';
import { useTheme } from '../../context/themeProvider';
// import { useSpring, animated } from 'react-spring';
import * as successAnimation from '../../assets/json/report_success.json';
import { ArrowLeft, ArrowLeftDark, ArrowLeftDarkIcon, ArrowLeftFilled, ArrowRightDarkIcon, ArrowRightFilled, NoDataImage } from '../../assets/images';
import { userStore } from '../../stores/userStore';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import Lottie from 'react-lottie';

export default function NewsMcqPage() {
  const { colors, colorScheme } = useTheme();
  const { id } = useParams();
  const { newsDetailData } = useNews();
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionStates, setQuestionStates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = window.innerWidth <= 2500;
  const [details, setDetails] = useState('');
  const [resportData, setResportData] = useState('');
  const [isReportSubmited, setReportSubmit] = useState(false);
  const [selectedQuestion, setSelectedQuestionId] = useState('');
  const userData = userStore((state) => state.userData);
  const userId = userData.user;
  const containerRef = useRef(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const mcqQuestions = useMemo(() => newsDetailData.mcqs?.questions || [], [newsDetailData.mcqs?.questions]);

  // const [props, set] = useSpring(() => ({
  //   x: 0,
  //   config: { tension: 300, friction: 30 },
  // }));

  useEffect(() => {
    setQuestionStates(mcqQuestions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null })));
  }, [mcqQuestions]);

  const currentQuestion = mcqQuestions[currentQuestionIndex];

  const handleOptionChange = (index) => {
    const correctAnswerIndex = currentQuestion.answer;
    const isAnswerCorrect = questionStates[currentQuestionIndex].answerIndex === correctAnswerIndex;
    if (!questionStates[currentQuestionIndex].isSubmitted) {
      setQuestionStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[currentQuestionIndex].selectedOption = currentQuestion.options[index];
        newStates[currentQuestionIndex].answerIndex = index;
        return newStates;
      });
    }
    const optionsIndex = ['A', 'B', 'C', 'D'];
    const selectedOption = optionsIndex[index];
    MixpanelEvent('question_option_selected', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_mcq',
    });
    sendPostHogEvent('question_option_selected', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_mcq',
    });
  };

  const dictMyCategoryName = (category) => {
    const categories = {
      economy: 'economy',
      history: 'history',
      geography: 'geography',
      polity: 'polity',
      science: 'science',
      environment: 'environment',
      csat: 'csat',
    };

    const categoryLower = category?.toLowerCase();

    return categories[categoryLower] || 'current_affairs';
  };

  const discussWithAi = (item, checkedStat) => {
    const optionKey = ['a', 'b', 'c', 'd'];
    let tutorConnectParam = {
      assoc_news: newsDetailData.id,
      flowType: 'postGame',
      source: 'news_feed',
      id: null,
      chatType: 'ai_tutor',
      node_id: null,
      chatgraph_id: null,
      messages: [
        {
          role: 'user',
          convo_id: '',
          type: 'Question',
          content: item.question,
        },
        {
          role: 'user',
          convo_id: '',
          type: 'My Answer',
          content: item.options[parseInt(checkedStat)],
        },
      ],
      preSelectQuestion: {
        answer: null,
        choices: [
          {
            a: item.options[0],
          },
          {
            b: item.options[1],
          },
          {
            c: item.options[2],
          },
          {
            d: item.options[3],
          },
        ],
        category: dictMyCategoryName(item.category),
        question: item.question,
        question_id: item.id,
        user_answer: item.options[checkedStat],
        user_option: optionKey[checkedStat],
        correct_answer: optionKey[item.answer],
        options: item.options,
      },
      category: dictMyCategoryName(item.category),
      preSelectAnswer: item.options[parseInt(checkedStat)],
    };
    return tutorConnectParam;
  };

  const handleSubmit = () => {
    const correctAnswerIndex = currentQuestion.answer;
    const isAnswerCorrect = questionStates[currentQuestionIndex].answerIndex === correctAnswerIndex;
    setQuestionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[currentQuestionIndex].isSubmitted = true;
      newStates[currentQuestionIndex].isCorrect = isAnswerCorrect;
      return newStates;
    });

    const optionsIndex = ['A', 'B', 'C', 'D'];
    const selectedOption = optionsIndex[questionStates[currentQuestionIndex].answerIndex];
    MixpanelEvent('question_answer_submitted', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_mcq',
    });
    sendPostHogEvent('question_answer_submitted', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_mcq',
    });
  };

  const handleNextQuestion = () => {
    // set({ x: -window.innerWidth });
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex < mcqQuestions.length - 1 ? prevIndex + 1 : prevIndex));
      // set({ x: 0, immediate: true });
    }, 300);
  };

  const handlePreviousQuestion = () => {
    // set({ x: window.innerWidth });
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      // set({ x: 0, immediate: true });
    }, 300);
  };

  const handleTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
    // set({ x: touchStart - e.targetTouches[0].clientX });
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe) {
      handleNextQuestion();
    } else if (isRightSwipe) {
      handlePreviousQuestion();
    } else {
      // set({ x: 0 });
    }
  };

  const handleDiscussWithAi = () => {
    const aiTutorParams = discussWithAi(currentQuestion, questionStates[currentQuestionIndex].answerIndex);
    navigate(`/chat/mcq/`, { state: { aiTutorParams } });
    MixpanelEvent('discuss_with_ai', { source: 'news_mcq' });
    sendPostHogEvent('discuss_with_ai', { source: 'news_mcq' });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setDetails('');
  };

  const handleReport = (prelimsQuestions) => {
    setIsOpen(true);
    setSelectedQuestionId(prelimsQuestions);
    setReportSubmit(false);
    setResportData('');
  };

  const handleResportSubmit = async () => {
    setReportSubmit(true);
    let postPayload = {
      flow: 'newspyq',
      question_id: selectedQuestion,
      user: userId,
      option: resportData,
      reason: '',
    };

    if (resportData === 'Other') {
      postPayload.reason = details;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();
      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    } catch (error) {}
  };

  return (
    <div className={`font-[Poppins] max-w-[600px] mx-auto h-[89dvh] flex flex-col `}>
      <div className="p-4 bg-background  text-text shadow-[0px_4px_9.2px_0px_rgba(0,0,0,0.10)]">
        <Flex align="center" className="mb-5">
          <img
            onClick={() => {
              navigate(-1);
              MixpanelEvent('navigation_back', { source_screen: 'news_mcq' });
              sendPostHogEvent('navigation_back', { source_screen: 'news_mcq' });
            }}
            src={colorScheme === 'dark' ? ArrowLeftDark : ArrowLeft}
            alt="Arrow Left"
            className="cursor-pointer mr-2.5"
          />
          <Text className="text-xl font-semibold">Related UPSC MCQs</Text>
        </Flex>

        <div className="flex justify-between items-center w-full">
          <img
            src={colorScheme === 'dark' ? ArrowLeftDarkIcon : ArrowLeftFilled}
            className={`cursor-pointer rounded-lg border border-[#D1D1D1] p-1.5 ${currentQuestionIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handlePreviousQuestion}
            alt="Arrow Left"
          />
          <div className="flex-1 overflow-hidden mx-2.5">
            <div className="flex overflow-x-auto scrollbar-hidden">
              {mcqQuestions.map((_, index) => (
                <div
                  key={index}
                  className={`min-w-[60px] h-[30px] flex justify-center items-center rounded-full cursor-pointer mx-1.5 flex-shrink-0 ${
                    index === currentQuestionIndex
                      ? `${colorScheme === 'dark' ? 'bg-[#EBF3FF] text-black' : 'bg-[rgb(219,219,219)] text-[#6374FA]'}`
                      : 'bg-transparent text-text'
                  }`}
                  onClick={() => setCurrentQuestionIndex(index)}
                >
                  Q{index + 1}
                </div>
              ))}
            </div>
          </div>

          <img
            src={colorScheme === 'dark' ? ArrowRightDarkIcon : ArrowRightFilled}
            className={`cursor-pointer rounded-lg border border-[#D1D1D1] p-1.5  ${
              currentQuestionIndex === mcqQuestions.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleNextQuestion}
            alt="Arrow Right"
          />
        </div>
      </div>
      {mcqQuestions.length === 0 ? (
        <div className="text-center p-5 h-[60vh] flex justify-center items-center flex-col">
          <img src={NoDataImage} alt="no data" className="mb-5" />
          <Text className={`font-semibold mb-2.5 text-[${colors.text}]`}>No Related Questions Found</Text>
          <Text className={`text-[${colors.subtleText}]`}>We were unable to generate any MCQs on this article. Please tap on try again below.</Text>
        </div>
      ) : (
        <>
          <div
            style={{
              ...styles.container,
              backgroundColor: colors.cardBackground,
              border: `1px solid ${colors.border}`,
              margin: '15px',
              height: 'calc(100dvh - 220px)',
              position: 'relative',
            }}
            ref={containerRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div
              style={{
                ...styles.bottomContainer,
                backgroundColor: colors.cardBackground,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflowY: 'auto',
              }}
            >
              <div style={styles.questionMeta}>
                <Badge color={colors.subtleText}>CURRENT AFFAIRS</Badge>
                <IconHeart style={{ ...styles.heartIcon, color: colors.subtleText }} />
              </div>

              <Text className={`text-base font-medium mb-5 text-[${colors.text}]`}>
                {currentQuestion.question.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Text>

              <div className="flex flex-col gap-2.5">
                {currentQuestion.options.map((option, index) => {
                  const isSubmitted = questionStates[currentQuestionIndex]?.isSubmitted;
                  const isCorrectAnswer = index === currentQuestion.answer;
                  const isSelectedAnswer = index === questionStates[currentQuestionIndex]?.answerIndex;

                  return (
                    <div
                      key={index}
                      onClick={() => handleOptionChange(index)}
                      className={`
                      rounded-lg p-4 cursor-pointer border flex items-center gap-1.5 
                      ${
                        isSubmitted
                          ? isCorrectAnswer
                            ? 'bg-[#2F6545] border-[#2F6545]'
                            : isSelectedAnswer
                            ? 'bg-[#9F4749] border-[#9F4749]'
                            : 'bg-cardBackground border-border'
                          : isSelectedAnswer
                          ? 'bg-selectedOptionBackground border-selectedOptionBackground'
                          : 'bg-cardBackground border-border'
                      }
                    `}
                    >
                      <div className="flex items-center">
                        <div
                          className={`w-6 h-6 rounded-full flex justify-center items-center mr-2.5 transition-all duration-300 ${
                            questionStates[currentQuestionIndex]?.isSubmitted
                              ? index === currentQuestion.answer
                                ? 'bg-[#34d399]'
                                : index === questionStates[currentQuestionIndex].answerIndex
                                ? 'bg-[#ff9999]'
                                : 'bg-transparent'
                              : 'bg-transparent'
                          } ${
                            questionStates[currentQuestionIndex]?.isSubmitted &&
                            (index === currentQuestion.answer || index === questionStates[currentQuestionIndex].answerIndex)
                              ? 'border-none'
                              : 'border-2 border-[#6374FA]'
                          }`}
                        >
                          {questionStates[currentQuestionIndex]?.answerIndex === index && !questionStates[currentQuestionIndex]?.isSubmitted && (
                            <div className="w-[14.5px] h-[14.5px] bg-[#6374FA] rounded-full"></div>
                          )}
                          {questionStates[currentQuestionIndex]?.isSubmitted &&
                            (index === currentQuestion.answer ? (
                              <IconCheck className="w-3.5 h-3.5 text-white" />
                            ) : index === questionStates[currentQuestionIndex].answerIndex ? (
                              <IconX className="w-3.5 h-3.5 text-white" />
                            ) : null)}
                        </div>
                        <Text className={`mr-2.5 text-[${colors.text}]`}>{String.fromCharCode(65 + index)}.</Text>
                        <Text className={`text-[15px] font-medium flex-1 text-[${colors.text}]`}>{option}</Text>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flex justify-between p-4 shadow-[0px_-4px_24px_0px_rgba(0,0,0,0.12)] w-full bg-cardBackground border border-border absolute bottom-0 left-0 right-0 z-50 box-border sm:mb-0 mb-4">
            <button
              className="text-[#D95353] flex items-center justify-center gap-1 py-2 px-4 rounded-full font-medium focus:outline-none"
              onClick={() => handleReport(currentQuestion.id)}
            >
              <IconExclamationCircle size={16} />
              Report
            </button>
            {!questionStates[currentQuestionIndex]?.isSubmitted ? (
              <button
                disabled={!questionStates[currentQuestionIndex]?.selectedOption}
                className={`${
                  questionStates[currentQuestionIndex]?.selectedOption
                    ? colorScheme === 'dark'
                      ? 'bg-white text-black'
                      : 'bg-black text-white'
                    : 'bg-transparent text-[#B9B9B9] cursor-not-allowed border border-[#D1D1D1] hover:bg-transparent transition-all duration-300 opacity-50'
                } rounded-full py-2 px-6 h-[2.5rem] text-sm font-medium`}
                onClick={handleSubmit}
              >
                Submit
              </button>
            ) : (
              <button
                className="bg-white text-black border border-black rounded-full py-2 px-6 h-[2.5rem] text-sm font-medium hover:bg-[#F3F3F3] transition duration-300"
                onClick={handleDiscussWithAi}
              >
                Discuss with AI
              </button>
            )}
          </div>
        </>
      )}

      <ReportModaContent
        isMobile={isMobile}
        isOpen={isOpen}
        closeModal={handleCloseModal}
        resportData={resportData}
        details={details}
        handleResportSubmit={handleResportSubmit}
        styles={styles}
        setResportData={setResportData}
        isReportSubmited={isReportSubmited}
        onChange={(e) => setDetails(e.currentTarget.value)}
        colors={colors}
      />
    </div>
  );
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: successAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ReportModaContent = ({
  isOpen,
  isMobile,
  closeModal,
  isReportSubmited,
  resportData,
  setResportData,
  details,
  onChange,
  handleResportSubmit,
  styles,
  colors,
}) => {
  if (!isOpen) return null;

  return (
    <div className="absolute top-0 bottom-0 left-0 w-full h-full flex justify-center items-end z-[1000]">
      <div className={`bg-cardBackground text-text p-5 rounded-t-xl w-full text-center shadow-lg ${isMobile ? 'max-w-[28rem]' : 'max-w-[22vw]'}`}>
        <div className="flex items-center justify-center w-full">
          <span className="block w-[89px] h-[6px] bg-[#E8E8E8] rounded-full cursor-pointer" onClick={closeModal}></span>
        </div>
        <button
          onClick={closeModal}
          className="relative right-[-5px] border-none bg-none cursor-pointer text-xl font-bold text-black w-full flex justify-end"
        >
          &times;
        </button>

        {!isReportSubmited ? (
          <>
            <Title className="text-left m-4 text-xl">Report Question</Title>
            <Stack className="space-y-4">
              <Radio.Group value={resportData} onChange={setResportData} size="1em">
                {[
                  'Wrong category',
                  'Answer is incorrect',
                  'Incomplete question',
                  'Mistake in question',
                  'Mistake in options',
                  'Poorly formatted question',
                  'Poorly formatted options',
                  'Other',
                ].map((item, index) => (
                  <Radio key={index} value={item} label={item} className="p-4" />
                ))}
              </Radio.Group>

              {resportData === 'Other' && (
                <>
                  <Text className="text-left">Other reason*</Text>
                  <Textarea
                    placeholder="Provide details about your issue here..."
                    value={details}
                    rows={4}
                    radius="sm"
                    className="mt-2.5 text-sm"
                    onChange={onChange}
                  />
                </>
              )}
            </Stack>

            <Group position="center" mt="lg">
              <Button
                fullWidth
                radius="xl"
                size="md"
                variant="filled"
                color="dark"
                disabled={!resportData}
                className={`bg-background text-white font-medium ${!resportData && 'bg-gray-400'}`}
                onClick={handleResportSubmit}
              >
                Submit Feedback
              </Button>
            </Group>
          </>
        ) : (
          <>
            <Title className="m-4 text-xl">Report Submitted</Title>
            <Title className="m-4 text-md">Thank you! Your report has been submitted.</Title>
            <Lottie options={defaultOptions} height={200} width={200} />
          </>
        )}
      </div>
    </div>
  );
};
const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end', // Aligns modal at the bottom
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px 10px 0 0', // Rounded only on top corners
    width: '100%',
    textAlign: 'center',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: '0', // Modal is at the bottom
  },
  outerContainer: {
    fontFamily: 'Poppins, Arial, sans-serif',
    color: '#333',
    maxWidth: '600px',
    margin: '0 auto',
    minHeight: '100dvh',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    position: 'relative',
    // paddingBottom: '80px',
  },
  container: {
    position: 'relative',
    border: '1px solid #E8E8E8',
    margin: '15px',
    height: 'calc(100dvh - 180px)',
    overflow: 'hidden',
  },
  animatedContainer: {
    width: '100%',
    willChange: 'transform',
  },
  topContainer: {
    padding: '15px',
    background: 'white',
    boxShadow: '0px 4px 9.2px 0px rgba(0, 0, 0, 0.10)',
  },
  bottomContainer: {
    margin: '0 15px',
    background: 'white',
    marginTop: '20px',
    marginBottom: '20px',
    borderRadius: '12px',
  },
  header: {
    marginBottom: '20px',
  },
  backArrow: {
    cursor: 'pointer',
    marginRight: '10px',
  },
  headerText: {
    fontSize: '20px',
    fontWeight: '600',
  },
  questionNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  qNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    marginLeft: '10px',
  },
  navIcon: {
    cursor: 'pointer',
    borderRadius: '8px',
    border: '1px solid #D1D1D1',
    padding: '7px 5px',
    backgroundColor: 'white',
  },
  questionNumber: {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  questionMeta: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  heartIcon: {
    color: '#888',
    cursor: 'pointer',
  },
  questionText: {
    fontSize: '16px',
    fontWeight: '500',
    marginBottom: '20px',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  optionBox: {
    padding: '15px',
    borderRadius: '8px',
    border: '1px solid #e0e0e0',
    cursor: 'pointer',
  },
  optionContent: {
    display: 'flex',
    alignItems: 'center',
  },
  radioCircle: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    transition: 'all 0.3s ease',
  },
  purpleDot: {
    width: '14.5px',
    height: '14.5px',
    backgroundColor: '#6374FA',
    borderRadius: '50%',
  },
  icon: {
    width: '14px',
    height: '14px',
    color: 'white',
  },
  optionLabel: {
    marginRight: '10px',
  },
  optionText: {
    fontSize: '15px',
    fontWeight: '500',
    flex: 1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    borderRadius: '12px',
    boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.12)',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 10,
  },
  reportButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    backgroundColor: 'white',
    color: '#ff0000',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  reportIcon: {
    marginRight: '5px',
  },
  submitButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 25px',
    backgroundColor: '#070519',
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  discussButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    color: 'black',
    border: '1px solid black',
    backgroundColor: 'white',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
  },
  discussIcon: {
    marginRight: '5px',
  },
  noDataContainer: {
    textAlign: 'center',
    padding: '20px',
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noDataImage: {
    marginBottom: '20px',
  },
  noDataText: {
    fontWeight: 600,
    marginBottom: '10px',
  },
  noDataSubtext: {
    color: '#888',
  },
};
