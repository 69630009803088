import React, { useState } from 'react';
import { PracticeCustom, HelpCircle } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import CustomPracticeInfoTips from './Infotip'; // You'll need to create this component

export default function CustomPracticeFirstTime() {
  const navigate = useNavigate();
  const [showInfoTips, setShowInfoTips] = useState(false);

  const handleInfoTip = () => {
    setShowInfoTips(true);
  };

  const closeModal = () => {
    setShowInfoTips(false);
  };

  return (
    <div className="flex items-center justify-center h-[60vh] sm:h-[70vh] bg-background">
      <div className="flex flex-col items-center justify-center max-w-md w-full px-4 py-4">
        <div className="flex flex-col text-center mt-4">
          <h2 className={`m-0 p-0 flex font-bold justify-center text-text text-3xl items-center`}>
            Custom Topic
            <img 
              src={HelpCircle} 
              alt="Help icon" 
              className="ml-2 w-5 h-5 cursor-pointer" 
              onClick={handleInfoTip} 
            />
          </h2>
          <p className={`text-text m-[5px]`}>
            Level up your UPSC preparation with customized practice
          </p>
        </div>
        <div className="rounded-2xl w-full h-[224px] mt-4">
          <img 
            src={PracticeCustom} 
            alt="Custom Topic" 
            className="w-full h-full object-cover rounded-2xl mb-[10px]" 
          />
          <button 
            className={`px-4 py-5 rounded-[24px] text-background border-none w-full bg-text`} 
            onClick={() => navigate('/customize-practice')}
          >
            Start Now
          </button>
        </div>
        <CustomPracticeInfoTips 
          opened={showInfoTips} 
          onClose={closeModal} 
        />
      </div>
    </div>
  );
}