import React, { useState, useRef, useEffect } from 'react';
import { ArrowDown, AttachedIcon, CloseIcon } from '../../assets/images';
import CompletionModal from './CompletionModal';
import { useTheme } from '../../context/themeProvider';

const FeedbackForm = ({ isVisible, onClose, userData }) => {
  const [feedbackCategory, setFeedbackCategory] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const { colorScheme, colors } = useTheme();

  const dropdownRef = useRef(null);
  const modalRef = useRef(null);
  const isMobile = window.innerWidth <= 2500;
  const token = userData.user;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (feedbackCategory) {
      const data = new FormData();
      data.append('profile_id', userData.user);
      data.append('experience', feedbackText);
      data.append('category', feedbackCategory);

      if (selectedFile) {
        data.append('screenshot', {
          uri: selectedFile.uri,
          type: selectedFile.type || 'image/jpeg',
          name: selectedFile.name || 'screenshot.jpg',
        });
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/service-feedback/`, {
          method: 'POST',
          body: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const responseText = await response.text();
        try {
          const result = JSON.parse(responseText);
          setShowCompletionModal(true);
        } catch (jsonError) {
          console.error('Failed to parse JSON:', jsonError);
        }
      } catch (error) {
        console.error('Error:', error);
      }

      // Clear form after submission
      setFeedbackCategory('');
      setFeedbackText('');
      setSelectedFile(null);
      setIsButtonActive(false);
    }
  };

  const handleGoBack = () => {
    setShowCompletionModal(false);
    onClose();
  };

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const selectCategory = (category) => {
    setFeedbackCategory(category);
    setIsDropdownOpen(false);
    setIsButtonActive(true);
  };

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const truncateFileName = (fileName, maxLength = 20) => {
    if (fileName.length > maxLength) {
      return fileName.slice(0, maxLength - 3) + '...';
    }
    return fileName;
  };

  const clearFile = () => {
    setSelectedFile(null);
    document.getElementById('fileInput').value = '';
  };

  const isSubmitButtonDisabled = !feedbackCategory;

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end z-[1000]">
      <div
        ref={modalRef}
        className={`${colorScheme === 'dark' ? 'bg-cardBackground' : 'bg-white'
          } shadow-lg rounded-t-xl p-5 w-full text-center ${isMobile ? 'max-w-[28rem]' : 'max-w-[22vw]'
          }`}
      >
        <div className="flex justify-center items-center w-full">
          <span
            className={`h-[6px] w-[89px] ${colorScheme === 'dark' ? 'bg-[#464646]' : 'bg-[#E8E8E8]'
              } rounded-full cursor-pointer`}
            onClick={onClose}
          ></span>
        </div>
        <h2 className={`text-xl font-semibold mb-5 ${colorScheme === 'dark' ? 'text-text' : ''
          }`}>
          Help Us Improve Padhai
        </h2>

        {/* Feedback Category Dropdown */}
        <div className="relative mb-4" ref={dropdownRef}>
          <div
            className={`flex justify-between items-center p-2  rounded-md cursor-pointer h-14 ${colorScheme === 'dark'
                ? 'bg-background text-text'
                : 'bg-white'
              }`}
            onClick={toggleDropdown}
          >
            <span>{feedbackCategory || 'What is your feedback about?'}</span>
            <span className={`flex justify-center items-center w-14 h-14 rounded-[0.3em] ${colorScheme === 'dark' ? 'bg-[#57575B]' : 'bg-gray-200'
              } -mr-2`}>
              {colorScheme === 'dark' ? (
                <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 5.99976L0 -0.000244141H12L6 5.99976Z" fill="white" />
                </svg>
              ) : (
                <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 5.99976L0 -0.000244141H12L6 5.99976Z" fill="black" />
                </svg>
              )}
            </span>
          </div>
          {isDropdownOpen && (
            <ul className={`absolute top-full left-0 w-full border rounded-md shadow-lg max-h-[150px] overflow-y-auto z-[1000] ${colorScheme === 'dark'
                ? 'border-borderColor bg-cardBackground text-text'
                : 'border-gray-300 bg-white'
              }`}>
              <li
                className={`p-2 cursor-pointer ${colorScheme === 'dark' ? 'hover:bg-background' : 'hover:bg-gray-100'
                  }`}
                onClick={() => selectCategory('')}
              >
                What is your feedback about?
              </li>
              <li
                className={`p-2 cursor-pointer ${colorScheme === 'dark' ? 'hover:bg-background' : 'hover:bg-gray-100'
                  }`}
                onClick={() => selectCategory('general_app_improvements')}
              >
                General app improvements
              </li>
              <li
                className={`p-2 cursor-pointer ${colorScheme === 'dark' ? 'hover:bg-background' : 'hover:bg-gray-100'
                  }`}
                onClick={() => selectCategory('bugs_and_fixes')}
              >
                Bugs and fixes
              </li>
              <li
                className={`p-2 cursor-pointer ${colorScheme === 'dark' ? 'hover:bg-background' : 'hover:bg-gray-100'
                  }`}
                onClick={() => selectCategory('new_feature_requests')}
              >
                New feature requests
              </li>
              <li
                className={`p-2 cursor-pointer ${colorScheme === 'dark' ? 'hover:bg-background' : 'hover:bg-gray-100'
                  }`}
                onClick={() => selectCategory('other')}
              >
                Other
              </li>
            </ul>
          )}
        </div>

        {/* Feedback Textarea */}
        <div className="mb-4 text-left">
          <label className={`block mb-1 font-medium text-sm ${colorScheme === 'dark' ? 'text-text' : ''
            }`}>
            Tell Us More (Optional)
          </label>
          <textarea
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            placeholder="How can we improve your experience?"
            className={`w-full p-2 rounded-md resize-none h-[250px] text-sm ${colorScheme === 'dark'
                ? ' bg-background text-text placeholder-subtleText'
                : ' bg-white placeholder-gray-400'
              }`}
          />
        </div>

        {/* Attach Screenshot */}
        <div className={`border rounded-lg p-2 flex justify-center items-center mb-4 cursor-pointer ${colorScheme === 'dark'
            ? 'border-borderColor bg-background'
            : 'border-gray-300'
          }`}>
          <input type="file" onChange={handleFileChange} id="fileInput" className="hidden" />
          <label htmlFor="fileInput" className="flex items-center cursor-pointer">
            <span className={`text-sm mr-2 ${colorScheme === 'dark' ? 'text-text' : 'text-black'
              }`}>
              {selectedFile ? truncateFileName(selectedFile.name) : 'Attach a Screenshot'}
            </span>
            <img
              src={selectedFile ? CloseIcon : AttachedIcon}
              alt={selectedFile ? 'Clear' : 'Attach'}
              className="w-5 h-5"
              onClick={selectedFile ? clearFile : null}
            />
          </label>
        </div>

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          disabled={isSubmitButtonDisabled}
          className={`w-full py-2 rounded-md text-lg font-medium ${isSubmitButtonDisabled
              ? colorScheme === 'dark'
                ? 'bg-cardBackground text-subtleText border-borderColor cursor-not-allowed'
                : 'bg-gray-200 text-gray-400 border border-gray-300 cursor-not-allowed'
              : colorScheme === 'dark'
                ? 'bg-text text-background'
                : 'bg-black text-white'
            }`}
        >
          Submit Feedback
        </button>
        {showCompletionModal && (
          <CompletionModal
            onGoBack={() => {
              setShowCompletionModal(false);
              onClose();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FeedbackForm;
