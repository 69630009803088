import React, { useState, useEffect } from 'react';
import { AddFriendLink, ShareBlueIcon } from '../../../assets/images';
import { userStore } from '../../../stores/userStore';
import branch from 'branch-sdk';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';
import { useTheme } from '../../../context/themeProvider';

const AddFriendModal = ({ onClose, onShowUserIdInfo }) => {
    const [friendUserId, setFriendUserId] = useState('');
    const [addFriendLoading, setAddFriendLoading] = useState(false);
    const [addFriendError, setAddFriendError] = useState(false);
    const [addFriendErrorMessage, setAddFriendErrorMessage] = useState('');
    const [deeplinkUrl, setDeeplinkUrl] = useState('');
    const [isGeneratingLink, setIsGeneratingLink] = useState(false);
    const userData = userStore((state) => state.userData);
    const { colorScheme } = useTheme();

    useEffect(() => {
        generateDeepLink();
    }, [userData]);

    const generateDeepLink = async () => {
        if (!userData?.user) return;
        
        setIsGeneratingLink(true);
        const linkData = {
            channel: 'web',
            feature: 'friend_invite',
            data: {
                $desktop_url: window.location.origin,
                $ios_url: window.location.origin,
                $android_url: window.location.origin,
                $og_title: `${userData.username || userData.name} is inviting you to be a friend and do UPSC PadhAI together`,
                $og_description: 'Use this link to invite your friend for a Duel.',
                $og_image: 'https://d1mwozwejtazmv.cloudfront.net/profile/IMG_0854.png',
                $og_image_width: 1200,
                $og_image_height: 630,
                navigate_to: '/duel-friends',
                inviter_id: userData.user,
                inviter_name: userData.username || userData.name,
            },
        };
    
        branch.link(linkData, (err, link) => {
            setIsGeneratingLink(false);
            if (err) {
                console.error('Error creating branch link:', err);
                return;
            }
            setDeeplinkUrl(link);
        });
    };

    const handleShareInvite = async () => {
        if (isGeneratingLink || !deeplinkUrl) {
            alert('Please wait while generating the invite link...');
            return;
        }
    
        try {
            if (navigator.share) {
                await navigator.share({
                    title: `${userData.name || userData.username} is inviting you to be a friend and do UPSC PadhAI together`,
                    text: 'Use this link to invite your friend for a Duel.',
                    url: deeplinkUrl
                });
            } else {
                await navigator.clipboard.writeText(deeplinkUrl);
                alert('Invite link copied to clipboard!');
            }
            sendPostHogEvent('add_friend_initiated', { source: 'link' });
            MixpanelEvent('add_friend_initiated', { source: 'link' });
        } catch (error) {
            console.error('Error sharing:', error);
            try {
                await navigator.clipboard.writeText(deeplinkUrl);
                alert('Invite link copied to clipboard!');
            } catch (clipboardError) {
                console.error('Error copying to clipboard:', clipboardError);
                alert('Failed to share or copy link. Please try again.');
            }
        }
    };

    const addFriendByUserId = async () => {
        sendPostHogEvent('add_friend_initiated', { source: 'userid' });
        MixpanelEvent('add_friend_initiated', { source: 'userid' });
        if (!friendUserId) {
            setAddFriendError(true);
            setAddFriendErrorMessage('Please enter a friend username');
            return;
        }

        setAddFriendLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/add-friend/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    friend_username: friendUserId,
                    user_id: userData.user,
                    category: "history",
                    from_web:true
                }),
            });

            const data = await response.json();
            if (response.ok && data.status) {
                setFriendUserId('');
                setAddFriendLoading(false);
                setAddFriendError(false);
                setAddFriendErrorMessage('');
                onClose();
            } else {
                setAddFriendLoading(false);
                setAddFriendError(true);
                setAddFriendErrorMessage(data.error || 'Failed to add friend');
            }
        } catch (error) {
            console.error('Add friend error:', error);
            setAddFriendLoading(false);
            setAddFriendError(true);
            setAddFriendErrorMessage('Failed to add friend. Please try again.');
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-cardBackground rounded-2xl w-[90%] max-w-[400px] p-6">
                <h2 className="text-xl font-bold text-center mb-6">Add New Friend</h2>

                <div className="flex items-center gap-4 border-2 border-border p-2 rounded-lg mb-4">
                    <img src={AddFriendLink} alt='add friend link' />
                    <div className="flex-1">
                        <h3 className="font-medium">Add friend via Link</h3>
                        <p className="text-sm text-text">Use the link to add your friend to play a duel</p>
                    </div>
                    <button 
                        className={`p-2 ${isGeneratingLink ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={handleShareInvite}
                        disabled={isGeneratingLink}
                    >
                        <img src={ShareBlueIcon} alt='share' />
                    </button>
                </div>

                <div className="flex items-center text-center my-4">
                    <hr className="flex-grow border-t border-border" />
                    <span className="mx-4 text-[12px] font-bold text-gray-500">OR</span>
                    <hr className="flex-grow border-t border-border" />
                </div>

                <div className="mb-6">
                    <h3 className="mb-2 text-[12px] font-bold text-text">Add Friends by their User ID</h3>
                    <div className="flex gap-2">
                        <input
                            type="text"
                            value={friendUserId}
                            onChange={(e) => setFriendUserId(e.target.value)}
                            placeholder="Enter friend's username"
                            className={`flex-1 p-3 border text-black rounded-lg ${addFriendError ? 'border-red-500' : 'border-gray-200'}`}
                            disabled={addFriendLoading}
                        />
                        <button
                            className={`p-3 rounded-lg ${addFriendLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            onClick={addFriendByUserId}
                            disabled={addFriendLoading}
                        >
                            {addFriendLoading ? (
                                <div className="animate-spin">...</div>
                            ) : (
                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.0693 5.74146L6.50929 1.46146C0.759287 -1.41854 -1.60071 0.941458 1.27929 6.69146L2.14929 8.43146C2.39929 8.94146 2.39929 9.53146 2.14929 10.0415L1.27929 11.7715C-1.60071 17.5215 0.749287 19.8815 6.50929 17.0015L15.0693 12.7215C18.9093 10.8015 18.9093 7.66146 15.0693 5.74146ZM11.8393 9.98146H6.43929C6.02929 9.98146 5.68929 9.64146 5.68929 9.23146C5.68929 8.82146 6.02929 8.48146 6.43929 8.48146H11.8393C12.2493 8.48146 12.5893 8.82146 12.5893 9.23146C12.5893 9.64146 12.2493 9.98146 11.8393 9.98146Z" fill="#6374FA" />
                                </svg>
                            )}
                        </button>
                    </div>
                    {addFriendError && <div className="text-red-500 mt-2">{addFriendErrorMessage}</div>}
                </div>

                <button
                    onClick={onShowUserIdInfo}
                    className="w-full py-3 px-4 rounded-[10px] mb-6 text-left flex items-center justify-between relative overflow-hidden group transition-colors"
                    style={{
                        backgroundColor: colorScheme === 'dark' ? '#61BD853D' : '#E7FAF0',
                    }}
                    >
                    <div className="absolute left-0 top-0 bottom-0 w-1 bg-gradient-to-b from-[#00BA88] to-[#00BA88]/50"></div>
                    <span className="font-medium text-sm pl-3">WHERE TO FIND FRIEND'S USERNAME?</span>
                    <div className="w-6 h-6 rounded-full bg-[#00BA88] flex items-center justify-center">
                        <span className="text-white text-sm font-bold">!</span>
                    </div>
                </button>

                <button
                    onClick={onClose}
                    className="w-full py-3 border border-border rounded-full text-text"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default AddFriendModal;