import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, Image, Text, Group, Box, Avatar, Notification, Modal, Button, Flex } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import {
  Star,
  CopyIcon,
  EraserIcon,
  HighlighterIcon,
  Like2,
  Bookmark,
  Share,
  Download,
  LikeActive,
  BookmarkActive,
  TheHindu,
  NewsDetailsCloseIcon,
} from '../../assets/images';
import { NEWS_SOURCE } from '../../constants/newsData';
import { callGETAPI, callPOSTAPI } from '../../utils/NetworkUtil';
import { userStore } from '../../stores/userStore';
import { useTheme } from '../../context/themeProvider';
import { useNews } from '../../context/newsProvide';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import branch from 'branch-sdk';
import { useDisclosure } from '@mantine/hooks';
import { CopyButton } from '@mantine/core';
import { IconCopy, IconCheck } from '@tabler/icons-react';
import InfoHighlight from './infoHighlights'; // Import the modal
import ChatCard from '../Chat/TutorChat/ChatCard';

export default function NewsDetailPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { colors, colorScheme } = useTheme();
  const { setNewsDetailData } = useNews();
  const [newsItem, setNewsItem] = useState(null);
  const [highlightedPoints, setHighlightedPoints] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [bookmarked, setBookmarked] = useState(false);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [imageModalOpened, setImageModalOpened] = useState(false);
  const [showHighlightModal, setShowHighlightModal] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const isMobile = window.innerWidth <= 2500;
  const isResponsiveMobile =
    window.innerWidth > 1200
      ? '28em'
      : window.innerHeight <= 700 && window.innerWidth <= 400
      ? '23em'
      : window.innerWidth <= 400 && window.innerWidth <= 900
      ? '24em'
      : '26.5em';

  const [selection, setSelection] = useState({
    selectedText: '',
    startIndex: 0,
    endIndex: 0,
    summaryId: null,
  });
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const { newsType, currentIndex, totalArticles, selectedDate, scrollPosition, readingTime, streakCount } = location.state || {
    newsType: NEWS_SOURCE.THE_HINDU,
    currentIndex: 1,
    totalArticles: 1,
    selectedDate: new Date(),
    scrollPosition: 0,
    readingTime: null,
  };

  const userData = userStore((state) => state.userData);

  const [link, setLink] = useState(null);
  const [error, setError] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [chats, setChats] = useState([]);

  const timeoutRef = useRef(null);
  const touchStartRef = useRef({ x: 0, y: 0 });

  useEffect(() => {
    callGETAPI(`${process.env.REACT_APP_BASE_URL}/api/tutorhistory/?user_id=${userData.user}&source=news_feed&assoc_news=${id}`, (resp) => {
      setChats(resp.data.data);
      console.log('chats', resp.data.data);
    });
  }, [id, userData.user]);

  const handleDownloadPdf = (chatId) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/download-pdf/?chat_id=${chatId}&chat_type=history`;
    window.open(url, '_blank');
  };

  const handleChatSelect = (chat) => {
    if (chat.id) {
      navigate(`/chat/${chat.id}`, { state: { chat, chatTypeFlow: chat.source } });
    } else {
      navigate(`/chat/new/`, { state: { userId: userData.user, chatTypeFlow: 'ai_tutor' } });
    }
  };

  useEffect(() => {
    const { newsItem: stateNewsItem } = location.state || {};
    const Newsid = stateNewsItem?.id || id; // Use state newsId or URL newsId

    if (Newsid) {
      callGETAPI(`${process.env.REACT_APP_BASE_URL}/api/news-summary/${id}/?user=${userData.user}`, (resp) => {
        const updatedNewsItem = {
          ...resp.data,
          user_liked: resp.data.user_liked || stateNewsItem?.user_liked,
          likes: resp.data.likes || stateNewsItem?.likes || 0,
          user_bookmarked: resp.data.user_bookmarked || stateNewsItem?.user_bookmarked,
          bookmarks: resp.data.bookmarks || stateNewsItem?.bookmarks || 0,
        };

        setNewsItem(updatedNewsItem);
        setNewsDetailData(updatedNewsItem);

        const highlights = stateNewsItem?.highlights || resp.data.highlights || [];
        console.log(highlights, 'hgh');
        setHighlightedPoints(
          highlights.map((h) => ({
            id: h.id,
            highlighted_content_text: h.highlighted_content_text || h.text,
          }))
        );

        setLiked(updatedNewsItem.user_liked);
        setLikeCount(updatedNewsItem.likes);
        setBookmarked(updatedNewsItem.user_bookmarked);
        setBookmarkCount(updatedNewsItem.bookmarks);
      });
    } else {
      navigate('/news');
    }
  }, [location.state, id, userData.user, navigate]);

  useEffect(() => {
    const infoHighLightModal = localStorage.getItem('infoHighLightModal');
    if (!infoHighLightModal) {
      setShowHighlightModal(true);
    }
  }, []);

  useEffect(() => {
    const checkReadStatus = async () => {
      try {
        await callPOSTAPI(`${process.env.REACT_APP_BASE_URL}/api/update-summary-read/`, {
          article: id,
          user: userData.user,
          is_read: true,
        });
        setIsRead(true);
      } catch (error) {
        console.error('Failed to mark as read:', error);
      }
    };

    checkReadStatus();
  }, [id, userData]);

  const closeModal = () => {
    setShowHighlightModal(false);
    localStorage.setItem('infoHighLightModal', 'true');
  };
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  const handlePYQClick = () => {
    MixpanelEvent('pyq_clicked', {
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      streak: streakCount,
      news_id: newsItem.id,
      tab: 'Latest',
      dark_mode: colorScheme === 'dark',
      topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
    });
    sendPostHogEvent('pyq_clicked', {
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      news_id: newsItem.id,
      tab: 'Latest',
    });
    if (newsItem?.id) {
      navigate(`/news/pyq/${newsItem.id}`);
    }
  };

  const handleMCQClick = () => {
    MixpanelEvent('mcq_clicked', {
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      streak: streakCount,
      news_id: newsItem.id,
      tab: 'Latest',
      dark_mode: colorScheme === 'dark',
      topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
    });
    sendPostHogEvent('mcq_clicked', {
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      news_id: newsItem.id,
      tab: 'Latest',
    });
    if (newsItem?.id) {
      navigate(`/news/mcq/${newsItem.id}`);
    }
  };

  const handleBack = () => {
    navigate('/news', { state: { scrollPosition, selectedDate, readingTime } });
    MixpanelEvent('navigation_back', { source_screen: 'news_detail' });
    sendPostHogEvent('navigation_back', { source_screen: 'news_detail' });
  };
  const handleTouchStart = (e) => {
    touchStartRef.current = {
      x: e.touches[0].clientX,
      y: e.touches[0].clientY,
      time: Date.now(),
    };
  };

  const handleTouchEnd = (e) => {
    setTimeout(() => {
      const selection = window.getSelection();
      const selectedText = selection.toString().trim();

      if (selectedText) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        setSelection({
          selectedText,
          startIndex: range.startOffset,
          endIndex: range.endOffset,
          summaryId: findAncestor(range.startContainer, 'li')?.getAttribute('data-id') || null,
        });

        setMenuPosition({
          x: rect.left + rect.width / 2 - 100,
          y: rect.top - 60,
        });

        setMenuVisible(true);
      }
    }, 0);
  };
  const handleTextSelection = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();

    if (selectedText) {
      const startLi = findAncestor(selection.anchorNode, 'li');
      const endLi = findAncestor(selection.focusNode, 'li');
      if (startLi !== endLi) {
        selection.removeAllRanges();
        setNotificationMessage('Please select text from a single point only');
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
        return;
      }

      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      setSelection({
        selectedText,
        startIndex: range.startOffset,
        endIndex: range.endOffset,
        summaryId: findAncestor(range.startContainer, 'li')?.getAttribute('data-id') || null,
      });

      const menuX = rect.left + rect.width / 2 - 100;
      const menuY = rect.top - 60 + window.scrollY;

      setMenuPosition({
        x: menuX,
        y: menuY,
      });

      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    const selectionChangeHandler = () => {
      handleTextSelection();
    };

    document.addEventListener('selectionchange', selectionChangeHandler);

    return () => {
      document.removeEventListener('selectionchange', selectionChangeHandler);
    };
  }, []);

  const handleSelectionChange = useCallback(() => {
    setTimeout(() => {
      const selection = window.getSelection();
      const selectedText = selection.toString().trim();

      if (selectedText) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        setSelection({
          selectedText,
          startIndex: range.startOffset,
          endIndex: range.endOffset,
          summaryId: findAncestor(range.startContainer, 'li')?.getAttribute('data-id') || null,
        });

        setMenuPosition({
          x: rect.left + rect.width / 2 - 100,
          y: rect.top - 60,
        });

        setMenuVisible(true);
      } else {
        setMenuVisible(false);
      }
    }, 0);
  }, []);

  const handleHighlight = () => {
    MixpanelEvent('feature_selection', {
      feature_type: 'highlights',
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      streak: streakCount,
      news_id: newsItem.id,
      tab: 'Latest',
      dark_mode: colorScheme === 'dark',
      topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
    });
    sendPostHogEvent('feature_selection', {
      feature_type: 'highlights',
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      news_id: newsItem.id,
      tab: 'Latest',
    });
    if (newsItem && selection.selectedText) {
      const payload = {
        article: newsItem.id,
        user: userData.user_id,
        point_id: selection.summaryId,
        content_start_index: selection.startIndex,
        content_end_index: selection.endIndex,
        highlighted_content_text: selection.selectedText,
      };

      callPOSTAPI(`${process.env.REACT_APP_BASE_URL}/api/highlight/`, payload, (response) => {
        const newHighlight = {
          id: response.id,
          highlighted_content_text: selection.selectedText,
        };
        setNotificationMessage('Highlight created successfully!');
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
        setHighlightedPoints((prevHighlights) => [...prevHighlights, newHighlight]);

        setSelection({
          selectedText: '',
          startIndex: 0,
          endIndex: 0,
          summaryId: null,
        });
        setMenuVisible(false);
        window.getSelection().removeAllRanges();
      });
    }
  };

  const findAncestor = (node, tagName) => {
    while (node) {
      if (node.nodeType === Node.ELEMENT_NODE && node.tagName.toLowerCase() === tagName.toLowerCase()) {
        return node;
      }
      node = node.parentNode;
    }
    return null;
  };

  const handleDeleteHighlight = useCallback(async (highlightId) => {
    if (highlightId) {
      const url = `${process.env.REACT_APP_BASE_URL}/api/highlight/delete/${highlightId}/`;
      try {
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setNotificationMessage('Highlight deleted successfully!');
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
        setHighlightedPoints((prevHighlightedPoints) => prevHighlightedPoints.filter((highlight) => highlight.id !== highlightId));
      } catch (error) {
        console.error('Error deleting highlight:', error);
      }
    } else {
      console.log('No highlight ID provided for deletion');
    }
  }, []);

  const handleCopyHighlight = useCallback((text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setNotificationMessage('Your highlighted text has been copied successfully!');
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  }, []);

  const HighlightedText = ({ text, highlightId }) => (
    <span className="highlighted">
      {Array.isArray(text) ? text : <span>{text}</span>}
      {highlightId && (
        <div className="highlight-actions">
          <button className="delete-highlight-btn" onClick={() => handleDeleteHighlight(highlightId)}>
            <img src={EraserIcon} alt="delete" /> Clear Highlight
          </button>
          <button
            className="copy-highlight-btn"
            onClick={() => {
              const plainText = Array.isArray(text)
                ? text
                    .map((element) => {
                      if (React.isValidElement(element)) {
                        return element.props.children;
                      }
                      return element;
                    })
                    .join('')
                : text;
              handleCopyHighlight(plainText);
            }}
          >
            <img src={CopyIcon} alt="Copy" />
          </button>
          <div className="action-polygon"></div>
        </div>
      )}
    </span>
  );

  const renderHighlightedContent = (content) => {
    let lastIndex = 0;
    const elements = [];

    const removeMarkdown = (text) => text.replace(/\*\*(.*?)\*\*/g, '$1');
    const cleanContent = removeMarkdown(content);

    const markdownBoldSegments = findBoldPatterns(content);
    const summaryPoint = newsItem.gpt_output.summary.find((point) => point.point === content);
    const apiStylingRanges = summaryPoint?.styling || [];

    const apiBoldSegments = apiStylingRanges.map(([tag, start, end]) => ({
      start,
      end,
      isBold: true,
    }));

    const allBoldSegments = [...markdownBoldSegments, ...apiBoldSegments].sort((a, b) => a.start - b.start);

    const sortedHighlights = [...highlightedPoints].sort((a, b) => {
      return cleanContent.indexOf(a.highlighted_content_text) - cleanContent.indexOf(b.highlighted_content_text);
    });

    sortedHighlights.forEach((highlight) => {
      const startIndex = cleanContent.indexOf(highlight.highlighted_content_text, lastIndex);

      if (startIndex !== -1) {
        if (startIndex > lastIndex) {
          const beforeText = content.slice(lastIndex, startIndex);
          const boldifiedBeforeText = applyBoldStyling(beforeText, allBoldSegments, lastIndex);
          elements.push(...boldifiedBeforeText);
        }

        const highlightLength = highlight.highlighted_content_text.length;
        const highlightedContent = content.slice(startIndex, startIndex + highlightLength);
        const boldifiedHighlightedContent = applyBoldStyling(highlightedContent, allBoldSegments, startIndex);

        elements.push(<HighlightedText key={`highlight-${highlight.id}`} text={boldifiedHighlightedContent} highlightId={highlight.id} />);

        lastIndex = startIndex + highlightLength;
      }
    });

    if (lastIndex < content.length) {
      const remainingText = content.slice(lastIndex);
      const boldifiedRemainingText = applyBoldStyling(remainingText, allBoldSegments, lastIndex);
      elements.push(...boldifiedRemainingText);
    }

    return elements;
  };
  const findBoldPatterns = (text) => {
    const boldPattern = /\*\*(.*?)\*\*/g;
    const boldSegments = [];
    let match;

    while ((match = boldPattern.exec(text)) !== null) {
      boldSegments.push({
        start: match.index,
        end: match.index + match[0].length,
        text: match[1],
        isMarkdown: true,
      });
    }
    return boldSegments;
  };

  const applyBoldStyling = (text, boldSegments, offset) => {
    if (!text) return [];

    const elements = [];
    let currentIndex = 0;
    const sortedSegments = [...boldSegments].sort((a, b) => a.start - offset - (b.start - offset));

    for (let i = 0; i < sortedSegments.length; i++) {
      const segment = sortedSegments[i];
      const segmentStart = segment.start - offset;
      const segmentEnd = segment.end - offset;

      if (segmentEnd <= 0 || segmentStart >= text.length) continue;
      if (segmentStart > currentIndex) {
        elements.push(<span key={`text-${currentIndex}-${segmentStart}`}>{text.slice(currentIndex, segmentStart)}</span>);
      }

      // Add bold text
      const actualStart = Math.max(0, segmentStart);
      const actualEnd = Math.min(text.length, segmentEnd);
      const boldContent = segment.isMarkdown ? segment.text : text.slice(actualStart, actualEnd);

      if (boldContent) {
        elements.push(<strong key={`bold-${actualStart}-${actualEnd}`}>{boldContent}</strong>);
      }

      currentIndex = actualEnd;
    }

    // Add remaining non-bold text
    if (currentIndex < text.length) {
      elements.push(<span key={`text-${currentIndex}-end`}>{text.slice(currentIndex)}</span>);
    }

    return elements;
  };

  if (!newsItem) {
    return <div>Loading...</div>;
  }

  const createDeepLink = () => {
    MixpanelEvent('share_clicked', {
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      streak: streakCount,
      news_id: newsItem.id,
      tab: 'Latest',
      dark_mode: colorScheme === 'dark',
      topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
    });
    sendPostHogEvent('share_clicked', {
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      news_id: newsItem.id,
      tab: 'Latest',
    });
    const domain = window.location.origin;

    const linkData = {
      channel: 'web',
      feature: 'sharing',
      data: {
        $desktop_url: domain,
        '$og_redirect	': domain,
        $fallback_url: domain,
        $og_title: 'Share News',
        $og_description: newsItem.title || 'A basic example of the Branch Web SDK.',
        navigate_to: 'news/id',
        data: newsItem,
        newsType: newsType || NEWS_SOURCE.THE_HINDU,
      },
    };

    branch.link(linkData, (err, link) => {
      if (err) {
        console.error('Error creating link:', err);
        setError('Failed to create link');
        open();
      } else {
        setLink(link);
        open();
      }
    });
  };

  const DownloadSummary = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/summary-pdf/${newsItem.id}`;
    window.open(url, '_blank');
    setTimeout(() => {
      MixpanelEvent('download_clicked', {
        newspaper_name: newsType,
        date: selectedDate?.split('-').reverse().join('') || new Date(),
        reading_time: readingTime || null,
        streak: streakCount,
        news_id: newsItem.id,
        tab: 'Latest',
        dark_mode: colorScheme === 'dark',
        topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
      });
      sendPostHogEvent('download_clicked', {
        newspaper_name: newsType,
        date: selectedDate?.split('-').reverse().join('') || new Date(),
        reading_time: readingTime || null,
        news_id: newsItem.id,
        tab: 'Latest',
      });
    }, 1000);
  };

  const handleLike = async () => {
    if (!newsItem) {
      console.error('handleLike: newsItem is null or undefined');
      return;
    }

    const payload = {
      summary: newsItem.id,
      user: userData.user_id,
    };

    try {
      const url = newsItem.user_liked ? `${process.env.REACT_APP_BASE_URL}/api/unlike/` : `${process.env.REACT_APP_BASE_URL}/api/like/`;
      const newLiked = !newsItem.user_liked;
      if (newLiked) {
        MixpanelEvent('feature_selection', {
          feature_type: 'like',
          newspaper_name: newsType,
          date: selectedDate?.split('-').reverse().join('') || new Date(),
          reading_time: readingTime || null,
          streak: streakCount,
          news_id: newsItem.id,
          tab: 'Latest',
          dark_mode: colorScheme === 'dark',
          topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
        });
        sendPostHogEvent('feature_selection', {
          feature_type: 'like',
          newspaper_name: newsType,
          date: selectedDate?.split('-').reverse().join('') || new Date(),
          reading_time: readingTime || null,
          news_id: newsItem.id,
          tab: 'Latest',
        });
      }

      setLiked(newLiked);
      setLikeCount((prevCount) => (newLiked ? prevCount + 1 : prevCount - 1));

      const response = await fetch(url, {
        method: newsItem.user_liked ? 'DELETE' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to like/unlike article');
      }

      setNotificationMessage(newLiked ? 'Article liked successfully!' : 'Article unliked successfully!');
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);

      callGETAPI(`${process.env.REACT_APP_BASE_URL}/api/news-summary/${newsItem.id}/?user=${userData.user}`, (resp) => {
        setNewsItem(resp.data);
        setNewsDetailData(resp.data);
      });
    } catch (error) {
      console.error('Error handling like/unlike:', error);
      setNotificationMessage('Error handling like/unlike action.');
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    }
  };

  const handleBookmark = async () => {
    if (!newsItem) {
      console.error('handleBookmark: newsItem is null or undefined');
      return;
    }

    const payload = {
      summary: newsItem.id,
      user: userData.user_id,
    };

    try {
      const url = newsItem.user_bookmarked
        ? `${process.env.REACT_APP_BASE_URL}/api/remove-bookmark/`
        : `${process.env.REACT_APP_BASE_URL}/api/bookmark/`;

      const newBookmarked = !newsItem.user_bookmarked;
      setBookmarked(newBookmarked);
      setBookmarkCount((prevCount) => (newBookmarked ? prevCount + 1 : prevCount - 1));

      const response = await fetch(url, {
        method: newsItem.user_bookmarked ? 'DELETE' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to bookmark/remove-bookmark article');
      }
      if (newBookmarked) {
        MixpanelEvent('feature_selection', {
          feature_type: 'bookmark',
          newspaper_name: newsType,
          date: selectedDate?.split('-').reverse().join('') || new Date(),
          reading_time: readingTime || null,
          streak: streakCount,
          news_id: newsItem.id,
          tab: 'Latest',
          dark_mode: colorScheme === 'dark',
          topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
        });
        sendPostHogEvent('feature_selection', {
          feature_type: 'bookmark',
          newspaper_name: newsType,
          date: selectedDate?.split('-').reverse().join('') || new Date(),
          reading_time: readingTime || null,
          news_id: newsItem.id,
          tab: 'Latest',
        });
      }
      setNotificationMessage(newBookmarked ? 'Article bookmarked successfully!' : 'Bookmark removed successfully!');
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);

      callGETAPI(`${process.env.REACT_APP_BASE_URL}/api/news-summary/${newsItem.id}/?user=${userData.user}`, (resp) => {
        setNewsItem(resp.data);
        setNewsDetailData(resp.data);
      });
    } catch (error) {
      console.error('Error handling bookmark/remove-bookmark:', error);
      // Set notification for error
      setNotificationMessage('Error handling bookmark action.');
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    }
  };

  const handleReadStatusToggle = async () => {
    try {
      await callPOSTAPI(`${process.env.REACT_APP_BASE_URL}/api/update-summary-read/`, {
        article: id,
        user: userData.user,
        is_read: !isRead,
      });

      setIsRead(!isRead);

      setNotificationMessage(`Article marked as ${!isRead ? 'read' : 'unread'}`);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    } catch (error) {
      console.error('Failed to update read status:', error);
    }
  };

  const closeImageModal = () => {
    setImageModalOpened(false);
    document.body.style.overflow = '';
    document.body.style.marginTop = '';
  };
  const height =
    window.innerWidth <= 400 && window.innerHeight <= 700
      ? '80dvh'
      : window.innerWidth <= 400 && window.innerHeight > 700
      ? '79dvh'
      : window.innerWidth > 400 && window.innerHeight > 700 && window.innerWidth <= 1500
      ? '84dvh'
      : '85dvh';
  return (
    <>
       <style>
        {`
    .highlighted {
            background-color: #E1EBFF;
            position: relative;
          }
         .highlighted strong {
            font-weight: bold !important;
            margin-left: 0.9px;
        }
          .highlight-actions {
            position: absolute;
            bottom: 100%;
            left: 0;
            background-color: white;
            display: flex;
            align-items: center;
            border-radius: 8px;
            box-shadow: 0px -8px 24px 0px rgba(0, 0, 0, 0.33);
            width: 200px;
            justify-content: space-between;
            padding: 5px 10px;
            font-size: 14px;
            z-index: 1000;
            transform: translateY(-10px);
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.3s;
          }
          .highlighted:hover .highlight-actions {
            opacity: 1;
            visibility: visible;
          }
          .copy-highlight-btn, .delete-highlight-btn {
            background-color: white;
            border: none;
            cursor: pointer;
            padding: 5px;
          }
          .copy-highlight-btn {
            border-right: 1px solid #E1EBFF;
          }
          .delete-highlight-btn {
            color: red;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
          }
          .action-polygon {
            position: absolute;
            bottom: -10px;
            left: calc(50% - 10px);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid white;
          }
          .no-select {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          .user-select-auto {
            -webkit-user-select: auto;
            -moz-user-select: auto;
            -ms-user-select: auto;
            user-select: auto;
          }
          .custom-scrollbar {
            @apply overflow-y-auto;
            -webkit-overflow-scrolling: touch;
          }
        `}
      </style>

      <div className="h-screen flex flex-col box-border bg-background">
        {/* Header */}
        <div className="flex justify-between items-center px-4 py-4 bg-cardBackground sticky top-0 z-[1000] font-poppins border-b border-[#DCDCDC] no-select">
        <p className="m-0 text-base text-text font-semibold ml-4">
            {currentIndex && totalArticles ? `Article ${currentIndex} / ${totalArticles}` : 'Article'}
          </p>
          <img src={NewsDetailsCloseIcon} className="cursor-pointer w-6 h-6 mr-4" onClick={handleBack} alt="Close Icon" />
        </div>
        {showNotification && (
            <Notification 
              color="green" 
              className="fixed top-[10%] left-1/2 -translate-x-1/2 -translate-y-1/2 z-[100] w-[300px] text-center absolute"
              style={{
                position: 'absolute', 
                top: '10%', 
                left: '50%', 
                transform: 'translate(-50%, -50%)',
                zIndex: 100
              }}
            >
              {notificationMessage}
            </Notification>
          )}
        {/* Main content */}
        <div className="flex-1 overflow-hidden">
          <div
            className={`
              relative 
              ${isMobile ? 'w-full max-w-[28rem]' : 'max-w-[22vw]'}
              h-[calc(100dvh-64px)] overflow-y-auto
              bg-newsCardBackground 
              mx-auto
              box-border
              custom-scrollbar
              user-select-auto
            `}
            style={{ height: 'calc(100dvh - 100px)' }}
            onMouseUp={handleSelectionChange}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onSelectionChange={handleSelectionChange}
          >
            <div className="space-y-4 bg-cardBackground m-4 rounded-lg shadow-[0px_4px_40px_0px_rgba(0,0,0,0.08)] p-[15px]">
              {/* Mark as read button */}
              <button
                onClick={handleReadStatusToggle}
                className="flex items-center justify-center gap-2 px-4 py-2 rounded-full 
                w-[150px] h-[35px] text-xs border border-[#DCDCDC] bg-transparent"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 16 17"
                  fill={colorScheme === 'dark' ? 'white' : '#6374FA'}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12.667 6.71338C11.287 6.71338 10.167 5.59338 10.167 4.21338C10.167 2.83338 11.287 1.71338 12.667 1.71338C14.047 1.71338 15.167 2.83338 15.167 4.21338C15.167 5.59338 14.047 6.71338 12.667 6.71338ZM12.667 2.71338C11.8403 2.71338 11.167 3.38671 11.167 4.21338C11.167 5.04005 11.8403 5.71338 12.667 5.71338C13.4937 5.71338 14.167 5.04005 14.167 4.21338C14.167 3.38671 13.4937 2.71338 12.667 2.71338Z" />
                  <path d="M8.00033 10.0469H4.66699C4.39366 10.0469 4.16699 9.82021 4.16699 9.54688C4.16699 9.27354 4.39366 9.04688 4.66699 9.04688H8.00033C8.27366 9.04688 8.50033 9.27354 8.50033 9.54688C8.50033 9.82021 8.27366 10.0469 8.00033 10.0469Z" />
                  <path d="M10.667 12.7134H4.66699C4.39366 12.7134 4.16699 12.4867 4.16699 12.2134C4.16699 11.94 4.39366 11.7134 4.66699 11.7134H10.667C10.9403 11.7134 11.167 11.94 11.167 12.2134C11.167 12.4867 10.9403 12.7134 10.667 12.7134Z" />
                  <path d="M9.99967 16.0467H5.99967C2.37967 16.0467 0.833008 14.5 0.833008 10.88V6.88005C0.833008 3.26005 2.37967 1.71338 5.99967 1.71338H9.33301C9.60634 1.71338 9.83301 1.94005 9.83301 2.21338C9.83301 2.48671 9.60634 2.71338 9.33301 2.71338H5.99967C2.92634 2.71338 1.83301 3.80671 1.83301 6.88005V10.88C1.83301 13.9534 2.92634 15.0467 5.99967 15.0467H9.99967C13.073 15.0467 14.1663 13.9534 14.1663 10.88V7.54671C14.1663 7.27338 14.393 7.04671 14.6663 7.04671C14.9397 7.04671 15.1663 7.27338 15.1663 7.54671V10.88C15.1663 14.5 13.6197 16.0467 9.99967 16.0467Z" />
                </svg>
                <span className={colorScheme === 'dark' ? 'text-white' : 'text-[#6374FA]'}>{isRead ? 'Mark as unread' : 'Mark as read'}</span>
              </button>

              {/* News source and date */}
              <div className="flex items-center gap-2 mt-4">
                <img src={TheHindu} alt="The Hindu" className="h-[15px]" />
                <span className="text-[#747474] text-[15px]">
                  • {new Date(newsItem.created_at).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).toUpperCase()}
                </span>
              </div>

              {/* Title */}
              <h1 className="text-lg font-semibold text-text leading-tight mt-2 font-poppins no-select">{newsItem.title}</h1>

              {/* Author section */}
              <div className="flex items-center gap-2 mt-4 no-select">
                <Avatar size={40} />
                <div>
                  <p className="text-sm font-semibold text-text">{newsItem.author || 'Unknown Author'}</p>
                  <p className="text-sm text-subtleText">Author</p>
                </div>
              </div>

              {/* PYQ Button */}
              <button
                onClick={handlePYQClick}
                className="w-full flex items-center justify-between bg-coloredButton text-white px-4 py-3 rounded-lg mt-4 no-select"
              >
                <div className="flex gap-1 items-center">
                  <Image src={Star} />
                  <Text className="select-none text-white text-sm">Get UPSC PYQs Related to Article</Text>
                </div>
                <IconArrowRight color="white" />
              </button>

              <ol className="list-decimal list-outside text-[15px] ml-4 user-select-auto">
                {newsItem.gpt_output.summary.map((item) => (
                  <li key={item.id} data-id={item.id} className="text-subtleText mb-2">
                    {renderHighlightedContent(item.point)}
                  </li>
                ))}
              </ol>
              <div
                onClick={handleMCQClick}
                className="flex justify-between items-center gap-1 mt-4 w-full h-[46px] bg-[#61BD85] p-4 rounded-lg cursor-pointer"
              >
                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M10.6666 1.33331H5.33325C2.66659 1.33331 1.33325 2.66665 1.33325 5.33331V14C1.33325 14.3666 1.63325 14.6666 1.99992 14.6666H10.6666C13.3333 14.6666 14.6666 13.3333 14.6666 10.6666V5.33331C14.6666 2.66665 13.3333 1.33331 10.6666 1.33331ZM9.33325 10.1666H4.66659C4.39325 10.1666 4.16659 9.93998 4.16659 9.66665C4.16659 9.39331 4.39325 9.16665 4.66659 9.16665H9.33325C9.60659 9.16665 9.83325 9.39331 9.83325 9.66665C9.83325 9.93998 9.60659 10.1666 9.33325 10.1666ZM11.3333 6.83331H4.66659C4.39325 6.83331 4.16659 6.60665 4.16659 6.33331C4.16659 6.05998 4.39325 5.83331 4.66659 5.83331H11.3333C11.6066 5.83331 11.8333 6.05998 11.8333 6.33331C11.8333 6.60665 11.6066 6.83331 11.3333 6.83331Z"
                      fill="white"
                    />
                  </svg>
                  <Text className="select-none text-white text-sm" style={{ color: 'white' }}>
                    Generate MCQs from the article
                  </Text>
                </div>
                <IconArrowRight color="white" />
              </div>

              <div
                className={`fixed ${
                  menuVisible ? 'flex' : 'hidden'
                } items-center justify-center bg-white text-black w-[200px] p-2.5 rounded-lg shadow-[0px_-8px_24px_0px_rgba(0,0,0,0.33)]`}
                style={{
                  top: menuPosition.y,
                  left: menuPosition.x,
                  zIndex: 1000,
                }}
              >
                <div className="absolute top-full left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[10px] border-r-[10px] border-t-[10px] border-l-transparent border-r-transparent border-t-white" />

                <button
                  onClick={handleHighlight}
                  className="bg-white text-black flex items-center justify-center gap-1 border-none cursor-pointer p-1.5"
                >
                  <img src={HighlighterIcon} alt="highlight" /> Add to Notes
                </button>

                <button
                  onClick={() => handleCopyHighlight(selection.selectedText)}
                  className="bg-white text-black flex items-center justify-center gap-1 border-none cursor-pointer p-1.5"
                >
                  <img src={CopyIcon} alt="Copy" />
                </button>
              </div>

              <Box mt="md" className="text-center" style={{ color: colors.text }}>
                <Text fz="lg">{renderHighlightedContent(newsItem.content)}</Text>
              </Box>

              <Box
                className=""
                style={{
                  marginTop: '0.5rem',
                }}
              >
                {/* Topic Tags Section */}
                <Box className="mt-2 no-select">
                  <div className="text-text font-bold mb-2 font-poppins text-[14px]">Topic Tags</div>

                  {newsItem.prelims_syllabus && (
                    <>
                      <div className="text-text font-normal mb-2 font-poppins text-[15px]">Prelims Syllabus</div>
                      <Group spacing="xs" className="mb-4 flex-wrap">
                        {newsItem.prelims_syllabus.split(',').map((item, index) => (
                          <div key={index} className="text-xs opacity-70 text-text p-2 rounded  border-[#aeadad] border-solid border-[1px]">
                            {item.trim()}
                          </div>
                        ))}
                      </Group>
                    </>
                  )}

                  {newsItem.mains_syllabus && (
                    <>
                      <div className="text-text font-normal mb-2 font-poppins text-[15px]">Mains Syllabus</div>
                      <Group spacing="xs" className="flex-wrap">
                        {newsItem.mains_syllabus.split('GS-').map(
                          (item, index) =>
                            item && (
                              <div key={index} className="text-xs opacity-70 text-text p-2 rounded  border-[#aeadad] border-solid border-[1px]">
                                {`GS-${item.trim()}`}
                              </div>
                            )
                        )}
                      </Group>
                    </>
                  )}

                  <div className="text-text font-semibold mb-2 font-poppins text-[15px] mt-2">Tutor Chat related to the Article</div>
                  <div>
                    {chats.map((chat, index) => (
                      <ChatCard
                        key={index}
                        chat={chat}
                        index={index}
                        totalChats={chats.length}
                        onDownload={handleDownloadPdf}
                        onSelect={handleChatSelect}
                      />
                    ))}
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div
          className={`flex justify-between items-center p-2.5 bg-cardBackground border-t border-border fixed bottom-0 left-0 right-0 w-full max-w-[${
            isMobile ? '28rem' : '22vw'
          }] mx-auto box-border z-1000 no-select`}
        >
          <div className="flex gap-4 items-center">
            <div className="flex gap-2 cursor-pointer items-center" onClick={handleLike}>
              <img
                src={liked || newsItem.user_liked ? LikeActive : Like2}
                alt={liked || newsItem.user_liked ? 'Active Like Icon' : 'Like Icon'}
                className="h-6 w-6"
              />
              <span>{newsItem.likes}</span>
            </div>
            <div className="flex gap-2 cursor-pointer items-center" onClick={handleBookmark}>
              <img
                src={bookmarked || newsItem.user_bookmarked ? BookmarkActive : Bookmark}
                alt={bookmarked || newsItem.user_bookmarked ? 'Active Bookmark Icon' : 'Bookmark Icon'}
                className="h-6 w-6"
              />
              <span>{newsItem.bookmarks}</span>
            </div>
          </div>

          <div className="flex gap-4 items-center">
            <div className="flex items-center cursor-pointer" onClick={createDeepLink}>
              <img src={Share} alt="Share Icon" className="h-6 w-6" />
            </div>
            <div className="flex items-center cursor-pointer" onClick={DownloadSummary}>
              <img src={Download} alt="Download Icon" className="h-6 w-6" />
            </div>
          </div>
        </div>
        <div className="h-15" />

        {imageModalOpened && (
          <div className="absolute inset-0 bg-black/90 flex flex-col justify-center items-center z-[999999999] overflow-hidden h-screen w-[28rem] md:w-[22vw]">
            <div className="absolute top-[4%] left-[45%] -translate-x-1/2 text-white rounded-lg p-4 flex items-center justify-between w-4/5 max-w-[28rem] md:max-w-[22vw]">
              <h2 className="m-0 text-lg font-medium text-white truncate flex-1">
                {newsItem.title.length > 30 ? `${newsItem.title.substring(0, 30)}...` : newsItem.title}
              </h2>
              <button
                onClick={closeImageModal}
                className="bg-transparent border-none text-white text-2xl cursor-pointer ml-3 rounded-full p-1.5 flex-shrink-0"
              >
                ✕
              </button>
            </div>
            <div className="flex-1 flex justify-center items-center w-full h-screen box-border">
              <img src={newsItem.image} alt="Full-size news image" className="max-w-[28rem] md:max-w-[22vw] max-h-[80vh] object-contain" />
            </div>
          </div>
        )}

        <Modal opened={opened} onClose={close} title="Share Link" centered className="z-[9999]" size="sm">
          {error ? (
            <Text color="red">{error}</Text>
          ) : (
            <Flex align="center" gap="md">
              <Box className="flex-1 overflow-hidden">
                <Text className="truncate">{link}</Text>
              </Box>
              <CopyButton value={link} timeout={2000}>
                {({ copied, copy }) => (
                  <Button
                    color={copied ? 'teal' : 'blue'}
                    onClick={() => {
                      copy();
                      MixpanelEvent('feature_selection', {
                        feature_type: 'news share',
                        newspaper_name: newsType,
                        date: selectedDate?.split('-').reverse().join('') || new Date(),
                        reading_time: readingTime || null,
                        streak: streakCount,
                        news_id: newsItem.id,
                        tab: 'Latest',
                        dark_mode: colorScheme === 'dark',
                        topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
                      });
                      sendPostHogEvent('feature_selection', {
                        feature_type: 'news share',
                        newspaper_name: newsType,
                        date: selectedDate?.split('-').reverse().join('') || new Date(),
                        reading_time: readingTime || null,
                        news_id: newsItem.id,
                        tab: 'Latest',
                      });
                    }}
                    className="flex items-center gap-2"
                  >
                    {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                    {copied ? 'Copied' : 'Copy'}
                  </Button>
                )}
              </CopyButton>
            </Flex>
          )}
        </Modal>

        {showHighlightModal && <InfoHighlight onGoBack={closeModal} />}
      </div>
    </>
  );
}
