import React, { useState, useEffect, useRef, useMemo } from 'react';
import { IconCheck, IconHeart, IconMessageCircle2, IconX, IconExclamationCircle } from '@tabler/icons-react';
import { ArrowLeft, ArrowRightFilled, ArrowLeftFilled, ArrowLeftDark, ArrowLeftDarkIcon, ArrowRightDarkIcon } from '../../assets/images';
import { Button, Flex, Text } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../../context/themeProvider';
// import { useSpring, animated } from 'react-spring';
import ReportModaContent from '../../molecules/ReportQuestion';
import { userStore } from '../../stores/userStore';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { MarkdownWithLatex } from '../../molecules/RichText/MarkdownLatex';

const DuelReview = () => {
  const location = useLocation();
  const reviewData = location.state?.duel || location.state?.practice;
  const reviewType = location.state?.duel ? 'duel' : 'practice';

  const questionData = useMemo(() => {
    if (reviewType === 'practice') {
      return reviewData.history_data.map((question) => ({
        ...question,
        category: question.category || reviewData.category,
        choices: question.choices.map((choice, index) => ({
          [String.fromCharCode(97 + index)]: choice,
        })),
        correct_answer: String.fromCharCode(97 + question.correct_answer),
        user_option: question.user_option !== null ? String.fromCharCode(97 + question.user_option) : null,
      }));
    }
    return reviewData.questionData;
  }, [reviewData, reviewType]);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const navigate = useNavigate();
  const { colors, colorScheme } = useTheme();
  const containerRef = useRef(null);
  const isMobile = window.innerWidth <= 2500;
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [reportData, setReportData] = useState('');
  const [reportDetails, setReportDetails] = useState('');
  const [isReportSubmitted, setIsReportSubmitted] = useState(false);
  const userData = userStore((state) => state.userData);
  const userId = userData.user;

  const handleReportOpen = () => setIsOpen(true);

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsReportSubmitted(false);
    setReportDetails('');
    setReportData('');
  };

  const handleReportSubmit = async () => {
    setIsReportSubmitted(true);
    let postPayload = {
      flow: reviewType,
      question_id: questionData[currentQuestionIndex].question_id,
      user: userId,
      option: reportData,
      reason: '',
    };

    if (reportData === 'Other') {
      postPayload.reason = reportDetails;
    }

    MixpanelEvent('submit_feedback', {
      category: questionData[currentQuestionIndex].category,
      source: `${reviewType}_review`,
    });

    sendPostHogEvent('submit_feedback', {
      selection: reportData,
      question_id: questionData[currentQuestionIndex].question_id,
      category: questionData[currentQuestionIndex].category,
      source: `${reviewType}_review`,
    });

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();
      setTimeout(() => {
        handleCloseModal();
      }, 3000);
    } catch (error) {}
  };

  // const [props, set] = useSpring(() => ({
  //   x: 0,
  //   config: { tension: 300, friction: 30 },
  // }));

  useEffect(() => {
    if (!reviewData) {
      navigate('/duel');
    }
  }, [reviewData, navigate]);

  if (!reviewData) return null;

  const currentQuestion = questionData[currentQuestionIndex];

  const backClick = () => {
    if (reviewType === 'practice') {
      navigate('/practice');
    } else {
      navigate(-1);
    }
    MixpanelEvent('navigation_back', { source_screen: `${reviewType}_review` });
    sendPostHogEvent('navigation_back', { source_screen: `${reviewType}_review` });
  };

  const discussWithAi = (question, selectedOption) => {
    const optionKey = ['a', 'b', 'c', 'd'];

    const aiTutorParams = {
      flowType: 'postGame',
      source: reviewType,
      id: null,
      chatType: 'ai_tutor',
      node_id: null,
      chatgraph_id: null,
      messages: [
        {
          role: 'user',
          convo_id: '',
          type: 'Question',
          content: question.question,
        },
        {
          role: 'user',
          convo_id: '',
          type: 'My Answer',
          content: question.user_answer,
        },
      ],
      preSelectQuestion: {
        answer: null,
        choices: question.choices.map((option, idx) => ({
          [optionKey[idx]]: option,
        })),
        category: question.category,
        question: question.question,
        question_id: question.question_id,
        user_answer: question.choices[selectedOption],
        user_option: question.user_answer,
        correct_answer: question.user_option,
        options: question.choices,
      },
      category: question.category,
      preSelectAnswer: question.choices[selectedOption],
    };
    return aiTutorParams;
  };

  const handleDiscussWithAi = () => {
    MixpanelEvent('discuss_with_ai', {
      source: `${reviewType}_review`,
      category: reviewType === 'practice' ? reviewData.category : reviewData.category,
    });
    sendPostHogEvent('discuss_with_ai', {
      source: `${reviewType}_review`,
      category: reviewType === 'practice' ? reviewData.category : reviewData.category,
    });
    const aiTutorParams = discussWithAi(currentQuestion, currentQuestion.user_option);
    navigate(`/chat/new/`, { state: { aiTutorParams } });
  };

  const handleNextQuestion = () => {
    // set({ x: -window.innerWidth });
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex < questionData.length - 1 ? prevIndex + 1 : prevIndex));
      // set({ x: 0, immediate: true });
    }, 300);
  };

  const handlePreviousQuestion = () => {
    // set({ x: window.innerWidth });
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      // set({ x: 0, immediate: true });
    }, 300);
  };

  const handleTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
    // set({ x: touchStart - e.targetTouches[0].clientX });
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe) {
      handleNextQuestion();
    } else if (isRightSwipe) {
      handlePreviousQuestion();
    } else {
      // set({ x: 0 });
    }
  };

  const isCorrect = currentQuestion.user_option === currentQuestion.correct_answer;

  return (
    <div className="text-[#333] max-w-[600px] mx-auto h-[100dvh] flex  text-text flex-col bg-background">
      <div className="p-4 bg-background  text-text shadow-[0px_4px_9.2px_0px_rgba(0,0,0,0.10)]">
        <Flex align="center" className="mb-5">
        <img 
            onClick={backClick} 
            src={colorScheme === 'dark' ? ArrowLeftDark : ArrowLeft} 
            alt="Arrow Left" 
            className="cursor-pointer mr-2.5"
          />
          <Text className="text-xl font-semibold">{reviewType === 'duel' ? 'Duel Review' : 'Practice Review'}</Text>
        </Flex>

        <div className="flex justify-between items-center w-full">
          <img
            src={colorScheme === 'dark' ? ArrowLeftDarkIcon : ArrowLeftFilled} 
            className={`cursor-pointer rounded-lg border border-[#D1D1D1] p-1.5 ${currentQuestionIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handlePreviousQuestion}
            alt="Arrow Left"
          />
          <div className="flex-1 overflow-hidden mx-2.5">
            <div className="flex overflow-x-auto scrollbar-hidden">
              {questionData.map((_, index) => (
                <div
                  key={index}
                  className={`min-w-[60px] h-[30px] flex justify-center items-center rounded-full cursor-pointer mx-1.5 flex-shrink-0 ${
                    index === currentQuestionIndex
                      ? `${colorScheme === 'dark' ? 'bg-[#EBF3FF] text-black' : 'bg-[rgb(219,219,219)] text-[#6374FA]'}`
                      : 'bg-transparent text-text'
                  }`}
                  onClick={() => setCurrentQuestionIndex(index)}
                >
                  Q{index + 1}
                </div>
              ))}
            </div>
          </div>

          <img
            src={colorScheme === 'dark' ? ArrowRightDarkIcon : ArrowRightFilled} 
            className={`cursor-pointer rounded-lg border border-[#D1D1D1] p-1.5  ${
              currentQuestionIndex === questionData.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleNextQuestion}
            alt="Arrow Right"
          />
        </div>
      </div>

      <div
        className={`
          mx-auto px-2.5 py-2 text-xs font-bold text-center rounded-full relative top-[29px] z-10
          ${isCorrect ? 'bg-[#e6f7ed] text-[#059669]' : 'bg-[#ffe6e6] text-[#cc0000]'}
        `}
      >
        Question {currentQuestionIndex + 1} | {isCorrect ? 'Correct' : 'Incorrect'} Answer
      </div>
      {/* question bar */}
      <div
        className="flex-1 overflow-y-auto overflow-x-hidden relative touch-pan-y  m-[15px_15px_0_15px] scrollbar-hidden"
        ref={containerRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className={`w-full bg-cardBackground border ${colorScheme === 'dark' ? 'border-[#464646]' : ''} rounded-[12px]`}>
          <div className="text-text rounded-xl mt-[15px] p-4 h-[auto] mb-[3em] overflow-y-auto scrollbar-hide">
            <div className="flex justify-start items-center mb-2.5 gap-2.5">
            <Text  fw={700} className="text-xs text-text py-1 px-2 rounded ">
              {currentQuestion.category.toUpperCase()}
            </Text>
              <IconHeart className="text-text cursor-pointer" />
            </div>

            <Text className="text-base font-medium ">
              {currentQuestion.question.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  <MarkdownWithLatex content={line} />
                </React.Fragment>
              ))}
            </Text>

            <div className="flex flex-col mt-5 gap-2.5">
              {currentQuestion.choices.map((choice, index) => {
                const [key, value] = Object.entries(choice)[0];
                const isCorrectAnswer = key === currentQuestion.correct_answer;
                const isUserAnswer = key === currentQuestion.user_option;
                const isWrongUserAnswer = isUserAnswer && !isCorrectAnswer;

                return (
                  <div
                    key={key}
                    className={`
                      flex items-center p-4 rounded-xl cursor-pointer
                      ${isCorrectAnswer ? 'bg-[#E4FFEF] text-black border border-[#34d399]' : 
                        isWrongUserAnswer ? 'bg-[#FFB6B6] text-black  border border-[#ff9999]' : 
                        'bg-background  border border-[#e0e0e0]'}
                    `}
                  >
                    <div className="mr-2.5">
                      <div
                        className={`
                          w-[23px] h-[23px] rounded-full border-2 flex justify-center items-center
                          ${isUserAnswer ? (isCorrectAnswer ? 'border-[#34d399]' : 'border-[#ff9999]') : 'border-[#e0e0e0]'}
                        `}
                      >
                        {isCorrectAnswer && <IconCheck className="bg-[#61BD85] w-[21px] h-[20px] rounded-full text-[#E4FFEF] p-0.5" />}
                        {isWrongUserAnswer && <IconX className="bg-[#D95353] w-[21px] h-[20px] rounded-full text-[#FFB6B6] p-0.5" />}
                      </div>
                    </div>
                    <Text
                      className={`
                        text-[15px] font-medium flex items-center flex-1
                        ${isCorrectAnswer ? 'text-[#059669]' : isWrongUserAnswer ? 'text-[#cc0000]' : 'text-[#333]'}
                      `}
                    >
                      {key.toUpperCase()}. &nbsp; <MarkdownWithLatex content={value} />
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div
      className={`
        flex justify-between p-2.5 shadow-[0px_-4px_24px_0px_rgba(0,0,0,0.12)] w-full absolute bottom-0 left-0 right-0 z-[1000] box-border m-0
        ${colorScheme === 'dark' ? 'bg-[#242424]' : 'bg-white'}
      `}
      style={{
        borderTop: `1px solid ${colors.border}`,
      }}
    >
      {/* Report Button */}
      <button
        className={`
          flex items-center px-4 py-2 border-none cursor-pointer text-sm font-medium
          ${colorScheme === 'dark' ? 'text-red-500' : 'text-red-600'}
        `}
        onClick={handleReportOpen}
      >
        <IconExclamationCircle className="mr-1" height={18} width={18} />
        Report
      </button>

      {/* Discuss with AI Button */}
      <button
        className={`
          flex items-center px-4 py-2 text-sm font-medium rounded-full cursor-pointer
          ${colorScheme === 'dark' 
            ? 'bg-[#242424] text-white border border-white' 
            : 'bg-white text-black border border-black'}
        `}
        onClick={handleDiscussWithAi}
      >
        Discuss with AI
      </button>
    </div>


      <ReportModaContent
        isOpen={isOpen}
        closeModal={handleCloseModal}
        resportData={reportData}
        setResportData={setReportData}
        details={reportDetails}
        onChange={(e) => setReportDetails(e.currentTarget.value)}
        handleResportSubmit={handleReportSubmit}
        isReportSubmited={isReportSubmitted}
        isMobile={isMobile}
      />
    </div>
  );
};

export default DuelReview;
