import React, { useState } from 'react';
import { Card, Text, Group, ActionIcon, Button, Image, Flex, Box } from '@mantine/core';
import { NEWS_SOURCE } from '../../constants/newsData';
import { TheHinduLogo, IndianExpressLogo, NewsLikeIcon, NewsBookMarkIcon, NewsTagDropDownIcon, ArrowRight2, doubleTik } from '../../assets/images';
import { useTheme } from '../../context/themeProvider';
import dayjs from 'dayjs';

const splitTitle = (title, maxLength = 15) => {
  if (title.length <= maxLength) return [title];
  const firstLine = title.slice(0, maxLength);
  const secondLine = title.slice(maxLength);
  return [firstLine, secondLine];
};

const NewsListItem = ({ title, likes, bookmarks, newsItem, imageSrc, tags = [], onClick, newsType, created_at, isLastItem, readIds }) => {
  const { colors } = useTheme();
  const [firstLine, secondLine] = splitTitle(title);
  const [showTags, setShowTags] = useState(false);
  const formattedDate = created_at ? dayjs(created_at).format('DD MMM YYYY') : null;
  const isRead = readIds?.includes(newsItem.id);

  return (
    <div
      onClick={onClick}
      className={`
        w-full 
        box-border 
        font-poppins 
        bg-cardBackground
        border 
        border-border
        rounded-lg 
        p-4 
        mt-2 
        ${isLastItem ? 'mb-[45%]' : ''}
      `}
    >
      <div className="flex items-start space-x-4">
        <img src={newsType === NEWS_SOURCE.THE_HINDU ? TheHinduLogo : IndianExpressLogo} alt="News Logo" className="w-16 h-auto" />
        <div className="flex-1">
          <div
            weight={600}
            className={`
              text-base 
              mb-2 
              text-text
              leading-tight
            `}
          >
            {firstLine && <>{firstLine}</>}
            {secondLine && <>{secondLine}</>}
          </div>

          <div className="flex justify-between items-center">
            <div className="flex space-x-2">
              <div className="flex items-center space-x-1">
                <div className={`text-xs text-subtleText`}>{likes}</div>
                <div className="ml-0.5">
                  <img src={NewsLikeIcon} alt="Like" className="w-4 h-4" />
                </div>
              </div>

              <div className="flex items-center space-x-1">
                <div className={`text-xs text-subtleText`}>{bookmarks}</div>
                <div className="ml-0.5">
                  <img src={NewsBookMarkIcon} alt="Bookmark" className="w-4 h-4" />
                </div>
              </div>
            </div>

            <div className={`font-medium text-xs text-subtleText`}>{formattedDate}</div>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-2">
        <div className="flex flex-col">
          {showTags &&
            tags.map((item, index) => (
              <div
                key={index}
                className={`
                  w-full 
                  max-w-[20rem] 
                  border 
                  border-border 
                  rounded-sm 
                  mb-1 
                  box-border 
                  bg-cardBackground
                `}
              >
                <div
                  className={`
                  text-center 
                  text-xs 
                  text-text
                  p-1
                `}
                >
                  {item}
                </div>
              </div>
            ))}

          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowTags(!showTags);
            }}
            variant="subtle"
            className={`
              -ml-2.5 
              self-start 
              pb-1 
              border-b 
              border-border
            `}
            size="xs"
            compact
          >
            <div className="flex items-center space-x-1">
              <div
                className={`
                text-xs 
                font-bold 
                text-subtleText
              `}
              >
                {showTags ? 'SHOW LESS' : 'VIEW TAGS'}
              </div>
              <img
                src={NewsTagDropDownIcon}
                alt="Dropdown"
                className={`
                  w-4 
                  h-4 
                  transform 
                  transition-transform 
                  duration-200 
                  ease-in-out 
                  ${showTags ? 'rotate-180' : 'rotate-0'}
                `}
              />
            </div>
          </button>
        </div>

        <div className="flex items-center space-x-1">
          {isRead && (
            <div className="flex items-center space-x-1">
              <img src={doubleTik} alt="Read" className="w-4 h-4" />
              <div
                className={`
                text-xs 
                text-subtleText
              `}
              >
                Read
              </div>
            </div>
          )}
          <button variant="subtle" size="xs" compact>
            <img src={ArrowRight2} alt="Arrow" className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsListItem;
