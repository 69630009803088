import React, { useState } from 'react';
import DuelOnline from './DuelOnline';
import Tournaments from './Tournaments';
import DuelWithFriends from './DuelWithFriends';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useTheme } from '../../context/themeProvider';

export default function DuelPage() {
  const [activeTab, setActiveTab] = useState('duelOnline');
  const { colorScheme } = useTheme();
  const windowHeight = window.innerHeight;
  const isShortWindow = windowHeight < 900 && window.innerWidth < 400;
  const fontSize = isShortWindow ? '15px' : '15px';

  const activeStyle = {
    borderBottom: '1px solid blue',
    color: colorScheme === 'dark' ? 'white' : 'black',
  };

  const inactiveStyle = {
    borderBottom: '1px solid transparent',
    color: 'gray',
  };
  const getTabBorderStyle = (tab) => ({
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: activeTab === tab ? '1px solid blue' : '1px solid transparent',
    padding: '10px',
    cursor: 'pointer',
    fontWeight: 'normal',
    background: 'none',
    outline: 'none',
    ...((activeTab === tab) ? activeStyle : inactiveStyle),
  });
  const handleTabClick = (tab) => {
    const isDefaultTab = tab === 'online';
    MixpanelEvent('duel_header_selection', { tab, default_tab: isDefaultTab });
    sendPostHogEvent('duel_header_selection', { tab, default_tab: isDefaultTab });
  };

  return (
    <div className="bg-background">
      {/* Tab Navigation */}
      <div
        className="flex mt-14 border-b border-[#2E2E2E]"
        style={{
          fontSize: fontSize,
          justifyContent: 'space-evenly',
        }}
      >
        <button
          onClick={() => {
            setActiveTab('duelOnline');
            handleTabClick('online');
          }}
          style={getTabBorderStyle('duelOnline')}
        >
          Duel Online
        </button>
        <button
          onClick={() => {
            setActiveTab('tournaments');
            handleTabClick('tournaments');
          }}
          style={getTabBorderStyle('tournaments')}
        >
          Tournaments
        </button>
        <button
          onClick={() => {
            setActiveTab('duelWithFriends');
            handleTabClick('friends');
          }}
          style={getTabBorderStyle('duelWithFriends')}
        >
          Duel with Friends
        </button>
      </div>

      {/* Tab Content */}
      <div className="p-4">
        {activeTab === 'duelOnline' && <DuelOnline />}
        {activeTab === 'tournaments' && <Tournaments />}
        {activeTab === 'duelWithFriends' && <DuelWithFriends />}
      </div>
    </div>
  );
}
