import React from 'react';
import { DeletingAccountImage } from '../../assets/images';
import { useTheme } from '../../context/themeProvider';

const DeletingPage = () => {
  const { colorScheme } = useTheme();

  return (
    <div className={`flex flex-col items-center justify-center h-screen ${
      colorScheme === 'dark' ? 'bg-background' : 'bg-[#f9f9f9]'
    }`}>
      <img src={DeletingAccountImage} alt="Deleting Account" />
    </div>
  );
};

export default DeletingPage;