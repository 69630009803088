import { NoBookmarks } from '../../assets/images';
import { userStore } from '../../stores/userStore';
import { useTheme } from '../../context/themeProvider';
import { callGETAPI } from '../../utils/NetworkUtil';
import { useEffect, useState } from 'react';
import NewsListItem from './newsCard';
import { useNavigate } from 'react-router-dom';
import Pagination from './pagination'; // Import the Pagination component

export default function Bookmarks() {
  const userData = userStore((state) => state.userData);
  const { colors } = useTheme();
  const [userBookmarkData, setUserBookmarkData] = useState([]);
  const [selectedNews, setSelectedNews] = useState('THE_HINDU');
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const navigate = useNavigate();
  const [heightClass, setHeightClass] = useState('');

  const fetchBookmarks = (page) => {
    if (userData?.user_id) {
      callGETAPI(
        `${process.env.REACT_APP_BASE_URL}/api/user-bookmarks/?user=${userData.user_id}&page=${page}&page_size=50`,
        (response) => {
          console.log(response, 'response');
          if (response.data?.results) {
            setUserBookmarkData(response.data.results);
            setTotalPages(Math.ceil(response.data.count / 50));
          }
        },
        (error) => {
          console.error('API call error:', error);
        }
      );
    }
  };

  useEffect(() => {
    fetchBookmarks(currentPage);
  }, [currentPage, userData?.user_id]);

  // Dynamically set height class based on viewport size
  const updateHeightClass = () => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    console.log('Viewport Height:', viewportHeight,viewportWidth); // Log the height

    if (viewportHeight > 1450 && viewportWidth > 1440) {
      setHeightClass('83dvh');
    } else if (viewportHeight > 1300 && viewportWidth > 1440) {
      setHeightClass('77dvh');
    } else if (viewportHeight > 1275 && viewportWidth > 1440) {
      setHeightClass('75dvh');
    } else if (viewportHeight > 1200 && viewportWidth > 1440) {
      setHeightClass('73dvh');
    } else if (viewportHeight > 1000 && viewportWidth > 1440) {
      setHeightClass('70dvh');
    } else if (viewportHeight > 900 && viewportWidth > 1000) {
      setHeightClass('66dvh');
    } else if (viewportHeight > 800 && viewportWidth > 1000) {
      setHeightClass('60dvh');
    } else if (viewportWidth < 400 && viewportHeight < 700) {
      setHeightClass('51dvh');
    } else if (viewportWidth < 480  && viewportHeight < 700) {
      setHeightClass('53dvh');
    }else if (viewportWidth < 400 && viewportHeight < 800) {
      setHeightClass('54dvh');
    }else if (viewportWidth < 400 && viewportHeight < 900) {
      setHeightClass('60dvh');
    }else if (viewportWidth < 480 && viewportHeight < 975) {
      setHeightClass('62dvh');
    }  else {
      setHeightClass('70dvh'); // Default height
    }
  };

  useEffect(() => {
    updateHeightClass(); // Initial call
    window.addEventListener('resize', updateHeightClass); // Update on resize

    return () => {
      window.removeEventListener('resize', updateHeightClass); // Cleanup
    };
  }, []);

  const handleNewsClick = (data, index) => {
    navigate(`/news/${data.id}`, {
      state: {
        newsItem: {
          id: data.id,
        },
      },
    });
  };

  if (userBookmarkData.length === 0) {
    return <NoBookmarkComp colors={colors} />;
  }

  return (
    <div className="flex flex-col">
      <div
        className="flex-grow p-4 overflow-y-scroll scrollbar-hidden"
        style={{ height: heightClass }}
      >
      {userBookmarkData.map((data, index) => (
          <NewsListItem
            key={data.id}
            tags={data?.gpt_output?.mains_syllabus || []}
            title={data.title}
            likes={data.likes}
            bookmarks={data.bookmarks}
            imageSrc={data.imageSrc}
            newsItem={data}
            newsType={selectedNews}
            created_at={data.created_at}
            onClick={() => handleNewsClick(data, index)}
          />
        ))}
      </div>

      {/* Pagination Section */}
      <div className="flex-shrink-0 bg-background  flex justify-center border-t border-border">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>

    </div>
  );
}

const NoBookmarkComp = () => {
  return (
    <div className="h-[72vh] bg-background overflow-scroll">
      <div className="flex justify-center items-center h-full">
        <div className="w-[80%] max-w-[600px] text-center">
          <img
            src={NoBookmarks}
            alt="nothing here img"
            className="w-full object-cover mb-4"
          />
          <h2 className="font-normal font-poppins mb-2">Nothing here!</h2>
          <p className="font-poppins text-sm mx-8 leading-7 text-gray-500">
            Bookmark your favorite News Summaries for a personalized News Feed.
          </p>
        </div>
      </div>
    </div>
  );
};
