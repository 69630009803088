import React, { useState } from 'react';
import { DuelStateInfo1, DuelStateInfo2, DuelStateInfo3, DuelStateInfo4, ArrowLeftFilled, ArrowRightFilled, SendIcon, ArrowRightDarkIcon, ArrowLeftDarkIcon } from '../../../assets/images';
import { useTheme } from '../../../context/themeProvider'; 

const InfoTips = ({ opened = true, onClose }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { colorScheme } = useTheme(); 

  const slides = [
    {
      imageUrl: DuelStateInfo1,
      step: null,
    },
    {
      imageUrl: DuelStateInfo2,
      step: 'Step 1',
      description: 'Select a topic',
    },
    {
      imageUrl: DuelStateInfo3,
      step: 'Step 2',
      description: 'Checkout the leaderboards',
    },
    {
      imageUrl: DuelStateInfo4,
      step: 'Step 3',
      description: 'Monitor your stats',
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  if (!opened) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9999999999]">
      <div className="fixed inset-0 bg-black/50" onClick={onClose} />
      
      <div className={`relative rounded-[12px] w-auto ${
        colorScheme === 'dark' ? 'bg-cardBackground' : 'bg-cardBackground'
      }`}>
        {/* Header */}
        <div className="flex items-center justify-between px-4 pt-6">
          <h4 className={`text-center text-lg font-medium flex-grow ${
            colorScheme === 'dark' ? 'text-text' : ''
          }`}>
            Duel Stats
          </h4>
          <button 
            onClick={onClose}
            className={`p-2 rounded-full ${
              colorScheme === 'dark' 
                ? 'hover:bg-background' 
                : 'hover:bg-gray-100'
            }`}
          >
            <svg className={`w-4 h-4 ${
              colorScheme === 'dark' ? 'text-text' : ''
            }`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="p-6">
          <div className="flex flex-col items-center justify-center rounded-md">
            {slides[currentSlide].step && (
              <div className="w-full">
                <div className={`border rounded-lg p-4 text-center ${
                  colorScheme === 'dark' 
                    ? 'border-borderColor bg-cardBackground text-text' 
                    : 'border-gray-200'
                }`}>
                  <h4 className={`text-base font-medium ${
                    colorScheme === 'dark' ? 'text-text' : ''
                  }`}>
                    {slides[currentSlide].step}
                  </h4>
                  <p className={`text-sm ${
                    colorScheme === 'dark' ? 'text-text opacity-70' : 'text-gray-600'
                  }`}>
                    {slides[currentSlide].description}
                  </p>
                </div>
              </div>
            )}

            <img 
              src={slides[currentSlide].imageUrl} 
              alt={`Slide ${currentSlide + 1}`} 
              className="w-[18.75rem]"
            />
          </div>

          {/* Navigation */}
          <div className="flex items-center justify-between mt-8">
            <button
              onClick={prevSlide}
              disabled={currentSlide === 0}
              className={`border rounded-md p-2 ${
                colorScheme === 'dark' 
                  ? 'border-borderColor text-text hover:bg-background' 
                  : 'border-black hover:bg-gray-50'
              } ${
                currentSlide === 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              <img 
                src={colorScheme === 'dark' ? ArrowLeftDarkIcon : ArrowLeftFilled} 
                alt="Previous" 
                className="w-5" 
              />
            </button>

            <div className="flex space-x-2">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`w-2.5 h-2.5 rounded-full ${
                  index === currentSlide 
                    ? colorScheme === 'dark' 
                      ? 'bg-blue-600' 
                      : 'bg-blue-600'
                    : colorScheme === 'dark' 
                      ? 'bg-gray-600' 
                      : 'bg-gray-300'
                }`}
              />
            ))}
            </div>

            {currentSlide < slides.length - 1 ? (
              <button
                onClick={nextSlide}
                className={`border rounded-md p-2 ${
                  colorScheme === 'dark' 
                    ? 'border-borderColor text-text hover:bg-background' 
                    : 'border-black hover:bg-gray-50'
                }`}
              >
                  <img 
              src={colorScheme === 'dark' ? ArrowRightDarkIcon : ArrowRightFilled} 
              alt="Next" 
              className="w-5" 
            />
              </button>
            ) : (
              <button
                onClick={onClose}
                className={`flex items-center px-4 py-2 rounded-md border-0 ${
                  colorScheme === 'dark'
                    ? 'bg-white text-black'
                    : 'bg-[#070519] text-white'
                }`}
              >
                Check out
                <img src={SendIcon} alt="send" className="ml-1" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoTips;