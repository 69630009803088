import React, { useState, useCallback, useEffect } from 'react';
import { Badge, Box, Button, Group, Radio, Stack, Tabs, Text, Textarea, Title } from '@mantine/core';
import { IconArrowLeft, IconExclamationCircle, IconEye, IconEyeOff } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useNews } from '../../context/newsProvide';
import { useTheme } from '../../context/themeProvider';
import { userStore } from '../../stores/userStore';
import Lottie from 'react-lottie';
import * as successAnimation from '../../assets/json/report_success.json';
import { ArrowLeftDarkIcon, ArrowLeftFilled, ArrowRightDarkIcon, ArrowRightFilled, NoDataImage } from '../../assets/images';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import PrelimsQuestionsTab from './PrelimsQuestionsTab';

const NewsPyqPage = () => {
  const { colors, colorScheme } = useTheme();
  const { id } = useParams();
  const { newsDetailData } = useNews();
  console.log(newsDetailData.id, 'the news id here');
  const navigate = useNavigate();

  const mainsQuestions = newsDetailData.pyqs?.mains_questions || [];
  const prelimsQuestions = newsDetailData.pyqs?.prelims_questions || [];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswerIndex, setSelectedAnserIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('mains');
  const [showSolution, setShowSolution] = useState({});

  const [isOpen, setIsOpen] = useState(false);
  const isMobile = window.innerWidth <= 2500;
  const [details, setDetails] = useState('');
  const [resportData, setResportData] = useState('');

  const userData = userStore((state) => state.userData);
  const userId = userData.user;
  const [selectedQuestion, setSelectedQuestionId] = useState('');
  const [isReportSubmited, setReportSubmit] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const [questionStates, setQuestionStates] = useState(
    prelimsQuestions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null }))
  );

  const handlePrevQuestion = useCallback(
    (questions) => {
      if (currentQuestionIndex > 0) {
        const questionIndex = currentQuestionIndex - 1;
        setCurrentQuestionIndex(questionIndex);
        if (questionStates[questionIndex]?.isSubmitted) {
          setSelectedAnserIndex(questionStates[questionIndex].answerIndex);
        }
      }
    },
    [currentQuestionIndex, questionStates]
  );

  const handleNextQuestion = useCallback(
    (questions) => {
      if (currentQuestionIndex < questions.length - 1) {
        const questionIndex = currentQuestionIndex + 1;
        setCurrentQuestionIndex(questionIndex);
        if (questionStates[questionIndex]?.isSubmitted) {
          setSelectedAnserIndex(questionStates[questionIndex].answerIndex);
        }
      }
    },
    [currentQuestionIndex, questionStates]
  );

  const handleQuestionClick = useCallback(
    (index) => {
      setCurrentQuestionIndex(index);
      if (questionStates[index]?.isSubmitted) {
        setSelectedAnserIndex(questionStates[index].answerIndex);
      }
    },
    [questionStates]
  );

  useEffect(() => {
    setQuestionStates(prelimsQuestions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null })));
  }, [prelimsQuestions]);

  const trackTabChange = (tab) => {
    MixpanelEvent('pyq_tab_selection', {
      type: tab,
      newspaper_name: 'The Hindu',
      news_id: id,
      tab: 'Latest',
    });
    sendPostHogEvent('pyq_tab_selection', {
      type: tab,
      newspaper_name: 'The Hindu',
      news_id: id,
      tab: 'Latest',
    });
  };

  const toggleSolution = (index) => {
    setShowSolution((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
    MixpanelEvent('pyq_solution_clicked', {
      tab: 'Latest',
      newspaper_name: newsDetailData?.news_paper?.name || 'Unknown',
      news_id: id,
      type: activeTab,
      topic: newsDetailData?.gpt_output?.mains_syllabus?.join(', ') || 'N/A',
      action: showSolution[currentQuestionIndex] ? 'hidden' : 'shown',
      question_number: currentQuestionIndex,
    });
    sendPostHogEvent('pyq_solution_clicked', {
      tab: 'Latest',
      newspaper_name: newsDetailData?.news_paper?.name || 'Unknown',
      news_id: id,
      type: activeTab,
      topic: newsDetailData?.gpt_output?.mains_syllabus?.join(', ') || 'N/A',
      action: showSolution[currentQuestionIndex] ? 'hidden' : 'shown',
      question_number: currentQuestionIndex,
    });
  };

  const NoData = () => (
    <Box className="text-center p-5 h-[60dvh] flex justify-center items-center flex-col">
      <img src={NoDataImage} alt="no data" />
      <Text className={`text-[${colors.subtleText}] font-semibold`}>{'No Related Questions Found'}</Text>
      <Text className={`text-[${colors.subtleText}]`}>
        We couldn't find any related Mains questions for this article. Feel free to explore different articles for PYQs.
      </Text>
    </Box>
  );

  const handleCloseModal = () => {
    setIsOpen(false);
    setDetails('');
    MixpanelEvent('screen_closed', { source_screen: 'Report Question', close_type: 'modal' });
    sendPostHogEvent('screen_closed', { source_screen: 'Report Question', close_type: 'modal' });
  };

  const handleReport = (prelimsQuestions) => {
    setIsOpen(true);
    setSelectedQuestionId(prelimsQuestions);
    setReportSubmit(false);
    setResportData('');
  };

  const handleResportSubmit = async () => {
    setReportSubmit(true);
    let postPayload = {
      flow: 'newspyq',
      question_id: selectedQuestion,
      user: userId,
      option: resportData,
      reason: '',
    };

    if (resportData === 'Other') {
      postPayload.reason = details;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();
      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    } catch (error) {}
  };

  const QuestionNavigation = ({ questions }) => (
    <div className="flex justify-between items-center w-full">
      <img
        src={colorScheme === 'dark' ? ArrowLeftDarkIcon : ArrowLeftFilled}
        className={`cursor-pointer rounded-lg border border-[#D1D1D1] p-1.5 ${currentQuestionIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={() => handlePrevQuestion(questions)}
        alt="Arrow Left"
      />
      <div className="flex-1 overflow-hidden mx-2.5">
        <div className="flex overflow-x-auto scrollbar-hidden">
          {questions.map((_, index) => (
            <div
              key={index}
              className={`min-w-[60px] h-[30px] flex justify-center items-center rounded-full cursor-pointer mx-1.5 flex-shrink-0 ${
                index === currentQuestionIndex
                  ? `${colorScheme === 'dark' ? 'bg-[#EBF3FF] text-black' : 'bg-[rgb(219,219,219)] text-[#6374FA]'}`
                  : 'bg-transparent text-text'
              }`}
              onClick={() => setCurrentQuestionIndex(index)}
            >
              Q{index + 1}
            </div>
          ))}
        </div>
      </div>

      <img
        src={colorScheme === 'dark' ? ArrowRightDarkIcon : ArrowRightFilled}
        className={`cursor-pointer rounded-lg border border-[#D1D1D1] p-1.5  ${
          currentQuestionIndex === questions.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={handleNextQuestion}
        alt="Arrow Right"
      />
    </div>
  );

  const MainsContent = React.memo(() => (
    <div className="font-poppins text-inherit max-w-[600px] mx-auto h-[89dvh] flex flex-col  mb-4">
      <div className="p-[15px] bg-[inherit] shadow-md">{mainsQuestions.length > 0 && <QuestionNavigation questions={mainsQuestions} />}</div>
      {mainsQuestions.length > 0 ? (
        <div className="flex-1 bg-cardBackground overflow-y-auto overflow-x-hidden relative border border-border m-[15px] h-[calc(100dvh-180px)] rounded-[12px]">
          <div className="p-[15px] h-[88%] overflow-y-auto">
            <div className="flex justify-start items-center mb-2.5 gap-2.5">
              <Badge color={colors.subtleText}>{mainsQuestions[currentQuestionIndex]?.exam.toUpperCase()}</Badge>
              <Badge color={colors.subtleText}>{mainsQuestions[currentQuestionIndex]?.year}</Badge>
            </div>

            <div className="text-base font-bold mb-5">
              {mainsQuestions[currentQuestionIndex]?.question.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>

            {showSolution[currentQuestionIndex] && (
              <div className="mt-2.5">
                <div className="text-inherit font-bold mb-1.25 block">SOLUTION</div>
                {mainsQuestions[currentQuestionIndex]?.solution
                  ? mainsQuestions[currentQuestionIndex].solution.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))
                  : 'No solution available.'}
              </div>
            )}
          </div>
          {mainsQuestions.length > 0 && (
            <div className="flex justify-between p-2.5 shadow-[0px_-4px_24px_0px_#0000001F] border border-border w-full bg-cardBackground absolute bottom-0 left-0 right-0 z-10 box-border sm:mb-0 mb-4">
            <button onClick={() => handleReport(mainsQuestions[currentQuestionIndex].id)} className="text-red-500 flex items-center">
                <IconExclamationCircle className="mr-1" height={18} width={18} />
                Report
              </button>
              <button
                className={`flex justify-between items-center bg-transparent border-2  border-border rounded-3xl p-2 cursor-pointer text-sm font-bold ${
                  showSolution[currentQuestionIndex] ? 'text-[#404044]' : 'text-[#070519]'
                }`}
                onClick={() => toggleSolution(currentQuestionIndex)}
              >
                {showSolution[currentQuestionIndex] ? <IconEyeOff className="mr-1" /> : <IconEye className="mr-1" />}
                {showSolution[currentQuestionIndex] ? 'Hide Solution' : 'View Solution'}
              </button>
            </div>
          )}
        </div>
      ) : (
        <NoData />
      )}

      <ReportModaContent
        isMobile={isMobile}
        isOpen={isOpen}
        closeModal={handleCloseModal}
        resportData={resportData}
        details={details}
        handleResportSubmit={handleResportSubmit}
        setResportData={setResportData}
        isReportSubmited={isReportSubmited}
        onChange={(e) => setDetails(e.currentTarget.value)}
      />
    </div>
  ));

  const ReportModaContent = ({
    isOpen,
    isMobile,
    closeModal,
    isReportSubmited,
    resportData,
    setResportData,
    details,
    onChange,
    handleResportSubmit,
  }) => {
    if (!isOpen) return null;

    return (
      <div className="absolute top-0 bottom-0 left-0 w-full h-full flex justify-center items-end z-[1000]">
        <div className={`bg-cardBackground text-text p-5 rounded-t-xl w-full text-center shadow-lg ${isMobile ? 'max-w-[28rem]' : 'max-w-[22vw]'}`}>
          <div className="flex items-center justify-center w-full">
          <span className="block w-[89px] h-[6px] bg-[#E8E8E8] rounded-full cursor-pointer" onClick={closeModal}></span>
          </div>
          <button
            onClick={closeModal}
            className="relative right-[-5px] border-none bg-none cursor-pointer text-xl font-bold text-black w-full flex justify-end"
          >
            &times;
          </button>

          {!isReportSubmited ? (
            <>
              <Title className="text-left m-4 text-xl">Report Question</Title>
              <Stack className="space-y-4">
                <Radio.Group value={resportData} onChange={setResportData} size="1em">
                  {[
                    'Wrong category',
                    'Answer is incorrect',
                    'Incomplete question',
                    'Mistake in question',
                    'Mistake in options',
                    'Poorly formatted question',
                    'Poorly formatted options',
                    'Other',
                  ].map((item, index) => (
                    <Radio key={index} value={item} label={item} className="p-4" />
                  ))}
                </Radio.Group>

                {resportData === 'Other' && (
                  <>
                    <Text className="text-left">Other reason*</Text>
                    <Textarea
                      placeholder="Provide details about your issue here..."
                      value={details}
                      rows={4}
                      radius="sm"
                      className="mt-2.5 text-sm"
                      onChange={onChange}
                    />
                  </>
                )}
              </Stack>

              <Group position="center" mt="lg">
                  <Button
                    fullWidth
                    radius="xl"
                    size="md"
                    variant="filled"
                    color="dark"
                    disabled={!resportData}
                    className={`bg-background text-white font-medium ${!resportData && 'bg-gray-400'}`}
                    onClick={handleResportSubmit}
                  >
                    Submit Feedback
                  </Button>
                </Group>
            </>
          ) : (
            <>
              <Title className="m-4 text-xl">Report Submitted</Title>
              <Title className="m-4 text-md">Thank you! Your report has been submitted.</Title>
              <Lottie options={defaultOptions} height={200} width={200} />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex items-center w-full p-2.5 bg-[inherit] absolute top-0 z-[1000] h-[3.25em] text-[inherit]">
        <IconArrowLeft
          onClick={() => {
            navigate(-1);
            MixpanelEvent('navigation_back', { source_screen: 'news_pyq' });
            sendPostHogEvent('navigation_back', { source_screen: 'news_pyq' });
          }}
          height={24}
          width={24}
          className="cursor-pointer"
        />
        <h1 className="m-0 text-base text-[inherit] ml-4">Related UPSC PYQs</h1>
      </div>
      <div className="mt-[60px] w-full z-[999] relative">
        <div className="flex justify-center gap-6 border-b-2 border-border mb-4">
          <button
            style={{
              borderBottom: activeTab === 'mains' ? '1px solid blue' : '1px solid transparent',
            }}
            className={`text-xs w-1/2 py-2 ${activeTab === 'mains' ? 'text-text' : 'text-gray-500'}`}
            onClick={() => {
              setActiveTab('mains');
              setCurrentQuestionIndex(0);
              trackTabChange('mains');
            }}
          >
            Mains
          </button>
          <button
            style={{
              borderBottom: activeTab === 'prelims' ? '1px solid blue' : '1px solid transparent',
            }}
            className={`text-xs w-1/2 py-2 ${activeTab === 'prelims' ? 'text-text' : 'text-gray-500'}`}
            onClick={() => {
              setActiveTab('prelims');
              setCurrentQuestionIndex(0);
              trackTabChange('prelims');
            }}
          >
            Prelims
          </button>
        </div>

        {activeTab === 'mains' && <MainsContent />}
        {activeTab === 'prelims' && <PrelimsQuestionsTab questions={prelimsQuestions} colors={colors} userId={userId} />}
      </div>
    </>
  );
};

export default NewsPyqPage;
