import React, { useState } from 'react';
import { useTheme } from '../../context/themeProvider';
import { useLocation } from 'react-router-dom';
import TutorChat from './TutorChat';
import AskABook from './AskABook';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

export default function Chat() {
  const location = useLocation();
  const { colorScheme } = useTheme();
  const isNewsRoute = location.pathname.startsWith('/news');
  const [activeTab, setActiveTab] = useState(0);

  const tabLabels = {
    0: 'Ask a Book',
    1: 'Tutor Chat'
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
    const tabName = index === 0 ? 'ask_a_book' : 'tutor_chat';
    const eventData = {
      tab: tabName,
      default_tab: index === 0 ? true : false,
    };
    MixpanelEvent('chat_header_selection', eventData);
    sendPostHogEvent('chat_header_selection', eventData);
  };

  const getTabStyle = (tab) => ({
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: activeTab === tab ? '1px solid blue' : '1px solid transparent',
    padding: '10px',
    cursor: 'pointer',
    fontWeight: 'normal',
    background: 'none',
    outline: 'none',
    color: activeTab === tab 
      ? (colorScheme === 'dark' ? 'white' : 'black') 
      : 'gray',
  });

  return (
    <div className="absolute top-[60px] w-full z-[999] bg-background">
      <div className="flex justify-between items-center w-full">
        <div className="w-full">
          <div className="flex justify-evenly border-b border-[#2E2E2E]">
            {Object.entries(tabLabels).map(([value, label]) => (
              <div
                key={value}
                onClick={() => handleTabChange(parseInt(value))}
                style={getTabStyle(parseInt(value))}
                className="text-[15px] transition-all duration-300 cursor-pointer"
              >
                {label}
              </div>
            ))}
          </div>

          <div className='p-4'>
            {activeTab === 0 && <AskABook />}
            {activeTab === 1 && <TutorChat />}
          </div>
        </div>
      </div>
    </div>
  );
}