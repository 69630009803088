import React, { useState, useEffect } from 'react';
import { ArrowDown, ArrowDownDarKIcon, CloseIcon, HelpCircle, NoHistory, PracticeCustom } from '../../assets/images';
import { DateRangePicker } from 'react-date-range';
import BottomDrawerSelect from '../../molecules/BottomDrawerSelect';
import { useNavigate } from 'react-router-dom';
import PracticeInfoTips from './Infotip';
import FirstTimeUserPractice from './FirstTimeUser';
import { userStore } from '../../stores/userStore';
import { useTheme } from '../../context/themeProvider';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

export default function PracticeList() {
  const [selectedSubject, setSelectedSubject] = useState('csat');
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [practiceHistory, setPracticeHistory] = useState([]);
  const [filteredPracticeHistory, setFilteredPracticeHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [showInfoTips, setShowInfoTips] = useState(false);
  const userData = userStore((state) => state.userData);
  const { colors, colorScheme } = useTheme();
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const isMobile = window.innerWidth <= 400;

  const filterPracticeHistoryByDateRange = () => {
    const { startDate, endDate } = dateRange[0];

    // Make sure endDate includes the whole day (set time to 23:59:59)
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    if (startDate.getTime() === endDate.getTime()) {
      const filtered = practiceHistory.filter((practice) => {
        const practiceDate = new Date(practice.created_at);
        return practiceDate.toDateString() === startDate.toDateString();
      });

      setFilteredPracticeHistory(filtered);
    } else {
      const filtered = practiceHistory.filter((practice) => {
        const practiceDate = new Date(practice.created_at);
        return practiceDate >= startDate && practiceDate <= adjustedEndDate;
      });

      setFilteredPracticeHistory(filtered);
    }

    setIsFilterApplied(true);
    setPopoverOpened(false);
  };

  const handleDateRangeChange = (ranges) => {
    const { selection } = ranges;
    setDateRange([selection]);
  };

  const handleClearFilter = () => {
    setFilteredPracticeHistory(practiceHistory);
    setIsFilterApplied(false);
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  const handleCancel = () => {
    setPopoverOpened(false);
  };

  const formatDateRange = (startDate, endDate) => {
    const optionsWithoutYear = { day: '2-digit', month: 'short' };
    const optionsWithYear = { day: '2-digit', month: 'short', year: 'numeric' };

    const formattedStartDate = new Date(startDate).toLocaleDateString('en-US', optionsWithoutYear);
    const formattedEndDate = new Date(endDate).toLocaleDateString('en-US', optionsWithYear);

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  useEffect(() => {
    const fetchPracticeHistory = async () => {
      if (!userData || !userData.user) {
        console.error('User data is missing');
        setIsLoading(false);
        return;
      }

      try {
        const category = selectedSubject;
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/practice/history/?category=${category}&user=${userData.user}&render_type=${'text'}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch practice history');
        }

        const data = await response.json();
        setPracticeHistory(data);
        setFilteredPracticeHistory(data);
      } catch (error) {
        console.error('Error fetching practice history:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPracticeHistory();
  }, [userData, selectedSubject]);

  const subjects = [
    { value: 'csat', label: 'CSAT' },
    { value: 'history', label: 'History' },
    { value: 'geography', label: 'Geography' },
    { value: 'polity', label: 'Polity' },
    { value: 'current_affairs', label: 'Current Affairs' },
    { value: 'economy', label: 'Economy' },
    { value: 'science', label: 'Science' },
    { value: 'environment', label: 'Environment' },
  ];

  const handleInfoTip = () => {
    MixpanelEvent('info_tip', { type: 'practice_online', source: 'clicked' });
    sendPostHogEvent('info_tip', { type: 'practice_online', source: 'clicked' });
    setShowInfoTips(true);
  };

  const closeModal = () => {
    MixpanelEvent('screen_closed', { source_screen: 'practice_online', close_type: 'modal' });
    sendPostHogEvent('screen_closed', { source_screen: 'practice_online', close_type: 'modal' });
    setShowInfoTips(false);
  };

  if (isLoading) {
    return null;
  }

  const handleSubjectChange = (subject) => {
    console.log(subject);
    setSelectedSubject(subject);
    MixpanelEvent('practice_history_show', { mode: 'online', category: subject });
    sendPostHogEvent('practice_history_show', { mode: 'online', category: subject });
  };

  const isNewUser = localStorage.getItem('new_user') === 'true';
  const isFirstTimeUser = isNewUser && practiceHistory.length === 0;

  return (
    <div className="flex-1 overflow-hidden mt-[16%] font-poppins bg-background ">
      <div
        className={`
          relative 
          h-[calc(100dvh-64px)] overflow-y-auto
          w-full
          bg-background 
          box-border
          custom-scrollbar
          p-[15px]
        `}
        style={{ height: 'calc(100dvh - 146px)' }}
      >
        {isFirstTimeUser ? (
          <FirstTimeUserPractice />
        ) : (
          <>
            <div className="rounded-2xl overflow-hidden shadow-md mb-5">
            <div
              className="bg-cover bg-center h-[150px] w-full"
              style={{
                backgroundImage: `url(${PracticeCustom})`,
              }}
            ></div>
            <div className="flex justify-between items-center p-4 bg-cardBackground">
              <div className="flex items-center">
                <span className="font-bold text-text mr-1">Custom Topic</span>
                <img
                  src={HelpCircle}
                  alt="Help icon"
                  className="ml-2 w-5 h-5 cursor-pointer"
                  onClick={handleInfoTip}
                />
              </div>
              <button
                  onClick={() => navigate('/customize-practice')}
                  className={`border-none rounded-full px-5 py-2.5 cursor-pointer font-bold 
                    ${colorScheme === 'dark' ? 'bg-white text-black' : 'bg-black text-white'}`}
                >
                  Start Now
                  </button>

            </div>
          </div>

            <div>
              <p className="ml-1 mb-2 text-text">History</p>
              <div className="flex justify-between items-center w-full mb-3">
                <div className="">
                  <BottomDrawerSelect options={subjects} value={selectedSubject} onChange={handleSubjectChange} className="w-full" />
                </div>
                <div>
                  {isFilterApplied ? (
                    <button
                      className="bg-cardBackground border border-border text-text px-3 py-2 rounded-md flex items-center"
                      onClick={handleClearFilter}
                    >
                      {dateRange[0].startDate.getTime() === dateRange[0].endDate.getTime()
                        ? new Date(dateRange[0].startDate).toLocaleDateString('en-US', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                          })
                        : formatDateRange(dateRange[0].startDate, dateRange[0].endDate)}
                      <img src={CloseIcon} alt="Close Filter" className="w-4 h-4 ml-2" />
                    </button>
                  ) : (
                    <div>
                      <button
                        onClick={() => setPopoverOpened((o) => !o)}
                        className="bg-cardBackground border border-border text-text px-3 py-2 rounded-md flex items-center"
                      >
                        Sort by Date
                        <img src={colorScheme === 'dark' ? ArrowDownDarKIcon : ArrowDown} alt="" className="ml-2 w-4 h-4" />
                      </button>
                      {popoverOpened && (
                        <div className="absolute right-[1%] z-50 mt-2 bg-cardBackground border border-border rounded-md shadow-lg">
                          <DateRangePicker
                            ranges={dateRange}
                            onChange={handleDateRangeChange}
                            moveRangeOnFirstSelection={false}
                            showMonthAndYearPickers
                            maxDate={new Date()}
                            rangeColors={[colors.coloredButton]}
                          />
                          <div className="flex justify-between p-2">
                            <button onClick={handleCancel} className="px-3 py-1 text-text bg-transparent border border-border rounded-md">
                              Cancel
                            </button>
                            <button onClick={filterPracticeHistoryByDateRange} className="px-3 py-1 text-white bg-black rounded-md">
                              Select Range
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {filteredPracticeHistory.length > 0 ? (
                <div>
                  {filteredPracticeHistory.map((practice, index) => (
                    <div
                      key={practice.id}
                      className="bg-cardBackground rounded-lg border border-border shadow-md p-4 mb-4 cursor-pointer"
                      onClick={() =>
                        navigate('/review', {
                          state: {
                            practice: {
                              category: practice.category,
                              history_data: practice.history_data,
                            },
                          },
                        })
                      }
                    >
                      <div className="flex justify-between items-center">
                        <h3 className="font-semibold text-text">PRACTICE #{index + 1}</h3>
                        <div className="w-6 h-6">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="text-gray-400">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                          </svg>
                        </div>
                      </div>

                      <div className="flex items-center mt-2">
                        <svg className="w-4 h-4 mr-2 text-blue-500" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p className="text-subtleText">PYQ only</p>
                      </div>

                      <div className="flex items-center mt-2">
                        <svg className="w-4 h-4 mr-2 text-blue-500" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                          />
                        </svg>
                        <p className="text-text">
                          {practice.history_data.filter((q) => q.user_option === q.correct_answer).length} / {practice.history_data.length} Correct
                          answers
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="mt-16 flex justify-center flex-col items-center">
                  <img src={NoHistory} className="w-36 h-36" alt="No history" />
                  <div className="flex flex-col text-center">
                    <h3 className="m-0 p-0 text-text">No history found</h3>
                    <h4 className="text-subtleText mt-1">You've not had any practice for the selected topic & date</h4>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <PracticeInfoTips opened={showInfoTips} onClose={closeModal} />
      </div>
    </div>
  );
}
