import React, { useEffect, useRef, useState } from 'react';
import { TextInput, Title, Group, Paper, Image } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { InfoCircle, PyqSearchLogo, PyqSearchLogoDark, SearchNormal, SendIcon } from '../../assets/images';
import InfoTips from './InfoTips';
import { CustomDivider } from '../../molecules/OR';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useTheme } from '../../context/themeProvider';

const AutoScrollLists = () => {
  const navigate = useNavigate();
  const { colorScheme } = useTheme();
  const [showInfoTips, setShowInfoTips] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const list1Ref = useRef(null);
  const list2Ref = useRef(null);
  const list3Ref = useRef(null);

  const wordList = [
    'Space exploration milestones',
    'Globalization effects',
    'Effects of colonial rule',
    'Innovations in e-governance',
    'Water scarcity solutions',
    'Grassroot democratic reforms',
    "Indigenous peoples' rights",
    'Transboundary water conflict',
    'Sustainable agricultural innovations',
    'Enhancing public health systems',
    'International Relations',
    'Ancient Civilizations',
    'Mitigating soil degradation',
    'Revitalizing rural economies',
    'Advancing gender equality',
  ];

  // Splitting the word list into 3 different groups
  const list1Words = wordList.slice(0, 5);
  const list2Words = wordList.slice(5, 10);
  const list3Words = wordList.slice(10, 15);

  useEffect(() => {
    const scrollSpeed1 = 1;
    const scrollSpeed2 = 1;
    const scrollSpeed3 = 1;

    const scrollHorizontally = (list, speed) => {
      if (list) {
        list.scrollLeft += speed;
        if (list.scrollLeft >= list.scrollWidth - list.clientWidth) {
          list.scrollLeft = 0; // Reset to the beginning for infinite scrolling
        }
      }
    };

    const interval1 = setInterval(() => scrollHorizontally(list1Ref.current, scrollSpeed1), 50);
    const interval2 = setInterval(() => scrollHorizontally(list2Ref.current, scrollSpeed2), 50);
    const interval3 = setInterval(() => scrollHorizontally(list3Ref.current, scrollSpeed3), 50);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, []);

  const handleWordClick = (word) => {
    navigate('/pyqSearch', { state: { selectedWord: word } });
  };

  const renderWordItems = (listRef, words) => (
    <Paper
      ref={listRef}
      style={{
        overflowX: 'scroll',
        display: 'flex',
        whiteSpace: 'nowrap',
        backgroundColor: 'transparent',
        gap: 2,
        flexWrap: 'nowrap',
        width: '100%',
      }}
    >
      {/* Render words and clone them for infinite scrolling */}
      {[...words, ...words].map((item, index) => (
        <div
          key={index}
          className={`
            mr-[16px] 
            ${colorScheme === 'dark' ? 'bg-[#1B3260] text-white' : 'bg-[#EBF3FF] text-[#3C45CD]'}
            p-[8px_12px_8px_16px] 
            rounded-[25px] 
            flex items-center 
            whitespace-nowrap 
            font-[500] 
            text-[12.5px] 
            cursor-pointer 
            font-poppins
          `}
          onClick={() => handleWordClick(item)}
        >
          {item}
          <img src={SendIcon} alt="Send Icon" className="ml-[4px] mr-[8px] w-[16px] h-[16px]" />
        </div>
      ))}
    </Paper>
  );

  const LowerSection = () => (
    <>
      <CustomDivider className="my-8" />
      <h3 className="text-text text-[16px] font-[600] mb-5 font-poppins">Try any of the topics below</h3>

      <Group position="apart" noWrap className="flex flex-col gap-0 overflow-x-auto">
        {renderWordItems(list1Ref, list1Words)}
        {renderWordItems(list2Ref, list2Words)}
        {renderWordItems(list3Ref, list3Words)}
      </Group>
    </>
  );

  return (
    <div
      className="absolute fixed top-0 left-0 right-0 bottom-0 bg-background overflow-y-auto scrollbar-hidden"
      style={{
        WebkitOverflowScrolling: 'touch',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <div
        className={`mx-auto pt-[50px] pb-[100px] scrollbar-hidden text-center 
        ${windowDimensions.width <= 768 ? 'h-screen overflow-auto' : 'min-h-screen'} 
        ${windowDimensions.width <= 768 ? 'max-w-full' : 'max-w-[28rem]'} 
        shadow-[0px_19px_38px_rgba(0,0,0,0.3),0px_15px_12px_rgba(0,0,0,0.22)]`}
      >
        <Image src={colorScheme === 'dark' ? PyqSearchLogoDark : PyqSearchLogo} alt="Header Image" width={200} className="mx-auto mb-[8%]" />
    
        {/* Title and Info Icon */}
        <div className="relative flex justify-center items-center gap-4 mb-5">
          <h2 className="text-text text-[20px] font-semibold font-poppins">Meaning-Based PYQ search</h2>
          <img src={InfoCircle} className="cursor-pointer text-[#6374FA]" onClick={() => setShowInfoTips(true)} />
        </div>
        {/* Search Input */}
        <TextInput
          leftSectionPointerEvents="none"
          onClick={() => navigate('/pyqSearch')}
          leftSection={
            <div className="bg-[#6374FA] rounded-full p-2 flex justify-center items-center">
              <img src={SearchNormal} className="w-6" />
            </div>
          }
          size="lg"
          styles={{
            input: {
              border: `1px solid ${colorScheme === 'dark' ? '#92A1FF' : '#6374FA'}`,
              borderRadius: '25px',
              color: '#2C2E3F',
              backgroundColor: `${colorScheme === 'dark' ? '#303034' : '#F7F8FC'}`,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              outline: `8px solid ${colorScheme === 'dark' ? '#3C517A' : '#E1EBFF'}`,
              width: '100%',
              fontFamily: 'Poppins',
            },
          }}
          style={{
            margin: '2em auto',
            width: '90%',
          }}
          placeholder="Search PYQs here..."
        />
        {/* Lower Section */}
        <LowerSection />
        {/* Info Tips Modal */}
        {showInfoTips && (
          <InfoTips
            onClose={() => {
              setShowInfoTips(false);
              MixpanelEvent('screen_closed', {
                source_screen: 'Meaning-Based PYQ Search',
                close_type: 'modal',
              });
              sendPostHogEvent('screen_closed', {
                source_screen: 'Meaning-Based PYQ Search',
                close_type: 'modal',
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AutoScrollLists;
