import React, { useState, useEffect } from 'react';
import { HelpCircle, Add, AskBookImg2, NcertBook1 } from '../../../assets/images';
import { useTheme } from '../../../context/themeProvider';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Image, Text } from '@mantine/core';
import InfoTips from './InfoTips';
import BookChatHistoryCard from './BookChatHistoryCard';
import { userStore } from '../../../stores/userStore';

const AskABook = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const [chatHistory, setChatHistory] = useState([]);
  const [showInfoTips, setShowInfoTips] = useState(false);
  const userData = userStore((state) => state.userData);
  const userId = userData?.user || null;

  useEffect(() => {
    const fetchChatHistory = async () => {
      if (!userId) return;
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/bookchat/?user=${userId}`);
        const data = await response.json();
        setChatHistory(data);
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
    };

    fetchChatHistory();
  }, [userId]);

  const BookQueryCard = ({ title, img, isNew, bookType }) => {
    return (
      <div className="bg-cardBackground rounded-xl shadow-md relative mb-6 w-[96%]">
        <div className="relative">
          <img src={img} alt={title} className="h-[150px] w-full rounded-xl" />
          {isNew && (
            <>
              <div className="absolute top-0 -right-[0.8em] bg-[#EBF3FF] text-[#6374FA] 
                px-[0.8em] py-[0.3em] text-[0.8em] font-medium font-poppins z-10 
                border border-[#6374FA80] rounded-tr-none rounded-md">
                New
              </div>
              <div className="absolute top-[1.35em] -right-[0.7em] w-[3%]">
                <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 8.5L10 0H0V8.5Z" fill="#6374FA" />
                </svg>
              </div>
            </>
          )}
        </div>
        <div className="flex justify-between items-center px-[0.9em] py-[1.3em]">
          <div className="font-poppins font-medium text-text text-base">
            {title}
          </div>
          <button
            onClick={() => navigate('/book-chat/select-book', { state: { bookFlowType: bookType } })}
            className="h-[3em] w-[8em] border-border bg-text rounded-full cursor-pointer"
          >
            <div className="flex items-center justify-center space-x-[0.3em]">
              <span className="text-background text-[1.2em] mb-[4px]">+</span>
              <div className="text-background font-poppins font-medium text-[1em]">
                New Query
              </div>
            </div>
          </button>


        </div>
      </div>
    );
  };

  return (
    <>
      {chatHistory.length === 0 ? (
        <div className="flex justify-center items-center">
          <div className="h-[75dvh] overflow-auto no-scrollbar">
            <Flex align="center" justify="center" mb={'0.5em'}>
              <Text 
                fw={500} 
                fz={'1.5em'} 
                className="font-poppins text-[#070519]"
              >
                Ask a Book
              </Text>
              <Image 
                ml={'0.3em'} 
                src={HelpCircle} 
                alt="Help icon" 
                w={'2.5em'} 
                className="cursor-pointer" 
                onClick={() => setShowInfoTips(true)} 
              />
            </Flex>
            <Text 
              fw={400} 
              fz={'1em'} 
              mb={'1.5em'} 
              className="font-poppins text-[#747474] text-center"
            >
              Get reliable answers from top UPSC and NCERT books.
            </Text>
            <BookQueryCard title="Ask a NCERT Book" img={NcertBook1} isNew={true} bookType={'ncert'} />
            <BookQueryCard title="Ask a UPSC Book" img={AskBookImg2} isNew={false} bookType={'upsc'} />
            <div className="h-[80px]" />
          </div>
        </div>
      ) : (
        <div>
          <BookChatHistoryCard 
            chatHistorys={chatHistory} 
            handleCallBack={() => setShowInfoTips(true)} 
          />
        </div>
      )}
      {showInfoTips && (
        <InfoTips
          onClose={() => {
            setShowInfoTips(false);
          }}
        />
      )}
    </>
  );
};

export default AskABook;