import React from 'react';
import PropTypes from 'prop-types';
import { IconArrowRight, IconCalendar } from '@tabler/icons-react';
import { BlueCrown, CrownGeneral, RedCrown, Versus } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useTheme } from '../../context/themeProvider';

const DuelHistoryCard = ({ duel, cardIndex }) => {
  const { colorScheme } = useTheme();
  const {
    user_info: { name: userName, elo: userElo },
    opponent_info: { name: opponentName, elo: opponentElo },
    eloScore,
    user_correct_answer: correctAnswer,
    opponent_correct_answer: opponentCorrectAnswer,
    quizScore,
  } = duel;

  const eloChange = eloScore - userElo;
  const resultStatus = correctAnswer > opponentCorrectAnswer ? 'VICTORY' : correctAnswer < opponentCorrectAnswer ? 'DEFEAT' : 'DRAW';

  const getHeaderStyles = (status) => {
    switch (status) {
      case 'VICTORY':
        return { bgColor: 'bg-[#61BD85]', textColor: 'text-white', text: 'VICTORY' };
      case 'DEFEAT':
        return { bgColor: 'bg-[#FFEDED]', textColor: 'text-[#D95353]', text: 'DEFEAT' };
      case 'DRAW':
        return { bgColor: 'bg-[#d5ddf9]', textColor: 'text-[#747474]', text: 'DRAW' };
      default:
        return { bgColor: 'bg-[#61BD85]', textColor: 'text-white', text: 'VICTORY' };
    }
  };

  const getScoreChangeDetails = (status) => {
    switch (status) {
      case 'VICTORY':
        return { sign: '+', textColor: 'text-[#38915B]' };
      case 'DRAW':
        return { sign: '+', textColor: 'text-[#4A90E2]' };
      case 'DEFEAT':
        return { sign: '-', textColor: 'text-[#D95353]' };
      default:
        return { sign: '+', textColor: 'text-[#38915B]' };
    }
  };

  const headerStyles = getHeaderStyles(resultStatus);
  const scoreChangeDetails = getScoreChangeDetails(resultStatus);
  const getInitials = (name) => name.charAt(0).toUpperCase();
  const navigate = useNavigate();

  const handleTabClick = () => {
    MixpanelEvent('duel_review_clicked', {
      category: duel.category,
      source: 'duel_homepage',
      mode: 'online',
    });
    sendPostHogEvent('duel_review_clicked', {
      category: duel.category,
      source: 'duel_homepage',
      mode: 'online',
    });
    navigate('/duel-review', { state: { duel } });
  };

  return (
    <div 
    onClick={handleTabClick}
    className={`
      flex flex-col rounded-2xl w-full mt-4 mb-4 cursor-pointer bg-background
      ${colorScheme === 'dark' ? 'border border-[#464646]' : 'border border-gray-200'}
    `}
  >
      {/* Header */}
      <div className={`flex justify-between items-center p-2 px-4 rounded-t-2xl ${headerStyles.bgColor}`}>
        <div className={`font-bold text-sm ${headerStyles.textColor}`}>
          {headerStyles.text}
        </div>
        <div className={`flex items-center gap-1 text-sm ${headerStyles.textColor}`}>
          <IconCalendar className="w-4 h-4" />
          <span>{correctAnswer}</span> - <span>{opponentCorrectAnswer}</span>
          <IconCalendar className="w-4 h-4" />
        </div>
        <div className={`font-bold text-sm ${headerStyles.textColor}`}>
          Duel #{cardIndex}
        </div>
      </div>

      {/* Players Container */}
      <div className="flex justify-between items-center p-4">
        {/* Player 1 */}
        <div className="flex items-center gap-2">
          <div className="w-8 h-8 rounded bg-[#E8F1FF] flex justify-center items-center">
            <span className="text-black text-base font-bold">
              {getInitials(userName)}
            </span>
          </div>
          <div>
            <div className="text-sm text-text font-bold">
              {userName.length > 10 ? `${userName.substring(0, 10)}...` : userName}
            </div>
            <div className="flex items-center gap-1 text-sm">
              <img src={BlueCrown} className="w-5 h-5" alt="Blue Crown" />
              <span className='text-text'>{userElo}</span>
            </div>
          </div>
        </div>

        {/* VS Icon */}
        <img src={Versus} alt="Versus" className="w-8 h-8" />

        {/* Player 2 */}
        <div className="flex items-center gap-2">
          <div>
            <div className="text-sm text-text font-bold text-right">
              {opponentName.length > 10 ? `${opponentName.substring(0, 10)}...` : opponentName}
            </div>
            <div className="flex items-center gap-1 justify-end text-sm">
              <span className='text-text'>{opponentElo}</span>
              <img src={RedCrown} className="w-5 h-5" alt="Red Crown" />
            </div>
          </div>
          <div className="w-8 h-8 rounded bg-[#E8F1FF] flex justify-center items-center">
            <span className="text-black text-base font-bold">
              {getInitials(opponentName)}
            </span>
          </div>
        </div>
      </div>

      {/* Bottom Container */}
      <div className="flex justify-between items-center p-2 px-4">
        <div className={`flex items-center gap-1 font-bold text-base ${scoreChangeDetails.textColor}`}>
          {scoreChangeDetails.sign}
          <img src={CrownGeneral} className="w-5 h-5" alt="Crown General" />
          <span>{Math.abs(quizScore)}</span>
        </div>
        <div className="flex items-center">
          <IconArrowRight className="text-[#6374FA] w-6 h-6" />
        </div>
      </div>
    </div>
  );
};

DuelHistoryCard.propTypes = {
  duel: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    user_info: PropTypes.shape({
      name: PropTypes.string.isRequired,
      elo: PropTypes.number.isRequired,
    }).isRequired,
    opponent_info: PropTypes.shape({
      name: PropTypes.string.isRequired,
      elo: PropTypes.number.isRequired,
    }).isRequired,
    eloScore: PropTypes.number.isRequired,
    user_correct_answer: PropTypes.number.isRequired,
    opponent_correct_answer: PropTypes.number.isRequired,
    quizScore: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
  }).isRequired,
  cardIndex: PropTypes.number.isRequired,
};

export default DuelHistoryCard;