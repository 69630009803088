import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart } from 'recharts';
import { DuelNoData, totalStats, wonStats, ratingStats, Question, Correct, HoursGlass } from '../../assets/images';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useTheme } from '../../context/themeProvider';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        let value = payload[0].value !== undefined ? payload[0].value : '-';
        const dataKey = payload[0].dataKey;

        let valueLabel;
        switch (dataKey) {
            case 'elo': valueLabel = 'Rating'; break;
            case 'accuracy': valueLabel = 'Accuracy'; break;
            case 'average_time': valueLabel = 'Avg Time'; break;
            case 'number_of_questions': valueLabel = 'Questions'; break;
            default: valueLabel = dataKey;
        }

        const date = new Date(payload[0].payload.date);
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        });

        return (
            <div className="bg-[rgba(0,0,0,0.8)] p-2 rounded-md border border-gray-300 text-white text-xs min-w-[120px] text-center flex items-center gap-2">
                <p className="font-medium">{value}</p>
                <p className="text-2xs">{formattedDate}</p>
            </div>
        );
    }
    return null;
};


export default function MyStatsPage({ selectedSubject, userId }) {
    const { colorScheme } = useTheme();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('All');
    const [statsData, setStatsData] = useState({
        total_questions_done: 0,
        correct_answers: 0,
        average_time_per_question: 0,
        elo_graph_stats: [],
        accuracy_stats: [],
        average_time_stats: [],
        total_duels: 0,
        duels_won: 0,
        rating: 0,
        top: 0,
    });

    const handleTabClick = (tab) => {
        const isDefaultSelection = tab === 'All';
        const hasGraph = statsData.total_questions_done > 5;
        setActiveTab(tab);

        MixpanelEvent('duel_stats_show', {
            tab: tab,
            source: hasGraph ? 'graph' : 'no_graph',
            category: selectedSubject,
            default_selection: isDefaultSelection,
        });

        sendPostHogEvent('duel_stats_show', {
            tab: tab,
            source: hasGraph ? 'graph' : 'no_graph',
            category: selectedSubject,
            default_selection: isDefaultSelection,
        });
    };


    useEffect(() => {
        const fetchUserStats = async () => {
            try {
                let day = 'all';
                if (activeTab === '1m') { day = 'month'; }
                else if (activeTab === '1w') { day = 'week'; }
                else if (activeTab === '1d') { day = 'day'; }

                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/track/user-stats/?profile=${userId}&category=${selectedSubject}&date=${day}`
                );
                const data = await response.json();
                setStatsData(data);

            } catch (error) {
                console.error('Error fetching user stats:', error);
            }
        };

        fetchUserStats();
    }, [userId, selectedSubject, activeTab]);

    const handleClick = () => {
        navigate('/duel');
    };

    const sortDataByDate = (data) => {
        const dateMap = data.reduce((acc, curr) => {
            const dateStr = new Date(curr.date).toISOString().split('T')[0];
            if (!acc[dateStr]) {
                acc[dateStr] = { ...curr };
            } else {
                acc[dateStr] = {
                    ...curr,
                    elo: (acc[dateStr].elo + curr.elo) / 2,
                    accuracy: (acc[dateStr].accuracy + curr.accuracy) / 2,
                    average_time: (acc[dateStr].average_time + curr.average_time) / 2,
                    number_of_questions: acc[dateStr].number_of_questions + curr.number_of_questions,
                };
            }
            return acc;
        }, {});
        return Object.values(dateMap).sort((a, b) => new Date(a.date) - new Date(b.date));
    };


    const renderChart = (data, dataKey, stroke, fill, XAxisTitle, yAxisTitle, domain, tickCount) => {
        const validData = data.filter(item => item && item.date && typeof item[dataKey] !== 'undefined' && !isNaN(item[dataKey]));
        return (
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={validData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                    <XAxis
                        stroke="#888"
                        dataKey="date"  // Add dataKey here
                        tickFormatter={(tick) => new Date(tick).toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })}
                        label={{
                            value: XAxisTitle,
                            position: 'bottom',
                            offset: -10,
                            style: { textAnchor: 'middle', fill: colorScheme === 'dark' ? 'white' : 'black' },
                        }}
                        tick={false}

                    />
                    <YAxis
                        stroke="#888"
                        label={{
                            value: yAxisTitle,
                            angle: -90,
                            position: 'insideLeft',
                            style: { textAnchor: 'middle', fill: colorScheme === 'dark' ? 'white' : 'black'
                             },
                                                    }}
                        domain={domain}
                        ticks={Array.from({ length: tickCount }, (_, i) => domain[0] + i * ((domain[1] - domain[0]) / (tickCount - 1)))}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Area type="monotone" dataKey={dataKey} stroke={stroke} fill={fill} strokeWidth={2} />
                </AreaChart>
            </ResponsiveContainer>
        );
    };

    const renderChartOrNoData = (data, title, description, dataKey, stroke, fillColor, XAxisTitle, yAxisTitle, domain, tickCount) => (

        <div className="border border-border rounded-md p-5 mb-5 bg-background">
            <div className="font-bold text-lg mb-1 text-text">{title}</div>
            <div className="text-sm text-text mb-5">{description}</div>
            {statsData.total_questions_done > 5 ? (
                <div className="h-[314px]">
                    {renderChart(data, dataKey, stroke, fillColor, XAxisTitle, yAxisTitle, domain, tickCount)}
                </div>
            ) : (
                <div className="text-center">
                    <img src={DuelNoData} alt="Chart icon" className="w-[100px] h-[100px] mx-auto mb-5" />
                    <p className="font-bold text-sm">NO DATA TO SHOW</p>
                    <p className="text-gray-500 text-xs">You've not had any duels to view your {title}.</p>
                    <button onClick={handleClick} className="bg-white text-black border border-black px-4 py-2 rounded-full text-sm mt-3 w-full">
                        Play Duel ▶
                    </button>
                </div>
            )}
        </div>
    );



    return (
        <div className="w-[90%] mx-auto my-5 overflow-y-auto scrollbar-hidden h-[80vh] mb-8 text-gray-800 font-poppins">
            <div className="border border-border rounded-md p-5 mb-5 bg-background">
                <div className="font-bold text-text text-lg mb-1">Overall Duel Stats</div>
                <div className="text-sm mb-5 text-gray-500">Performance statistics for your duels, aiding progress tracking and goal setting.</div>

                <div className="flex justify-between mb-5">
                    <div className="text-center w-1/3">
                        <div className="w-14 h-14 flex items-center justify-center rounded-full bg-[#e6f2ff] mx-auto mb-2">
                            <img src={ratingStats} alt="Rating" />
                        </div>
                        <div className="font-bold text-text text-base">{statsData.rating}</div>
                        <div className="text-xs font-bold text-gray-600 mb-1">RATING</div>
                    </div>
                    <div className="text-center w-1/3">
                        <div className="w-14 h-14 flex items-center justify-center rounded-full bg-[#e6ffe6] mx-auto mb-2">
                            <img src={wonStats} alt="Won" />
                        </div>
                        <div className="font-bold text-text text-base">{statsData.duels_won}</div>
                        <div className="text-xs font-bold text-gray-600 mb-1">WON</div>
                    </div>
                    <div className="text-center w-1/3">
                        <div className="w-14 h-14 flex items-center justify-center rounded-full bg-[#fff2e6] mx-auto mb-2">
                            <img src={totalStats} alt="Total" />
                        </div>
                        <div className="font-bold text-text text-base">{statsData.total_duels}</div>
                        <div className="text-xs font-bold text-gray-600 mb-1">TOTAL</div>
                    </div>
                </div>

                <div>
                    <div className="flex items-center text-gray-500 mb-2 text-sm">
                        <span className="mr-2"> <img src={Question} alt="question" /> </span>
                        Questions Answered <span className="ml-auto text-text">{statsData.total_questions_done}</span>
                    </div>
                    <div className="flex items-center text-gray-500 mb-2 text-sm">
                        <span className="mr-2"> <img src={Correct} alt="correct" /></span>
                        Correct Answers <span className="ml-auto text-text">{statsData.correct_answers}</span>
                    </div>
                    <div className="flex items-center text-gray-500 text-sm">
                        <span className="mr-2"> <img src={HoursGlass} alt="hours glass" /></span>
                        Avg. Time per Question
                        <span className="ml-auto text-text">{statsData.average_time_per_question.toFixed(2)}sec</span>
                    </div>
                </div>
            </div>




            <div className="flex justify-evenly bg-tabContinerBackground p-2 mb-5 rounded-[200px]">
                {['All', '1m', '1w', '1d'].map((tab) => (
                    <button
                        key={tab}
                        onClick={() => handleTabClick(tab)}
                        className={`px-8 py-2 text-sm text-center cursor-pointer rounded-full
                            ${activeTab === tab ? 'bg-tabButtonBackground rounded-[20px] text-text' : 'bg-transparent text-[#64748B]'}`}
                    >
                        {tab}
                    </button>
                ))}
            </div>


           
            {renderChartOrNoData(
                sortDataByDate(statsData.elo_graph_stats),
                'Duel Rating Stats',
                'Explore your Rating progression over time.',
                'elo',
                '#6374FA',
                'rgba(136, 132, 216, 0.1)',
                'date',
                'Rating',
                [1000, 2000],
                11
            )}

            {renderChartOrNoData(
                sortDataByDate(statsData.accuracy_stats),
                'Total Questions Attempted',
                'Visualizing the amount of questions you attempted in the selected time duration.',
                'number_of_questions',
                '#82ca9d',
                'rgba(130, 202, 157, 0.1)',
                'date',
                'No. of Questions',
                [0, 20],
                11
            )}

            {renderChartOrNoData(
                sortDataByDate(statsData.accuracy_stats),
                'Accuracy Stats',
                'Measure accuracy of your duel answers.',
                'accuracy',
                '#82ca9d',
                'rgba(130, 202, 157, 0.1)',
                'date',
                'Accuracy %',
                [0, 100],
                11
            )}

            {renderChartOrNoData(
                sortDataByDate(statsData.average_time_stats),
                'Average Answer Time (sec)',
                'Measure the avg. time taken by you to answer the duel questions.',
                'average_time',
                '#82ca9d',
                'rgba(130, 202, 157, 0.1)',
                'date',
                'Avg. Time Taken per Question (sec)',
                [0, 20],
                11
            )}
        </div>
    );
}