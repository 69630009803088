import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../constants/iconData';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';
import { useTheme } from '../../../context/themeProvider';
import { ArrowRightDarkIcon, ArrowRightFilled } from '../../../assets/images';

const subjects = [
  { value: 'csat', label: 'CSAT' },
  { value: 'history', label: 'History' },
  { value: 'geography', label: 'Geography' },
  { value: 'polity', label: 'Polity' },
  { value: 'current_affairs', label: 'Current Affairs' },
  { value: 'economy', label: 'Economy' },
  { value: 'science', label: 'Science' },
  { value: 'environment', label: 'Environment' },
];

const MatchGame = () => {
  const navigate = useNavigate();
  const [selectedTopic, setSelectedTopic] = useState('');
  const isResponsiveMobiles = window.innerHeight <= 900 && window.innerWidth <= 400;
  const { colors, colorScheme } = useTheme();

  const handleBeginDuel = () => {
    MixpanelEvent('initiate_duel', { category: selectedTopic, mode: 'online' });
    sendPostHogEvent('initiate_duel', { category: selectedTopic, mode: 'online' });
    navigate('/matching', { state: { topic: selectedTopic } });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className={`flex flex-col ${isResponsiveMobiles ? 'h-[94vh] p-6' : 'h-screen p-4 bg-background'}`}>
      <div className={`text-center ${isResponsiveMobiles ? 'my-2.5' : 'my-4'}`}>
        <h5 className="text-text m-0">ONLINE DUEL</h5>
        <h2 className="text-2xl font-bold m-0 text-text">Select Topic</h2>
      </div>

      {/* Scrollable container */}
      <div className="flex-grow mb-4 overflow-y-auto custom-scrollbar">
        <div className="flex flex-col gap-2">
          {subjects.map((option) => (
            <label
              key={option.value}
              className={`flex items-center border border-borderColor justify-between p-3 rounded-lg cursor-pointer transition-all duration-200
              ${
                selectedTopic === option.value
                  ? colorScheme === 'dark'
                    ? 'bg-[#1B3260]'
                    : 'bg-[#EBF3FF]'
                  : colorScheme === 'dark'
                    ? 'bg-background'
                    : 'bg-cardBackground'
              }`}
            >
              <div className="flex items-center">
                {icons[option.value] && <div className={`mr-2 ${selectedTopic === option.value ? 'text-white' : ''}`}>{icons[option.value]}</div>}
                <span className="text-base text-text">{option.label}</span>
              </div>
              <input
                type="radio"
                name="topic"
                value={option.value}
                checked={selectedTopic === option.value}
                onChange={() => setSelectedTopic(option.value)}
                className="scale-150"
              />
            </label>
          ))}
        </div>
      </div>

      <div className="flex justify-between mt-auto">
        <button
          onClick={handleGoBack}
          className={`px-6 py-3 rounded-full cursor-pointer
            ${colorScheme === 'dark' ? 'bg-background text-white border border-white' : 'bg-white text-black border border-black'}`}
        >
          Go back
        </button>
        <button
          onClick={handleBeginDuel}
          disabled={!selectedTopic}
          className={`px-6 py-3 rounded-full cursor-pointer flex items-center
            ${colorScheme === 'dark' ? 'bg-white text-black' : 'bg-black text-white'}
            ${!selectedTopic && 'opacity-50 cursor-not-allowed'}`}
        >
          Begin Duel
          <img src={colorScheme === 'dark' ? ArrowRightFilled : ArrowRightDarkIcon} alt="Previous" className="w-5 ml-2" />
        </button>
      </div>
    </div>
  );
};

export default MatchGame;
