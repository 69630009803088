import React, { useEffect, useState } from 'react';
import { Flex, Text } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, CrownGeneral, DuelShare, Share, DuelOnlineResultVs, ArrowLeftDark } from '../../../assets/images';
import { IconCheck, IconX, IconPlus, IconMinus } from '@tabler/icons-react';
import { callPOSTAPI } from '../../../utils/NetworkUtil';
import branch from 'branch-sdk';
import { Loader } from '@mantine/core';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';
import { useTheme } from '../../../context/themeProvider';

const DuelResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    hasExited,
    duel = {},
    botElo = {},
    userProfile = {},
    botProfile = {},
    userEloChange = 0,
    botEloChange = 0,
    userAnswerStatus = [],
    opponentAnswerStatus = [],
  } = location.state || {};
  const { colors, colorScheme } = useTheme();

  const userCorrectAnswers = userAnswerStatus.filter((answer) => answer === true).length;
  const opponentCorrectAnswers = opponentAnswerStatus.filter((answer) => answer === true).length;

  const opponentData = {
    name: botProfile.name || duel.opponent_info?.name || '',
    elo: botProfile.elo || duel.opponent_info?.elo || 0,
    photo: botProfile.photo || null,
  };
  const isUserWinner = userCorrectAnswers > opponentCorrectAnswers;
  const isTie = userCorrectAnswers === opponentCorrectAnswers;
  const [loading, setLoading] = useState(false);
  const [showUnableRematch, setShowUnableRematch] = useState(false);
  const [shareImg, setShareImg] = useState('');

  const getInitial = (name) => {
    if (!name) return '-';
    return name.charAt(0).toUpperCase();
  };

  useEffect(() => {
    if (!hasExited && isUserWinner) {
      handleVictoryShare();
    }
  }, [hasExited, isUserWinner]);

  const handleVictoryShare = async () => {
    const payload = JSON.stringify({
      userName: userProfile.name,
      opponentUserName: duel.opponent_info?.name || '',
      userElo: userProfile.elo,
      opponentElo: duel.opponent_info?.elo || 0,
      category: duel.category,
    });

    try {
      await callPOSTAPI(
        `${process.env.REACT_APP_BASE_URL}/api/create-svg-duel/`,
        payload,
        (resp) => {
          console.log(resp.data.link);
          setShareImg(resp.data.link);
        },
        (err) => {
          console.error('API error:', err);
        }
      );
    } catch (error) {
      console.error('Error posting duel data:', error);
    }
  };

  const buildDeeplink = async () => {
    MixpanelEvent('duel_share_clicked', {});
    sendPostHogEvent('duel_share_clicked', {});
    const domain = window.location.origin;
    const linkData = {
      channel: 'web',
      feature: 'sharing',
      data: {
        $desktop_url: domain,
        $og_redirect: domain,
        $fallback_url: domain,
        $og_image_url: shareImg,
        navigate_to: 'duel/',
      },
    };

    branch.link(linkData, (err, link) => {
      if (err) {
        console.error('Error creating link:', err);
      } else {
        if (link) {
          shareImage(link);
        }
      }
    });
  };

  const shareImage = async (link) => {
    if (link && navigator.canShare) {
      try {
        await navigator.share({
          title: 'Learn as you play—Install PadhAI app.',
          text: `Feel like you're wasting too much time playing mobile games? Try the Online Duels instead where you can play against UPSC aspirants from all over India. Play, compete, and learn.`,
          url: link,
        });
        console.log('Shared successfully');
      } catch (error) {
        console.error('Sharing failed:', error);
      }
    } else {
      console.error('Sharing not supported or link is missing');
    }
  };

  const rematch = async () => {
    setLoading(true);
    setShowUnableRematch(false);
    let payload = {};

    if (botProfile.name) {
      payload = {
        current_id: userProfile.user,
        category: duel.category,
        game_with_bot: true,
        bot_data: {
          bot_data: {
            name: botProfile.name,
            elo: botElo,
            id: botProfile.id,
          },
          render_type: 'text',
        },
      };
    } else {
      payload = {
        current_id: userProfile.user,
        friend_id: duel.opponentId,
        category: duel.category,
        game_with_bot: false,
      };
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/rematch/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.start_game && data.room) {
          const navigationData = {
            bot: data.bot_data
              ? {
                name: data.bot_data.bot_data.name,
                elo: data.bot_data.bot_data.elo,
                id: data.bot_data.bot_data.id,
                room_id: data.room,
              }
              : null,
            currentUser: {
              name: data.current_user.name,
              elo: data.current_user.elo,
              photo: data.current_user.photo,
              user: data.current_user.user,
            },
            topic: duel.category,
            room_id: data.room,
            opponent: !data.bot_data
              ? {
                name: duel.opponentName,
                elo: duel.opponentElo,
                id: duel.opponentId,
                room_id: data.room,
              }
              : null,
          };
          setTimeout(() => {
            setLoading(false);
            navigate('/duel-start', { state: navigationData });
          }, 3000);
        } else {
          setLoading(false);
          setShowUnableRematch(true);
        }
      } else {
        setLoading(false);
        setShowUnableRematch(true);
        console.error('Failed to create rematch:', response.statusText);
      }
    } catch (error) {
      setLoading(false);
      setShowUnableRematch(true);
      console.error('An error occurred while trying to start the rematch:', error);
    }
  };
  const handleTabClick = () => {
    console.log(duel.category, 'duel.category');
    MixpanelEvent('duel_review_clicked', {
      category: duel.category,
      source: 'post_game',
      mode: 'online',
    });
    sendPostHogEvent('duel_review_clicked', {
      category: duel.category,
      source: 'post_game',
    });
    navigate('/duel-review', { state: { duel } });
  };

  const UserCard = ({ user, isWinner, isTie, hasExited, score, answerStatus, colorScheme }) => {
    const cardBackground = hasExited 
      ? 'bg-cardBackground'
      : isTie 
      ? 'bg-cardBackground'
      : isWinner
      ? 'bg-[#61BD85]' 
      : 'bg-[#FFEDED]'; 
        
    const textColor = hasExited
      ? colorScheme === 'dark' ? 'text-white' : 'text-[#747474]'
      : isWinner
      ? 'text-white'
      : isTie
      ? 'text-[#747474]'
      : 'text-[#333]';
  
    const scoreColor = hasExited
      ? 'text-black'
      : isWinner
      ? 'text-white'
      : isTie
      ? 'text-black'
      : 'text-[#D95353]';
  
    const displayScore = Math.abs(score);
    const isPositiveScore = score >= 0;
  
    return (
      <div className={`rounded-[10px] mb-5 shadow-[0_2px_4px_rgba(0,0,0,0.1)] overflow-hidden ${cardBackground}`}>
        <div className="flex justify-between items-center p-[15px]">
          <div className="flex items-center">
            {user.photo ? (
              <img
                src={user.photo}
                alt="User"
                className="w-10 h-10 rounded-full bg-[#D95353] text-white flex items-center justify-center mr-[10px]"
              />
            ) : (
              <div
                className="w-10 h-10 rounded-[4px] text-[24px] font-bold text-center mr-[10px] bg-[rgb(189,203,255)] text-black flex items-center justify-center"
              >
                {getInitial(user.name)}
              </div>
            )}
            <span className={`text-[16px] font-bold ${textColor}`}>{user.name}</span>
          </div>
          <div className="flex items-center">
            <div
              className={`w-[28px] h-[28px] flex items-center justify-center mr-[2px] 
                ${isWinner ? 'border-[#61BD85]' : isTie ? 'border-[#FFD700]' : 'border-[#FFB6B6]'}`}
            >
              {isPositiveScore ? (
                <IconPlus size={18} stroke={2} className={scoreColor} />
              ) : (
                <IconMinus size={18} stroke={2} className={scoreColor} />
              )}
            </div>
            <img src={CrownGeneral} alt="Crown" className="w-5 h-5 mr-[5px]" />
            <span className={`text-[18px] font-bold ${scoreColor}`}>{displayScore}</span>
          </div>
        </div>
        <div className="flex p-[15px] border border-border bg-background">
          {answerStatus.slice(0, 5).map((status, index) => (
            <div key={index} className="flex items-center ml-[20px]">
              <div
                className={`w-[24px] h-[24px] rounded-full flex items-center justify-center text-white text-[16px]
                  ${status === null ? 'bg-transparent' : status ? 'bg-[#61BD85]' : 'bg-[#FFB6B6]'}`}
              >
                {status === null ? (
                  <span className="text-[#747474]">―</span>
                ) : status ? (
                  <IconCheck color="#fff" size={16} />
                ) : (
                  <IconX color="#fff" size={16} />
                )}
              </div>
              {index !== 4 && <div className="w-[1px] h-[26px] bg-[#E8E8E8] ml-8" />}
            </div>
          ))}
        </div>
      </div>
    );
  };  

  return (
    <div>
      <Flex align="center" className="py-[1.2em] px-0 bg-background">
        <img
          src={colorScheme === 'dark' ? ArrowLeftDark : ArrowLeft}
          alt="Arrow Left"
          className="cursor-pointer ml-[1em]"
          onClick={() => navigate('/duel')}
        />
        <text className="ml-4 text-xl">Exit results</text>
      </Flex>
      <div className="p-5 text-[#333] max-w-[600px] mx-auto bg-background overflow-y-auto h-[92vh] scrollbar-hidden">
        <div className="text-[18px] text-center text-text">ONLINE DUEL</div>
        <div
          className={`text-2xl font-bold text-center mb-5 ${hasExited
            ? 'text-text'
            : isUserWinner
              ? 'text-text'
              : isTie
                ? 'text-text'
                : 'text-red-500'
            }`}
        >
          {hasExited
            ? 'Duel Aborted!'
            : isUserWinner
              ? 'Victory!'
              : isTie
                ? "It's a Draw!"
                : 'Defeat!'}
        </div>



        <UserCard
          user={userProfile}
          isWinner={!hasExited && isUserWinner}
          isTie={isTie}
          hasExited={hasExited}
          score={userEloChange}
          answerStatus={userAnswerStatus}
          colorScheme={colorScheme}
        />

        <div className="flex items-center justify-center w-full relative my-[2.4em] border-t border-[#E8E8E8]">
          <span className="bg-background px-[10px] absolute -top-4 text-[18px] font-bold text-[#E8E8E8]">
            <img src={DuelOnlineResultVs} alt="VS" className="w-auto" />
          </span>
        </div>

        <UserCard
          user={opponentData}
          isWinner={!hasExited && !isUserWinner && !isTie}
          isTie={isTie}
          hasExited={hasExited}
          score={botEloChange}
          answerStatus={opponentAnswerStatus}
          colorScheme={colorScheme}
        />

        {!hasExited ? (
          <div className="flex items-center justify-center my-[1.9em] gap-[10px]">
            {Object.keys(botProfile).length > 0 && (
              <button
                className="p-[1.2em] rounded-[28px] text-sm font-bold border border-black bg-white text-black w-[30%] cursor-pointer"
                onClick={rematch}
                disabled={loading}
              >
                {loading ? <Loader size="sm" /> : 'Rematch'}
              </button>
            )}
            <button
              className="p-[1.2em] rounded-[28px] text-sm font-bold border-none bg-[#070519] text-white w-[50%] cursor-pointer"
              onClick={() => handleTabClick(navigate)}
            >
              Duel Review
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center my-[1.9em]">
            <button
              className={`p-[1.2em] rounded-[28px] text-sm font-bold border w-[50%] cursor-pointer ${colorScheme === 'dark'
                  ? 'bg-white text-black border-white'
                  : 'bg-black text-white border-black'
                }`}
              onClick={() => navigate('/duel')}
            >
              Exit Results
            </button>
          </div>

        )}

        {showUnableRematch && (
          <div className="text-red-500 justify-center flex items-center text-center font-semibold m-3">
            We are unable to set up a rematch against your opponent. Please try again.
          </div>
        )}

        {!hasExited && isUserWinner && (
          <div className="bg-cardBackground rounded-[10px] shadow-[0px_0px_8px_rgba(0,0,0,0.1)] overflow-hidden mb-[3em]">
            <div className="flex items-center justify-between p-4">
              <div>
                <div className="text-[17px] font-bold text-text">Spread the excitement</div>
                <div className="text-[14px] text-text">Share the duel results with your friends!</div>
              </div>
              <div className="cursor-pointer" onClick={buildDeeplink}>
                <img src={Share} alt="ShareButton" />
              </div>
            </div>
            <div>
              <img src={shareImg} alt="Share Image" className="w-full" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DuelResults;