import React, { useState, useEffect } from 'react';
import { TextInput, Button, Text, Box, Stack, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../stores/userStore';
import { useTheme } from '../../context/themeProvider'; 

const AccountDetailsForm = () => {
  const navigate = useNavigate();
  const setUserData = userStore((state) => state.setUserData);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { colorScheme } = useTheme();

  const userData = userStore((state) => state.userData);
  console.log(colorScheme,'colorScheme')
  useEffect(() => {
    if (userData) {
      const fullName = userData.name || '';
      const nameParts = fullName.split(' ');
      setFirstName(nameParts[0] || '');
      setLastName(nameParts.slice(1).join(' ') || '');
      setPhoneNumber(userData.mobileNumber || '');
    }
  }, [userData]);

  const isFormValid = () => {
    if (firstName.trim() === '') {
      setError('Please enter your first name');
      return false;
    }
    if (lastName.trim() === '') {
      setError('Please enter your last name');
      return false;
    }
    if (phoneNumber.trim() === '') {
      setError('Please enter your phone number');
      return false;
    }
    return true;
  };
  const onSubmit = async () => {
    setError('');
    if (!isFormValid()) {
      return;
    }
  
    const userProfileData = {
      user_id: userData.user,
      name: `${firstName.trim()} ${lastName.trim()}`,
      phone: phoneNumber.trim(),
    };
  
    setIsSubmitting(true);
  
    try {
      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/update-profile/`
  
      const res = await fetch(apiUrl, {
        method: 'PATCH',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.accessToken}` // Include the access token
        },
        body: JSON.stringify(userProfileData),
      });
  
      const json = await res.json();  
      if (res.ok && json.data) {
        localStorage.setItem('is_signup_newUser', 'false');
        setUserData({
          ...userData,
          name: json.data.name,
          mobileNumber: json.data.phone,
          username: json.data.username,
        });
        window.location.reload()
        navigate('/news');
      } else {
        setError(json.error || json.detail || "We're unable to update your profile at the moment. Please try again later.");
      }
    } catch (err) {
      setError("We're unable to update your profile at the moment. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <Box className={`h-screen mx-auto pt-[50px] px-6 ${
      colorScheme === 'dark' ? 'bg-background text-text' : 'bg-white'
    }`}>
      <Stack className="space-y-4">
        <Text className={`text-center font-bold text-[22px] ${
          colorScheme === 'dark' ? 'text-text' : ''
        }`}>
          Account details
        </Text>
        <Text className={`text-center text-sm ${
          colorScheme === 'dark' ? 'text-subtleText' : 'text-gray-500'
        }`}>
          Update your profile details below
        </Text>

        <div className="space-y-2">
          <label className={`font-medium text-sm block mb-2 ${
            colorScheme === 'dark' ? 'text-text' : 'text-gray-700'
          }`}>
            First Name
          </label>
          <TextInput
            placeholder="Enter your first name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            classNames={{
              input: `h-[50px] rounded-[10px] ${
                colorScheme === 'dark' 
                  ? 'bg-background border-gray-700 text-text' 
                  : 'bg-white border-[#E0E0E0]'
              } text-base px-4 w-full`
            }}
          />
        </div>

        <div className="space-y-2">
          <label className={`font-medium text-sm block mb-2 ${
            colorScheme === 'dark' ? 'text-text' : 'text-gray-700'
          }`}>
            Last Name
          </label>
          <TextInput
            placeholder="Enter your last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            classNames={{
              input: `h-[50px] rounded-[10px] ${
                colorScheme === 'dark' 
                  ? 'bg-background border-gray-700 text-text' 
                  : 'bg-white border-[#E0E0E0]'
              } text-base px-4 w-full`
            }}
          />
        </div>

        <div className="space-y-2">
          <label className={`font-medium text-sm block mb-2 ${
            colorScheme === 'dark' ? 'text-text' : 'text-gray-700'
          }`}>
            Phone Number
          </label>
          <TextInput
            placeholder="+91 00000 00000"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            classNames={{
              input: `h-[50px] rounded-[10px] ${
                colorScheme === 'dark' 
                  ? 'bg-background border-gray-700 text-text' 
                  : 'bg-white border-[#E0E0E0]'
              } text-base px-4 w-full`
            }}
          />
        </div>

        {error && (
          <Text className="text-red-500 text-sm text-center">
            {error}
          </Text>
        )}

    <button
      onClick={onSubmit}
      disabled={isSubmitting || !firstName || !lastName || !phoneNumber}
      className={`w-full h-[55px] rounded-[30px] text-lg mt-6 transition-colors duration-200 ${
        isSubmitting || !firstName || !lastName || !phoneNumber
          ? colorScheme === 'dark'
            ? 'bg-[#333333] text-[#666666] cursor-not-allowed'
            : 'bg-[#E0E0E0] text-[#9E9E9E] cursor-not-allowed'
          : colorScheme === 'dark'
            ? 'bg-white text-black hover:bg-gray-200'
            : 'bg-black text-white hover:bg-gray-900'
      }`}
    >
      {isSubmitting ? 'Updating...' : 'Update Profile'}
    </button>
      </Stack>
    </Box>
  );
};

export default AccountDetailsForm;