import React, { useState, useEffect, useRef } from 'react';
import { icons } from '../constants/iconData';
import { ArrowDown, ArrowDownDarKIcon } from '../assets/images';
import { useTheme } from '../context/themeProvider';

const BottomDrawerSelect = ({ options, onChange, value, width = '100%' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const dropdownRef = useRef(null);
  const windowHeight = window.innerHeight;
  const isMobile = window.innerWidth <= 768;
  const { colorScheme } = useTheme();

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative z-[1000] flex-1" ref={dropdownRef}>
      <div
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        tabIndex={0}
        onClick={toggleDropdown}
        className={`flex items-center px-3 py-2 border rounded-lg cursor-pointer justify-between 
          ${focused ? 'border-blue-500' : 'border-gray-300'} outline-none`}
        style={{ width }}
      >
        <span
          className={'text-text text-sm font-poppins'}
        >
          {value ? options.find((opt) => opt.value === value)?.label : options[0].label}
        </span>
        <img src={colorScheme === 'dark' ? ArrowDownDarKIcon : ArrowDown} alt="Icon" className="w-3.5" />
      </div>

      <div
        className={`fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full ${isMobile ? 'max-w-full' : 'max-w-[28rem]'
          } bg-cardBackground max-h-[55vh] overflow-y-auto shadow-md pb-5 pt-2 rounded-t-lg z-50 transition-transform duration-300 ${isOpen ? 'translate-y-0' : 'translate-y-full'
          }`}
      >
        <div className="px-4 text-left">
          {options.map((option) => (
            <div
            key={option.value}
            className={`flex items-center mb-2 cursor-pointer px-3 py-2 rounded-lg hover:bg-selectedOptionBackground`}            
              onClick={() => handleSelect(option.value)}
            >
              {icons[option.value] && (
                <div className="mr-2">{icons[option.value]}</div>
              )}
              <span className="ml-2 text-text font-poppins">{option.label}</span>
            </div>
          ))}

        </div>
      </div>
    </div>
  );
};

export default BottomDrawerSelect;
