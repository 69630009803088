import React, { useState, useEffect } from 'react';
import { ArrowDown, ArrowDownDarKIcon, CloseIcon, DuelOnlineEmpty, HelpCircle, NoHistory } from '../../assets/images';
import { DateRangePicker } from 'react-date-range';
import BottomDrawerSelect from '../../molecules/BottomDrawerSelect';
import DuelHistoryCard from './DuelHistoryCard';
import { useNavigate } from 'react-router-dom';
import DuelInfoTips from './InfoTip/DuelOnlineInfoTip';
import FirstTimeUserDuel from './FirstTimeUser';
import { userStore } from '../../stores/userStore';
import { useTheme } from '../../context/themeProvider';
import { Button, Popover, Flex, Image } from '@mantine/core';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

export default function DuelOnline() {
  const [selectedSubject, setSelectedSubject] = useState('all_subjects');
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [duelHistory, setDuelHistory] = useState([]);
  const [filteredDuelHistory, setFilteredDuelHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [showInfoTips, setShowInfoTips] = useState(false);
  const userData = userStore((state) => state.userData);
  const { colors,colorScheme } = useTheme();
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const isMobile = window.innerWidth <= 400;

  const filterDuelHistoryByDateRange = () => {
    const { startDate, endDate } = dateRange[0];

    // Make sure endDate includes the whole day (set time to 23:59:59)
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    if (startDate.getTime() === endDate.getTime()) {
      const filtered = duelHistory.filter((duel) => {
        const duelDate = new Date(duel.created_at);
        return duelDate.toDateString() === startDate.toDateString();
      });

      setFilteredDuelHistory(filtered);
    } else {
      const filtered = duelHistory.filter((duel) => {
        const duelDate = new Date(duel.created_at);
        return duelDate >= startDate && duelDate <= adjustedEndDate;
      });

      setFilteredDuelHistory(filtered);
    }

    setIsFilterApplied(true);
    setPopoverOpened(false);
  };

  const handleDateRangeChange = (ranges) => {
    const { selection } = ranges;
    setDateRange([selection]);
  };

  const handleClearFilter = () => {
    setFilteredDuelHistory(duelHistory);
    setIsFilterApplied(false);
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  const handleCancel = () => {
    setPopoverOpened(false);
  };

  const formatDateRange = (startDate, endDate) => {
    const optionsWithoutYear = { day: '2-digit', month: 'short' };
    const optionsWithYear = { day: '2-digit', month: 'short', year: 'numeric' };

    const formattedStartDate = new Date(startDate).toLocaleDateString('en-US', optionsWithoutYear);
    const formattedEndDate = new Date(endDate).toLocaleDateString('en-US', optionsWithYear);

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  useEffect(() => {
    const fetchDuelHistory = async () => {
      if (!userData || !userData.user) {
        console.error('User data is missing');
        setIsLoading(false);
        return;
      }

      try {
        const category = selectedSubject === 'all_subjects' ? '' : `&category=${selectedSubject}`;
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/postgame/?profile=${userData.user}${category}&game_type=online`);
        if (!response.ok) {
          throw new Error('Failed to fetch duel history');
        }

        const data = await response.json();
        setDuelHistory(data);
        setFilteredDuelHistory(data);
      } catch (error) {
        console.error('Error fetching duel history:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDuelHistory();
  }, [userData, selectedSubject]);

  const subjects = [
    { value: 'all_subjects', label: 'All Subjects' },
    { value: 'csat', label: 'CSAT' },
    { value: 'history', label: 'History' },
    { value: 'geography', label: 'Geography' },
    { value: 'polity', label: 'Polity' },
    { value: 'current_affairs', label: 'Current Affairs' },
    { value: 'economy', label: 'Economy' },
    { value: 'science', label: 'Science' },
    { value: 'environment', label: 'Environment' },
  ];

  const handleInfoTip = () => {
    MixpanelEvent('info_tip', { type: 'duel_online', source: 'clicked' });
    sendPostHogEvent('info_tip', { type: 'duel_online', source: 'clicked' });
    setShowInfoTips(true);
  };

  const closeModal = () => {
    MixpanelEvent('screen_closed', { source_screen: 'duel_online', close_type: 'modal' });
    sendPostHogEvent('screen_closed', { source_screen: 'duel_online', close_type: 'modal' });
    setShowInfoTips(false);
  };

  if (isLoading) {
    return null;
  }
  const handleTabClick = (navigate) => {
    MixpanelEvent('duel_mode_clicked', { mode: 'online' });
    sendPostHogEvent('duel_mode_clicked', { mode: 'online' });
    navigate('/online-match-game');
  };

  const handleSubjectChange = (subject) => {
    console.log(subject);
    setSelectedSubject(subject);
    MixpanelEvent('duel_history_show', { mode: 'online', category: subject });
    sendPostHogEvent('duel_history_show', { mode: 'online', category: subject });
  };

  const isNewUser = localStorage.getItem('new_user') === 'true';
  const isFirstTimeUser = isNewUser && duelHistory.length === 0;

  return (
    <div
      style={{
        flexDirection: 'column',
        height: window.innerHeight <= 700 && window.innerWidth <= 400 ? '69vh' : '78vh',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
      }}
    >
      {isFirstTimeUser ? (
        <FirstTimeUserDuel state="online" />
      ) : (
        <>
          <div className="rounded-2xl overflow-hidden shadow-md mb-5">
            <div
              className="bg-cover bg-center h-[150px] w-full"
              style={{
                backgroundImage: `url(${DuelOnlineEmpty})`
              }}
            ></div>
            <div className="flex justify-between items-center p-4 bg-cardBackground">
              <div className="flex items-center">
                <span className="font-bold text-text mr-1">Duel online</span>
                <img
                  src={HelpCircle}
                  alt="Help icon"
                  className="ml-2 w-5 h-5 cursor-pointer"
                  onClick={handleInfoTip}
                />
              </div>
              <button
                  onClick={() => handleTabClick(navigate)}
                  className={`border-none rounded-full px-5 py-2.5 cursor-pointer font-bold 
                    ${colorScheme === 'dark' ? 'bg-white text-black' : 'bg-black text-white'}`}
                >
                  Play Duel
                </button>

            </div>
          </div>

          <div>
            <p className="w-full ml-1.5 text-text">History</p>
            <div className="flex justify-evenly gap-4 w-full">
              <div>
                <BottomDrawerSelect
                  options={subjects}
                  value={selectedSubject}
                  width={isMobile ? '160px' : '220px'}
                  onChange={handleSubjectChange}
                  className="w-full"
                />
              </div>
              <div>
                {isFilterApplied ? (
                  <Button
                  style={{
                    backgroundColor: colors.cardBackground,
                    borderColor: colors.border,
                    color: colors.text,
                    border: `1px solid ${colors.border}`,
                  }} 
                  onClick={handleClearFilter}
                >
                  {dateRange[0].startDate.getTime() === dateRange[0].endDate.getTime() 
                    ? new Date(dateRange[0].startDate).toLocaleDateString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                      })
                    : (new Date(dateRange[0].startDate).getMonth() === new Date(dateRange[0].endDate).getMonth() &&
                        new Date(dateRange[0].startDate).getFullYear() === new Date(dateRange[0].endDate).getFullYear()
                        ? `${new Date(dateRange[0].startDate).getDate()}-${new Date(dateRange[0].endDate).getDate()} ${new Date(dateRange[0].startDate).toLocaleDateString('en-US', {
                            month: 'short',
                          })}, ${new Date(dateRange[0].startDate).getFullYear()}`
                        : formatDateRange(dateRange[0].startDate, dateRange[0].endDate)
                      )
                    }
                    <Image src={CloseIcon} alt="Close Filter" className="w-5 h-5" />
                  </Button>
                ) : (
                  <Popover
                    width={300}
                    position="fixed"
                    withArrow
                    shadow="md"
                    opened={popoverOpened}
                    onChange={setPopoverOpened}
                    className="z-[9999]"
                  >
                    <Popover.Target>
                      <button onClick={() => setPopoverOpened((o) => !o)} className="custom-button-duel text-text w-full">
                        Sort by Date
                        <img src={colorScheme === 'dark' ? ArrowDownDarKIcon : ArrowDown} alt="" className="icon ml-2.5 h-3.5 w-3.5" />
                      </button>
                    </Popover.Target>
                    <Popover.Dropdown
                      style={{
                        zIndex: 9999,
                        position: 'absolute',
                        top: '70%',
                        left: '50%',
                        width: window.innerWidth <= 500 ? window.innerWidth : '28rem',
                        // width: '100dvw',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <DateRangePicker
                        ranges={dateRange}
                        onChange={handleDateRangeChange}
                        moveRangeOnFirstSelection={false}
                        showMonthAndYearPickers
                        maxDate={new Date()}
                        rangeColors={[colors.coloredButton]}
                      />
                      <Flex justify="space-between" w="90%">
                        <Button variant="outline" color="gray" onClick={handleCancel} style={{ flex: 1, color: 'black', border: 'none' }}>
                          Cancel
                        </Button>

                        <Button
                          onClick={filterDuelHistoryByDateRange}
                          style={{ flex: 1, backgroundColor: 'black', borderRadius: '200px', color: 'white' }}
                        >
                          Select Range
                        </Button>
                      </Flex>
                    </Popover.Dropdown>
                  </Popover>
                )}
              </div>
            </div>

            {/* Show duel history or "No history found" message */}
            {filteredDuelHistory.length > 0 ? (
              filteredDuelHistory.map((duel, index) => {
                const totalCards = filteredDuelHistory.length;
                const cardIndex = totalCards - index;
                return (
                  <DuelHistoryCard
                    key={index}
                    cardIndex={cardIndex}
                    resultStatus={
                      duel.correct_answers > duel.opponent_correct_answer
                        ? 'VICTORY'
                        : duel.correct_answers < duel.opponent_correct_answer
                          ? 'DEFEAT'
                          : 'DRAW'
                    }
                    duel={duel}
                  />
                );
              })
            ) : (
              <div className="mt-16 flex justify-center flex-col items-center">
                <img src={NoHistory} className="w-[150px] h-[150px]" alt="No history" />
                <div className="flex flex-col text-center">
                  <h3 className="m-0 p-0">No history found</h3>
                  <h4 className="text-gray-500 m-1.5">You've not had any duels for the selected topic & date</h4>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <DuelInfoTips opened={showInfoTips} onClose={closeModal} />
    </div>
  );
}
