import React from 'react';
import { AddFriendLinkInfo, ArrowLeft, ArrowLeftDark} from '../../../assets/images';
import { useTheme } from '../../../context/themeProvider';

const UserIdInfoModal = ({ onClose }) => {
  const { colorScheme } = useTheme();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-cardBackground rounded-2xl w-[90%] max-w-[400px] p-6">
        <div className="flex items-center gap-2 mb-6">
          <button onClick={onClose}>
          <img 
                src={colorScheme === 'dark' ? ArrowLeftDark : ArrowLeft} 
                alt="Previous" 
                className="w-5" 
              />
          </button>
          <h2 className="text-lg font-medium">Where to find Friend's User ID?</h2>
        </div>

        {/* Profile Preview */}
        <img 
          src={AddFriendLinkInfo} 
          alt="Add Friend Image" 
          className="w-full h-auto mb-6 rounded-md object-contain" 
        />

        <p className="text-center text-text mb-6">
          Your friend's User ID is displayed on their profile, allowing them to copy it & share it with you to engage in a Duel.
        </p>

        {/* Cancel Button */}
        <button 
          onClick={onClose}
          className="w-full py-3 text-text border border-buttonBorder rounded-full"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UserIdInfoModal;
