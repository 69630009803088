import React, { useState, useEffect, useRef } from 'react';
import { IconCheck, IconX, IconExclamationCircle, IconMessageCircle2 } from '@tabler/icons-react';
import { Button, Text, Badge, Title, Stack, Radio, Textarea, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftDarkIcon, ArrowLeftFilled, ArrowRightDarkIcon, ArrowRightFilled, NoDataImage } from '../../assets/images';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import Lottie from 'react-lottie';
import * as successAnimation from '../../assets/json/report_success.json';
import { useTheme } from '../../context/themeProvider';

export default function PrelimsQuestionsTab({ questions, colors, userId }) {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionStates, setQuestionStates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState('');
  const [resportData, setResportData] = useState('');
  const [isReportSubmited, setReportSubmit] = useState(false);
  const [selectedQuestion, setSelectedQuestionId] = useState('');
  const containerRef = useRef(null);
  const isMobile = window.innerWidth <= 2500;
  const { colorScheme } = useTheme();

  useEffect(() => {
    setQuestionStates(questions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null })));
  }, [questions]);

  const currentQuestion = questions[currentQuestionIndex];

  const handleOptionChange = (index) => {
    const correctAnswerIndex = currentQuestion.answer;
    const isAnswerCorrect = questionStates[currentQuestionIndex].answerIndex === correctAnswerIndex;
    if (!questionStates[currentQuestionIndex].isSubmitted) {
      setQuestionStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[currentQuestionIndex].selectedOption = currentQuestion.options[index];
        newStates[currentQuestionIndex].answerIndex = index;
        return newStates;
      });
    }
    const optionsIndex = ['A', 'B', 'C', 'D'];
    const selectedOption = optionsIndex[index];
    MixpanelEvent('question_option_selected', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_pyq',
    });
    sendPostHogEvent('question_option_selected', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_pyq',
    });
  };

  const handleSubmit = () => {
    const correctAnswerIndex = currentQuestion.answer;
    const isAnswerCorrect = questionStates[currentQuestionIndex].answerIndex === correctAnswerIndex;
    setQuestionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[currentQuestionIndex].isSubmitted = true;
      newStates[currentQuestionIndex].isCorrect = isAnswerCorrect;
      return newStates;
    });
    const optionsIndex = ['A', 'B', 'C', 'D'];
    const selectedOption = optionsIndex[questionStates[currentQuestionIndex].answerIndex];
    MixpanelEvent('question_answer_submitted', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_pyq',
    });
    sendPostHogEvent('question_answer_submitted', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_pyq',
    });
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => (prevIndex < questions.length - 1 ? prevIndex + 1 : prevIndex));
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleDiscussWithAi = () => {
    const aiTutorParams = {
      flowType: 'postGame',
      source: 'news_feed',
      id: null,
      chatType: 'ai_tutor',
      node_id: null,
      chatgraph_id: null,
      messages: [
        {
          role: 'user',
          convo_id: '',
          type: 'Question',
          content: currentQuestion.question,
        },
        {
          role: 'user',
          convo_id: '',
          type: 'My Answer',
          content: currentQuestion.options[questionStates[currentQuestionIndex].answerIndex],
        },
      ],
      preSelectQuestion: {
        answer: null,
        choices: currentQuestion.options.map((option, index) => ({
          [String.fromCharCode(97 + index)]: option,
        })),
        category: 'current_affairs',
        question: currentQuestion.question,
        question_id: currentQuestion.id,
        user_answer: currentQuestion.options[questionStates[currentQuestionIndex].answerIndex],
        user_option: String.fromCharCode(65 + questionStates[currentQuestionIndex].answerIndex),
        correct_answer: String.fromCharCode(65 + currentQuestion.answer),
        options: currentQuestion.options,
      },
      category: 'current_affairs',
      preSelectAnswer: currentQuestion.options[questionStates[currentQuestionIndex].answerIndex],
    };
    navigate(`/chat/new/`, { state: { aiTutorParams } });
    MixpanelEvent('discuss_with_ai', { source: 'news_pyq' });
    sendPostHogEvent('discuss_with_ai', { source: 'news_pyq' });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setDetails('');
    MixpanelEvent('screen_closed', { source_screen: 'Report Question', close_type: 'modal' });
    sendPostHogEvent('screen_closed', { source_screen: 'Report Question', close_type: 'modal' });
  };

  const handleReport = (questionId) => {
    setIsOpen(true);
    setSelectedQuestionId(questionId);
    setReportSubmit(false);
    setResportData('');
  };

  const handleResportSubmit = async () => {
    setReportSubmit(true);
    let postPayload = {
      flow: 'newspyq',
      question_id: selectedQuestion,
      user: userId,
      option: resportData,
      reason: '',
    };

    if (resportData === 'Other') {
      postPayload.reason = details;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();
      setTimeout(() => {
        if (res.status === 400) {
          MixpanelEvent('report_submitted', { content_id: selectedQuestion, error: json });
          sendPostHogEvent('report_submitted', { content_id: selectedQuestion, error: json });
        } else {
          MixpanelEvent('report_submitted', { content_id: selectedQuestion });
          sendPostHogEvent('report_submitted', { content_id: selectedQuestion });
        }
        setIsOpen(false);
      }, 3000);
    } catch (error) {}
  };

  return (
    <div className={`font-[Poppins] max-w-[600px] mx-auto h-[89dvh] flex flex-col `}>
      <div className={`p-4  shadow-[0px_4px_9.2px_0px_rgba(0,0,0,0.10)]`}>
        {questions.length > 0 && (
          <div className="flex justify-between items-center w-full">
            <img
             src={colorScheme === 'dark' ? ArrowLeftDarkIcon : ArrowLeftFilled}
              className={`cursor-${currentQuestionIndex === 0 ? 'not-allowed' : 'pointer'} rounded-lg border border-[#D1D1D1] p-2 bg-cardBackground`}
              style={{
                color: currentQuestionIndex === 0 ? 'gray' : colors.arrowColor,
              }}
              onClick={handlePreviousQuestion}
              alt="Arrow Left"
            />
            <div className="flex-1 overflow-hidden mx-2">
              <div className="flex overflow-x-auto scrollbar-hide">
                {questions.map((_, index) => (
                    <div
                    key={index}
                    className={`min-w-[60px] h-[30px] flex justify-center items-center rounded-full cursor-pointer mx-1.5 flex-shrink-0 ${
                      index === currentQuestionIndex
                        ? `${colorScheme === 'dark' ? 'bg-[#EBF3FF] text-black' : 'bg-[rgb(219,219,219)] text-[#6374FA]'}`
                        : 'bg-transparent text-text'
                    }`}
                    onClick={() => setCurrentQuestionIndex(index)}
                  >
                    Q{index + 1}
                  </div>
                ))}
              </div>
            </div>
            <img
              src={colorScheme === 'dark' ? ArrowRightDarkIcon : ArrowRightFilled}
              className={`cursor-${currentQuestionIndex !== questions.length - 1 ? 'pointer' : 'not-allowed'} rounded-lg border border-[#D1D1D1] p-2 bg-cardBackground`}
              style={{
                color: currentQuestionIndex !== questions.length - 1 ? colors.arrowColor : 'gray',
              }}
              onClick={handleNextQuestion}
              alt="Arrow Right"
            />
          </div>
        )}
      </div>

      {questions.length === 0 ? (
        <div className="text-center p-5 h-[60vh] flex justify-center items-center flex-col">
          <img src={NoDataImage} alt="no data" className="mb-5" />
          <Text className={`font-semibold mb-2.5 text-[${colors.text}]`}>No Related Questions Found</Text>
          <Text className={`text-[${colors.subtleText}]`}>
            We were unable to generate any PYQs on this article. Please tap on try again below.
          </Text>
        </div>
      ) : (
        <div 
          className={`flex-1 overflow-y-auto overflow-x-hidden rounded-xl relative touch-pan-y border border-border m-2.5 h-[calc(100dvh-180px)] bg-cardBackground`}
          ref={containerRef}
        >
          <div className={`m-[15px] bg-cardBackground mt-[7px] rounded-xl h-[88%] overflow-y-auto`}>
            <div className="flex justify-start items-center mb-2.5 gap-2.5">
              <Badge color={colors.subtleText}>{currentQuestion?.exam.toUpperCase()}</Badge>
              <Badge color={colors.subtleText}>{currentQuestion?.year}</Badge>
              <Badge color={colors.subtleText}>{currentQuestion?.category?.toUpperCase()}</Badge>
            </div>

            <Text className={`text-base font-medium mb-5 text-[${colors.text}]`}>
              {currentQuestion.question.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Text>

            <div className="flex flex-col gap-2.5">
              {currentQuestion.options.map((option, index) => {
                const isSubmitted = questionStates[currentQuestionIndex]?.isSubmitted;
                const isCorrectAnswer = index === currentQuestion.answer;
                const isSelectedAnswer = index === questionStates[currentQuestionIndex]?.answerIndex;

                return (
                  <div
                    key={index}
                    onClick={() => handleOptionChange(index)}
                    className={`
                      rounded-lg p-4 cursor-pointer border flex items-center gap-1.5 
                      ${isSubmitted
                        ? isCorrectAnswer
                          ? 'bg-[#2F6545] border-[#2F6545]'
                          : isSelectedAnswer
                            ? 'bg-[#9F4749] border-[#9F4749]'
                            : 'bg-cardBackground border-border'
                        : isSelectedAnswer
                          ? 'bg-selectedOptionBackground border-selectedOptionBackground'
                          : 'bg-cardBackground border-border'
                      }
                    `}
                  >
                    <div className="flex items-center">
                      <div
                        className={`w-6 h-6 rounded-full flex justify-center items-center mr-2.5 transition-all duration-300 ${
                          questionStates[currentQuestionIndex]?.isSubmitted
                            ? index === currentQuestion.answer
                              ? 'bg-[#34d399]'
                              : index === questionStates[currentQuestionIndex].answerIndex
                              ? 'bg-[#ff9999]'
                              : 'bg-transparent'
                            : 'bg-transparent'
                        } ${
                          questionStates[currentQuestionIndex]?.isSubmitted &&
                          (index === currentQuestion.answer || index === questionStates[currentQuestionIndex].answerIndex)
                            ? 'border-none'
                            : 'border-2 border-[#6374FA]'
                        }`}
                      >
                        {questionStates[currentQuestionIndex]?.answerIndex === index && !questionStates[currentQuestionIndex]?.isSubmitted && (
                          <div className="w-[14.5px] h-[14.5px] bg-[#6374FA] rounded-full"></div>
                        )}
                        {questionStates[currentQuestionIndex]?.isSubmitted &&
                          (index === currentQuestion.answer ? (
                            <IconCheck className="w-3.5 h-3.5 text-white" />
                          ) : index === questionStates[currentQuestionIndex].answerIndex ? (
                            <IconX className="w-3.5 h-3.5 text-white" />
                          ) : null)}
                      </div>
                      <Text className={`mr-2.5 text-[${colors.text}]`}>{String.fromCharCode(65 + index)}.</Text>
                      <Text className={`text-[15px] font-medium flex-1 text-[${colors.text}]`}>{option}</Text>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex justify-between p-4 shadow-[0px_-4px_24px_0px_rgba(0,0,0,0.12)] w-full bg-cardBackground border border-border absolute bottom-0 left-0 right-0 z-50 box-border sm:mb-0 mb-4">
          <button
              className=" text-[#D95353] flex items-center justify-center gap-1 py-2 px-4 rounded-full font-medium focus:outline-none"
              onClick={() => handleReport(currentQuestion.id)}
            >
              <IconExclamationCircle size={16} />
              Report
            </button>
            {!questionStates[currentQuestionIndex]?.isSubmitted ? (
              <button
                disabled={!questionStates[currentQuestionIndex]?.selectedOption}
                className={`${
                  questionStates[currentQuestionIndex]?.selectedOption
                    ? (colorScheme === 'dark' ? 'bg-white text-black' : 'bg-black text-white')
                    : 'bg-transparent text-[#B9B9B9] cursor-not-allowed border border-[#D1D1D1] hover:bg-transparent transition-all duration-300 opacity-50'
                } rounded-full py-2 px-6 h-[2.5rem] text-sm font-medium`}
                onClick={handleSubmit}
              >
                Submit
              </button>
            ) : (
              <button
                className="bg-white text-black border border-black rounded-full py-2 px-6 h-[2.5rem] text-sm font-medium hover:bg-[#F3F3F3] transition duration-300"
                onClick={handleDiscussWithAi}
              >
                Discuss with AI
              </button>
            )}
          </div>
        </div>
      )}
      <ReportModaContent
        isMobile={isMobile}
        isOpen={isOpen}
        closeModal={handleCloseModal}
        resportData={resportData}
        details={details}
        handleResportSubmit={handleResportSubmit}
        setResportData={setResportData}
        isReportSubmited={isReportSubmited}
        onChange={(e) => setDetails(e.currentTarget.value)}
        colors={colors}
      />
    </div>
  );
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: successAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ReportModaContent = ({
  isOpen,
  isMobile,
  closeModal,
  isReportSubmited,
  resportData,
  setResportData,
  details,
  onChange,
  handleResportSubmit,
  styles,
  colors,
}) => {
  if (!isOpen) return null;

  return (
    <div className="absolute top-0 bottom-0 left-0 w-full h-full flex justify-center items-end z-[1000]">
      <div className={`bg-cardBackground text-text p-5 rounded-t-xl w-full text-center shadow-lg ${isMobile ? 'max-w-[28rem]' : 'max-w-[22vw]'}`}>
        <div className="flex items-center justify-center w-full">
        <span className="block w-[89px] h-[6px] bg-[#E8E8E8] rounded-full cursor-pointer" onClick={closeModal}></span>
        </div>
        <button
          onClick={closeModal}
          className="relative right-[-5px] border-none bg-none cursor-pointer text-xl font-bold text-black w-full flex justify-end"
        >
          &times;
        </button>

        {!isReportSubmited ? (
          <>
            <Title className="text-left m-4 text-xl">Report Question</Title>
            <Stack className="space-y-4">
              <Radio.Group value={resportData} onChange={setResportData} size="1em">
                {[
                  'Wrong category',
                  'Answer is incorrect',
                  'Incomplete question',
                  'Mistake in question',
                  'Mistake in options',
                  'Poorly formatted question',
                  'Poorly formatted options',
                  'Other',
                ].map((item, index) => (
                  <Radio key={index} value={item} label={item} className="p-4" />
                ))}
              </Radio.Group>

              {resportData === 'Other' && (
                <>
                  <Text className="text-left">Other reason*</Text>
                  <Textarea
                    placeholder="Provide details about your issue here..."
                    value={details}
                    rows={4}
                    radius="sm"
                    className="mt-2.5 text-sm"
                    onChange={onChange}
                  />
                </>
              )}
            </Stack>

            <Group position="center" mt="lg">
              <Button
                fullWidth
                radius="xl"
                size="md"
                variant="filled"
                color="dark"
                disabled={!resportData}
                className={`bg-background text-white font-medium ${!resportData && 'bg-gray-400'}`}
                onClick={handleResportSubmit}
              >
                Submit Feedback
              </Button>
            </Group>
          </>
        ) : (
          <>
            <Title className="m-4 text-xl">Report Submitted</Title>
            <Title className="m-4 text-md">Thank you! Your report has been submitted.</Title>
            <Lottie options={defaultOptions} height={200} width={200} />
          </>
        )}
      </div>
    </div>
  );
};

