import React, { useState, useEffect } from 'react';
import { InfoHighlightImage, InfoHighlightImageDark } from '../../assets/images';
import { useTheme } from '../../context/themeProvider';

const InfoHighlight = ({ onGoBack }) => {
  const [isOpen, setIsOpen] = useState(true);
  const isMobile = window.innerWidth <= 2500;

  const { colors, colorScheme } = useTheme();

  const closeModal = () => {
    setIsOpen(false);
    onGoBack(); 
  };

  const handleClickOutside = (event) => {
    if (event.target.className.includes('modal-overlay')) {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const selectedImage = colorScheme === 'dark' ? InfoHighlightImageDark : InfoHighlightImage;

  return (
    <>
      {isOpen && (
        <div 
          className="fixed top-0 left-0 w-full h-full flex justify-center items-end z-50 modal-overlay"
          style={{ backgroundColor: colors.overlay }}
          onClick={handleClickOutside}
        >
          <div 
            className="p-5 rounded-t-xl w-full text-center shadow-lg"
            style={{ 
              maxWidth: isMobile ? '28rem' : '22vw', 
              backgroundColor: colorScheme === 'dark' ? '#303034' : 'white'
            }}
          >
            <div className="flex items-center justify-center w-full">
              <span 
                className="h-1.5 w-24 block rounded-full cursor-pointer"
                style={{ 
                  background: colorScheme === 'dark' ? '#5D5D5D' : '#E8E8E8' 
                }}
                onClick={closeModal}
              ></span>
            </div>
            
            <h2 
              className="text-2xl font-semibold mb-5"
              style={{ color: colors.text }}
            >
              Create Notes
            </h2>
            
            <p 
              className="text-base mb-5 mx-7"
              style={{ 
                color: colors.subText,
                maxWidth: '100%'
              }}
            >
              Long hold on the summary points to save them in the 'My Notes' for future reference.
            </p>
            
            <img 
              src={selectedImage} 
              alt="Thank You Illustration" 
              className="mb-5 w-full h-full"
            />

            <button 
              onClick={closeModal} 
              className="w-full p-3 rounded-lg text-base flex items-center justify-center"
              style={{ 
                backgroundColor: colorScheme === 'dark' ? 'white' : 'black', 
                color: colorScheme === 'dark' ? '#070519' : 'white', 
              }}
            >
              Got it
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoHighlight;