import { WsOneNew, WsTwoNew, WsThreeNew, WsFourNew, WsFiveNew, SliderOneIcon, ArrowRight, AiChat,DuelIconDark, PYQSearch,PyqIconDark, DuelChat,SliderOneIconDark } from '../assets/images';

export const getSlides = (colorScheme) => {

  return [
    {
      title: 'Daily Current Affairs',
      description: 'Find the latest news along with related PYQs.',
      icon: colorScheme === 'dark' ? SliderOneIconDark : SliderOneIcon,
      component: WsOneNew,
      svgBgColor: colorScheme === 'dark' ? ['#FFFCE6', '#18181A'] : ['#FFFCE6', '#FFFFFF'],
    },
    {
      title: 'AI Chat',
      description: 'Clarify your doubts using AI tutor. Ask anything get instant precise answers.',
      icon: colorScheme === 'dark' ? SliderOneIconDark : AiChat,
      component: WsTwoNew,
      svgBgColor: colorScheme === 'dark' ? ['#EAF5FF', '#18181A'] : ['#EAF5FF', '#FFFFFF'], 
    },
    {
      title: 'PYQ Search',
      description: 'Search any topic and get PYQs related to it.',
      icon: colorScheme === 'dark' ? PyqIconDark : PYQSearch,
      component: WsThreeNew,
      svgBgColor: colorScheme === 'dark' ? ['#F8EEFF', '#18181A'] : ['#F8EEFF', '#FFFFFF'], 
    },
    {
      title: 'Ask a Book',
      description: 'Chat with UPSC books. Get answers to all you doubts.',
      icon: colorScheme === 'dark' ? SliderOneIconDark : AiChat,
      component: WsFourNew,
      svgBgColor: colorScheme === 'dark' ? ['#FFF6EC', '#18181A'] : ['#FFF6EC', '#FFFFFF'], 
    },
    {
      title: 'Duel',
      description: 'Compete with your friends and other aspirants. Prepare for prelims in a fun way.',
      icon: colorScheme === 'dark' ? DuelIconDark : DuelChat,
      component: WsFiveNew,
      svgBgColor: colorScheme === 'dark' ? ['#F0FFFC', '#18181A'] : ['#F0FFFC', '#FFFFFF'], 
    },
  ];
};
