import React, { useState, useEffect, useRef } from 'react';
import { IconCheck, IconCaretDownFilled } from '@tabler/icons-react';
import { icons } from '../constants/iconData';

const CustomSelect = ({ options, onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [focused, setFocused] = useState(false);
  const dropdownRef = useRef(null);
  const isMobile = window.innerWidth <= 768;

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleSelect = (option) => {
    if (value.includes(option)) {
      onChange((prev) => prev.filter((item) => item !== option));
    } else {
      onChange((prev) => [...prev, option]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      onChange([]);
    } else {
      onChange(options.map((option) => option.value));
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div style={{ position: 'relative', zIndex: 1000, flex: 1 }} ref={dropdownRef}>
      <div
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        tabIndex={0}
        onClick={toggleDropdown}
        className={`flex bg-cardBackground items-center p-3 border ${focused ? 'border-[#6374FA]' : 'border-[#ccc]'} rounded-lg cursor-pointer justify-between w-full h-[36px] text-[13px] gap-1.5 focus:outline-none`}
      >
        <span className={`font-poppins ${value.length === 0 ? 'text-gray-500' : 'text-text'}`}>
          {value.length === 1
            ? options.find((opt) => opt.value === value[0])?.label
            : value.length > 1
              ? `${value.length} Topics selected`
              : 'Select Topic'}
        </span>
        <IconCaretDownFilled width={12} height={12} color="gray" />
      </div>

      <div
        className="bg-cardBackground fixed "
        style={{
          bottom: isOpen ? 0 : '-100%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: isMobile ? '100%' : '28rem',
          maxHeight: '55vh',
          overflowY: 'auto',
          zIndex: 9999,
          boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
          paddingBottom: '20px',
          paddingTop: '10px',
          borderRadius: '10px 10px 0 0',
          transition: 'bottom 0.3s ease-in-out',
        }}
      >
        <div className="p-4 text-left">
          <div
            className="flex items-center p-4 cursor-pointer"
            onClick={handleSelectAll}
          >
            <div
              className={`w-6 h-6 border-[1.5px] ${selectAll ? 'border-[#6374FA] bg-[#6374FA]' : 'border-[#ddd] bg-white'} flex items-center justify-center mr-3`}
            >
              <IconCheck
                width={16}
                height={16}
                color={selectAll ? 'white' : '#ddd'}
              />
            </div>
            <span className="font-poppins text-[#747474]">Select All</span>
          </div>

          {options.map((option) => (
            <div
              key={option.value}
              className="flex items-center p-4 cursor-pointer"
              onClick={() => handleSelect(option.value)}
            >
              <div
                className={`w-6 h-6 border-[1.5px] ${value.includes(option.value) ? 'border-[#6374FA] bg-[#6374FA]' : 'border-[#ddd] bg-white'} flex items-center justify-center mr-3`}
              >
                <IconCheck
                  width={16}
                  height={16}
                  color={value.includes(option.value) ? 'white' : '#ddd'}
                />
              </div>
              <div className="flex items-center">
                {icons[option.value]}
                <span className="ml-2 font-poppins text-text">
                  {option.label}
                </span>
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default CustomSelect;