import React, { useState } from 'react';
import { MobileDevelopmentIcon, MobileDevelopmentIconDark } from '../../assets/images';
import { useTheme } from '../../context/themeProvider';

const CompletionModal = ({ onGoBack }) => {
  const [isOpen, setIsOpen] = useState(true);
  const isMobile = window.innerWidth <= 2500;
  const { colorScheme, colors } = useTheme();

  const closeModal = () => {
    setIsOpen(false);
    onGoBack(); 
  };

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-end z-50">
          <div className={`${
            colorScheme === 'dark' ? 'bg-cardBackground' : 'bg-white'
          } p-5 rounded-t-xl w-full text-center shadow-xl mb-0 max-w-[28rem] sm:max-w-[28rem]`}>
            <div className="flex items-center justify-center w-full">
              <span
                className="h-[6px] w-[89px] block bg-[#E8E8E8] rounded-full cursor-pointer"
                onClick={closeModal}
              ></span> 
            </div>
            <h2 className={`text-xl font-semibold mb-5 ${
              colorScheme === 'dark' ? 'text-text' : ''
            }`}>Thank you!</h2>
            <p className={`text-base mb-5 ${
              colorScheme === 'dark' ? 'text-text' : ''
            }`}>
              Your feedback is valuable to us. The Padhai team is working on making the app better every day.
            </p>
            {colorScheme === 'dark' ? (
            <img
              src={MobileDevelopmentIconDark}
              alt="Thank You Illustration"
              className="w-full"
            />
          ) : (
            <img
              src={MobileDevelopmentIcon}
              alt="Thank You Illustration"
              className="w-full"
            />
          )}
            <button
              onClick={closeModal}
              className={`w-full py-2 px-5 rounded-xl border-2 flex items-center justify-center text-lg cursor-pointer ${
                colorScheme === 'dark' 
                  ? 'bg-white text-black border-text' 
                  : 'bg-white text-black border-black'
              }`}
            >
              Back to Profile
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CompletionModal;