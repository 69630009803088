import React, { useState, useEffect } from 'react';
import { NoFriends, RefreshIcon, AddWhitePlusIcon, RefreshDarkIcon } from '../../assets/images';
import AddFriendModal from './modals/AddFriendModal';
import UserIdInfoModal from './modals/UserIdInfoModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { userStore } from '../../stores/userStore';
import { icons } from '../../constants/iconData';
import { ScrollArea } from '@mantine/core';
import InviteFriendModal from './modals/InviteFriendModal';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useTheme } from '../../context/themeProvider';

const DuelWithFriends = () => {
  const [selectedTab, setSelectedTab] = useState('Select Friend');
  const [showAddFriendModal, setShowAddFriendModal] = useState(false);
  const [showUserIdInfoModal, setShowUserIdInfoModal] = useState(false);
  const [friendsList, setFriendsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { colors, colorScheme } = useTheme();
  const userData = userStore((state) => state.userData);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [friendToDelete, setFriendToDelete] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteDetails, setInviteDetails] = useState(null);
  const socketBaseUrl = process.env.REACT_APP_SOCKET_BASE_URL;
  const [inviteResponse, setInviteResponse] = useState(null);
  const subjects = [
    { value: 'csat', label: 'CSAT' },
    { value: 'history', label: 'History' },
    { value: 'geography', label: 'Geography' },
    { value: 'polity', label: 'Polity' },
    { value: 'current_affairs', label: 'Current Affairs' },
    { value: 'economy', label: 'Economy' },
    { value: 'science', label: 'Science' },
    { value: 'environment', label: 'Environment' },
  ];

  const getFriendsList = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/friends/?user=${userData.user}`);
      const data = await response.json();
      if (response.ok) {
        setFriendsList(data || []);
      }
    } catch (error) {
      console.error('Error fetching friends:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleInvite = async (inviterId, inviterName) => {
      if (!inviterId || !userData.user) return;

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/add-friend/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            friend_username: inviterName,
            user_id: userData.user,
            category: "history",
            from_web: true
          }),
        });

        const data = await response.json();
        if (response.ok && data.status) {
          getFriendsList();
        } else {
          console.error('Failed to add friend:', data.error);
        }
      } catch (error) {
        console.error('Error adding friend:', error);
      }
    };

    if (location.state?.isInvite) {
      const { inviterId, inviterName } = location.state;
      console.log('Invite data:', { inviterId, inviterName });
      handleInvite(inviterId, inviterName);
    }
  }, [location, userData.user]);

  useEffect(() => {
    getFriendsList();
  }, [userData.user]);

  const handleAddFriendClick = () => {
    MixpanelEvent('duel_add_friend_clicked');
    sendPostHogEvent('duel_add_friend_clicked');
    setShowAddFriendModal(true);
  };

  const handleShowUserIdInfo = () => {
    setShowAddFriendModal(false);
    setShowUserIdInfoModal(true);
  };

  const handleTabSelect = (tab) => {
    if (tab === 'Select Friend' && selectedTab === 'Select Topic') {
      setSelectedTab('Select Friend');
      setSelectedFriend(null);
    }
  };

  const handleFriendSelect = (friendId) => {
    setSelectedFriend(friendId);
  };

  const handleDeleteFriend = async (friendId) => {
    const friendToDelete = friendsList.find((friend) => friend.id === friendId);
    if (friendToDelete) {
      setFriendToDelete(friendToDelete);
      setShowDeleteModal(true);
    }
  };

  const handleContinue = () => {
    if (selectedFriend) {
      setSelectedTab('Select Topic');
    }
  };

  const handleSendDuelInvite = async () => {
    if (!selectedFriend || !selectedTopic) {
      return;
    }

    try {
      const selectedFriendDetails = friendsList.find((friend) => friend.id === selectedFriend);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/sendinvite-new/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          friend_id: selectedFriendDetails.user,
          current_id: userData.user,
          category: selectedTopic,
        }),
      });

      const responseData = await response.json();

      if (responseData && responseData.data && responseData.data.room_id) {
        setInviteResponse(responseData);
        const roomId = responseData.data.room_id;
        const currentData = {
          ...responseData.data.currentInfo,
        };
        const opponetData = {
          ...responseData.data.playerInfo,
        };


        navigate('/duel-start', {
          state: {
            currentUser: currentData,
            topic: selectedTopic,
            room_id: roomId,
            opponent: opponetData,
            duel_mode: 'friends'
          },
        });
      } else {
        console.error('Failed to send invite');
      }
    } catch (error) {
      console.error('Error sending invite:', error);
    }
  };

  const handleDeclineInvite = () => {
    setShowInviteModal(false);
    setInviteResponse(null);
    setInviteDetails(null);
  };

  const confirmDelete = async () => {
    if (deleteLoader || !friendToDelete) return;
    setDeleteLoader(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/delete-friend/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          friend_user_id: friendToDelete.user,
          user_id: userData.user,
        }),
      });
      const data = await response.json();
      if (data.status) {
        setFriendsList(friendsList.filter((f) => f.id !== friendToDelete.id));
        if (selectedFriend === friendToDelete.id) {
          setSelectedFriend(null);
        }
      }
    } catch (error) {
      console.error('Error deleting friend:', error);
    } finally {
      setDeleteLoader(false);
      setShowDeleteModal(false);
      setFriendToDelete(null);
    }
  };

  useEffect(() => {
    const parseInviteData = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const dataParam = searchParams.get('data');

      if (dataParam) {
        try {
          const inviteData = JSON.parse(decodeURIComponent(dataParam));

          if (inviteData.event === 'invite') {
            setInviteDetails({
              friendName: inviteData.playerInfo.name,
              friendScore: inviteData.playerInfo.elo,
              topic: inviteData.category,
              friendPhoto: inviteData.playerInfo.photo
            });

            setInviteResponse({
              data: {
                room_id: inviteData.room_id,
                opponent: inviteData.currentInfo,
                currentUser: inviteData.playerInfo,
                category: inviteData.category
              }
            });

            setShowInviteModal(true);
          }
        } catch (error) {
          console.error('Error parsing invite data:', error);
        }
      }
    };

    parseInviteData();
  }, []); // Empty dependency array since we only want this to run once on component mount

  const handleAcceptInvite = () => {
    navigate('/duel-start', {
      state: {
        currentUser: inviteResponse.data.opponent,
        opponent: inviteResponse.data.currentUser,
        topic: inviteResponse.data.category,
        room_id: inviteResponse.data.room_id,
        duel_mode: 'friends'
      }
    });

    setShowInviteModal(false);
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-full">
          <div className="w-8 h-8 border-4 border-t-transparent border-gray-500 rounded-full animate-spin"></div>
        </div>
      );
    }

    if (!friendsList || friendsList.length === 0) {
      return (
        <>
          <div className="h-[70vh] md:h-[74vh] p-4 flex flex-col items-center justify-center">
            <img src={NoFriends} alt="No Friends Added" className="mb-4" />
            <h3 className="text-lg font-bold text-text mb-2">No Friends Added</h3>
            <p className="text-sm text-text text-center mb-6">
              Add friends by entering their User <br /> ID below or sending invite link.
            </p>
            <button
              onClick={handleAddFriendClick}
              className="w-full max-w-[180px] h-[46px] bg-text text-background rounded-full mb-3 transition-colors font-medium flex items-center justify-center gap-2"
            >
              <span className="text-[20px]">+</span>
              <span className="leading-[1]">Add New Friend</span>
            </button>

            <button
              onClick={getFriendsList}
              className="w-full max-w-[180px] h-[46px] bg-transparent text-text rounded-full  transition-colors flex items-center justify-center gap-2 border border-border"
            >
              <img src={colorScheme === 'dark' ? RefreshDarkIcon : RefreshIcon} alt="refresh" className="w-4 h-4" />
              <span>Refresh List</span>
            </button>
          </div>

        </>
      );
    }

    return (
      <div className="w-full">
        <div className="flex justify-between items-center mb-4">
          <button onClick={getFriendsList} className="flex items-center gap-2 text-sm font-bold">
            <img src={colorScheme === 'dark' ? RefreshDarkIcon : RefreshIcon} alt="refresh" className="w-5 h-5" />
            Refresh List
          </button>

          <button onClick={handleAddFriendClick} className="flex items-center gap-2 px-4 py-2 border-2 font-bold border-buttonBorder rounded-full text-sm">
            <span className="text-[20px]">+</span> Add New Friend
          </button>
        </div>

        <div className="rounded-lg">
          {friendsList.map((friend) => (
            <div
              key={friend.id}
              onClick={() => handleFriendSelect(friend.id)}
              className={`flex items-center gap-3 p-2 rounded-lg mb-2 last:mb-0 cursor-pointer 
          ${selectedFriend === friend.id ? 'bg-selectedOptionBackground border-none' : 'bg-background border'}`}
            >

              <input
                type="radio"
                name="selectedFriend"
                checked={selectedFriend === friend.id}
                onChange={() => handleFriendSelect(friend.id)}
                className="w-4 h-4 accent-[#6374FA] pointer-events-none"
              />
              <div className="flex items-center flex-1">
                <div className="w-8 h-8 mr-2">
                  {friend.photo ? (
                    <img src={friend.photo} alt={friend.name || friend.username} className="w-full h-full rounded-full object-cover" />
                  ) : (
                    <div className="w-full h-full bg-[#EEF1FF] text-black rounded-[4px] flex items-center justify-center text-center font-bold">
                      {friend.name ? friend.name[0].toUpperCase() : friend.username[0].toUpperCase()}
                    </div>
                  )}
                </div>
                <span className="font-medium">{friend.name || friend.username}</span>
              </div>
              <button
                className="p-2"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteFriend(friend.id);
                }}
              >
                <svg width="20" height="20" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.2667 3.04667C11.3333 2.85333 9.38667 2.75333 7.44 2.75333C6.33333 2.75333 5.22667 2.81333 4.12 2.93333L0.733337 3.04667M4.66667 2.48L4.78667 1.75333C4.87333 1.23333 4.94 0.833332 5.96 0.833332H8.04C9.06 0.833332 9.13333 1.26 9.21333 1.76L9.33333 2.48M11.8133 5.84667L11.4 12.4733C11.34 13.4133 11.28 14.1667 9.52667 14.1667H4.47333C2.72 14.1667 2.66 13.4133 2.6 12.4733L2.18667 5.84667M5.98667 10.5H8.00667M5.33333 7.83333H8.66667"
                    stroke="#FF4242"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const DeleteFriendModal = ({ isOpen, onClose, onConfirm, friendName }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-cardBackground rounded-lg p-6 w-[80%] max-w-sm">
          <h3 className="text-lg font-medium text-center mb-4">Remove Friend?</h3>
          <p className="text-center text-text mb-6">
            Are you sure you want to remove <span className="font-bold">{friendName}</span> from your Friend list?
          </p>
          <div className="flex gap-4">
            <button onClick={onClose} className="flex-1 py-3 px-6 border border-buttonBorder  rounded-full text-sm font-medium">
              Cancel
            </button>
            <button onClick={onConfirm} className="flex-1 py-3 px-6 bg-deleteButtonBackground text-white rounded-full text-sm font-medium">
              Yes, Remove
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="h-screen flex flex-col w-full max-w-[28em] mx-auto bg-background">
      <header className="py-4">
        <h2 className="text-left text-[12px] text-[#747474] font-semibold ml-4">DUEL WITH FRIENDS</h2>
      </header>

      <div className="flex border-b border-border">
        <button
          onClick={() => handleTabSelect('Select Friend')}
          className={`flex-1 py-3 text-center text-sm transition-all ${selectedTab === 'Select Friend'
            ? 'border-b-2 border-[#6374FA] text-[#6374FA] font-medium bg-selectedOptionBackground'
            : 'border-b-2 border-border text-[#B9B9B9] bg-cardBackground'
            } ${selectedTab === 'Select Topic' ? 'cursor-pointer' : ''}`}
        >
          Select Friend
        </button>
        <button
          className={`flex-1 py-3 text-center text-sm transition-all ${selectedTab === 'Select Topic'
            ? 'border-b-2 border-[#6374FA] text-[#6374FA] font-medium bg-selectedOptionBackground'
            : 'border-b-2 border-border text-[#B9B9B9] bg-cardBackground'
            } cursor-not-allowed`}
        >
          Select Topic
        </button>
      </div>

      <div className="flex-1 flex flex-col">
        {selectedTab === 'Select Friend' ? (
          <div className="p-4 flex-1">{renderContent()}</div>
        ) : (
          <div className="flex-1 flex flex-col">
            <div className="flex-1 ">
              <ScrollArea className="h-[70vh] md:h-[74vh] p-4">
                <div className="flex flex-col gap-2">
                  {subjects.map((option) => (
                    <label
                      key={option.value}
                      className={`flex items-center justify-between px-4 py-3 rounded-lg cursor-pointer 
                          ${selectedTopic === option.value
                                ? colorScheme === 'dark'
                                  ? 'bg-[#1B3260] border-none'
                                  : 'bg-[#EBF3FF] border-none'
                                : colorScheme === 'dark'
                                  ? 'bg-background border border-border'
                                  : 'bg-cardBackground border border-border'
                              }`}
                          >
                      <div className="flex items-center">
                        {icons[option.value] && <div className="mr-2">{icons[option.value]}</div>}
                        <span className="text-base">{option.label}</span>
                      </div>
                      <input
                        type="radio"
                        name="topic"
                        value={option.value}
                        checked={selectedTopic === option.value}
                        onChange={() => setSelectedTopic(option.value)}
                        className="transform scale-150"
                      />
                    </label>
                  ))}
                </div>
              </ScrollArea>
            </div>
          </div>
        )}

        <div className="p-4 mb-[3em] md:mb-0 md:fixed md:bottom-0 md:left-1/2 md:transform md:-translate-x-1/2 md:w-full md:max-w-[28em] bg-background">
          <div className="flex justify-between gap-3">
            {selectedTab === 'Select Friend' ? (
              <>
                <button
                  onClick={() => navigate(-1)}
                  className="flex-1 py-3 px-6 border border-[#E5E5E5] rounded-full text-sm font-medium"
                >
                  Go back
                </button>
                <button
                  onClick={handleContinue}
                  disabled={!selectedFriend}
                  className={`flex-1 py-3 px-6 rounded-full text-background text-sm font-medium ${selectedFriend
                    ? 'bg-enableButtonBackground'
                    : 'bg-disableButtonBackground cursor-not-allowed'
                    }`}
                >
                  Continue
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => setSelectedTab('Select Friend')}
                  className="flex-1 py-3 px-6 border border-[#E5E5E5] rounded-full text-sm font-medium"
                >
                  Go back
                </button>
                <button
                  onClick={handleSendDuelInvite}
                  disabled={!selectedTopic}
                  className={`flex-1 py-3 px-6 rounded-full text-background text-sm font-medium ${selectedTopic
                    ? 'bg-enableButtonBackground'
                    : 'bg-disableButtonBackground cursor-not-allowed'
                    }`}
                >
                  Send Duel Invite
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {showAddFriendModal && <AddFriendModal onClose={() => setShowAddFriendModal(false)} onShowUserIdInfo={handleShowUserIdInfo} />}

      {showUserIdInfoModal && <UserIdInfoModal onClose={() => setShowUserIdInfoModal(false)} />}
      <DeleteFriendModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
        friendName={friendToDelete?.name || friendToDelete?.username}
      />
      {showInviteModal && inviteDetails && (
        <InviteFriendModal
          friendName={inviteDetails.friendName}
          friendScore={inviteDetails.friendScore}
          topic={inviteDetails.topic}
          onAccept={handleAcceptInvite}
          onDecline={handleDeclineInvite}
          isLoading={false}
          friendPhoto={inviteDetails.friendPhoto}
        />
      )}
    </div>
  );
};

export default DuelWithFriends;
