import React from 'react';
import Lottie from 'react-lottie';
import * as successAnimation from '../assets/json/report_success.json';

const ReportModaContent = ({
  isOpen,
  isMobile,
  closeModal,
  isReportSubmited,
  resportData,
  setResportData,
  details,
  onChange,
  handleResportSubmit,
}) => {
  // Default Lottie animation options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // If the modal is not open, return null
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] flex justify-center items-end z-[1000]">
      <div className={`bg-background  px-5 py-5 rounded-t-xl w-full text-center shadow-[0px_4px_20px_rgba(0,0,0,0.1)] mb-0 ${isMobile ? 'max-w-[28rem]' : 'max-w-[22vw]'}`}>
        <div className="flex items-center justify-center w-full">
          <span className="block h-[6px] w-[89px] bg-[#E8E8E8] rounded-[50px] cursor-pointer" onClick={closeModal}></span>
        </div>
        <button
          onClick={closeModal}
          className="relative right-2 border-none bg-transparent cursor-pointer text-[20px] font-bold text-text w-full flex justify-end"
        >
          &times;
        </button>

        {/* Conditionally render content based on report submission state */}
        {!isReportSubmited ? (
          <div>
            <h3 className="text-left text-[18px] font-bold m-6">Report Question</h3>
            <div className="space-y-6">
              {[
                'Wrong category',
                'Answer is incorrect',
                'Incomplete question',
                'Mistake in question',
                'Mistake in options',
                'Poorly formatted question',
                'Poorly formatted options',
                'Other',
              ].map((item, index) => (
                <div key={index} className="flex items-center">
                  <input
                    type="radio"
                    value={item}
                    checked={resportData === item}
                    onChange={() => setResportData(item)}
                   className="mr-4 w-5 h-5"
                  />
                  <label>{item}</label>
                </div>
              ))}
            </div>

            {resportData === 'Other' && (
              <div>
                <p className="text-left mt-4">Other reason*</p>
                <textarea
                  placeholder="Provide details about your issue here..."
                  value={details}
                  rows={4}
                  className="w-full mt-2 p-2 border border-[#ccc] rounded-sm text-[14px]"
                  onChange={onChange}
                />
              </div>
            )}

            <div className="mt-6">
              <button
                className={`w-full px-4 py-2 rounded-xl font-medium ${
                  resportData ? 'bg-black text-white' : 'bg-[#ccc] text-[#333]'
                }`}
                onClick={handleResportSubmit}
              >
                Submit Feedback
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h3 className="mb-4">Report Submitted</h3>
            <p className="mb-4">Thank you! Your report has been submitted.</p>
            <Lottie options={defaultOptions} height={200} width={200} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportModaContent;