import React, { useState, useRef } from 'react';
import { Box, Flex, Button, Text, Notification } from '@mantine/core';
import { ArrowLeft, Edit2, ProfilePic } from '../../assets/images'; // Assuming these are your icons
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../stores/userStore';
import { useTheme } from '../../context/themeProvider';

const EditProfile = () => {
  const { userData, updateUserData } = userStore();
  const [name, setName] = useState(userData.name || '');
  const [countryCode] = useState(userData.countryCode || '+91');
  const [phoneNumber] = useState(userData.phone || '');
  const [verbosity, setVerbosity] = useState(userData.verbosity || 'Verbose');
  const [profilePic, setProfilePic] = useState(userData.photo || ProfilePic);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { colorScheme } = useTheme();

  const handleSaveChanges = async () => {
    const formData = new FormData();
    formData.append('user_id', userData.user || '');
    formData.append('name', name);
    formData.append('verbosity', verbosity);
  
    if (fileInputRef.current && fileInputRef.current.files[0]) {
      formData.append('photo', fileInputRef.current.files[0]);
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/edit-profile/`, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
  
      if (response.ok && result && result.data) {
        setProfilePic(result.data.photo || ProfilePic);
        updateUserData({
          ...userData,
          name: result.data.name,
          photo: result.data.photo,
        });
        setNotificationMessage('Profile updated successfully!');
        setIsError(false);
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
      } else {
        throw new Error(result.message || 'Failed to update profile.');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setNotificationMessage('Error updating profile.');
      setIsError(true);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={`h-screen w-full flex flex-col ${
      colorScheme === 'dark' ? 'bg-background' : 'bg-white'
    }`}>
      {showNotification && (
        <Notification
          title={isError ? "Error" : "Success"}
          color={isError ? "red" : "green"}
          className="fixed top-[10%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-[300px] text-center"
        >
          {notificationMessage}
        </Notification>
      )}

      <div className="flex flex-col items-center w-full p-5 flex-1 overflow-y-auto">
        {/* Header */}
        <div className={`flex items-center w-full pb-5 ${
          colorScheme === 'dark' ? 'text-text' : ''
        }`}>
          <button
            onClick={() => navigate(-1)}
            className="bg-transparent p-2"
          >
            <img src={ArrowLeft} alt="Back" className="w-6" />
          </button>
          <span className="ml-4 text-xl font-bold">Edit Profile</span>
        </div>

        {/* Profile Picture */}
        <div className="relative w-[100px] h-[100px] mb-8">
          <img
            src={profilePic}
            alt="Profile"
            className="w-full h-full rounded-full object-cover"
          />
          <button
            className={`absolute bottom-0 right-0 p-1 rounded-full shadow-lg ${
              colorScheme === 'dark' ? 'bg-cardBackground' : 'bg-white'
            }`}
            onClick={() => fileInputRef.current.click()}
          >
            <img src={Edit2} alt="Edit Icon" className="w-5" />
          </button>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            onChange={handleFileChange}
          />
        </div>

        {/* Form */}
        <div className="w-full max-w-[400px]">
          {/* Name Input */}
          <label className={`block text-sm font-bold mb-2 ${
            colorScheme === 'dark' ? 'text-text' : ''
          }`}>
            Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={`w-full p-3 mb-5 rounded-xl text-base ${
              colorScheme === 'dark' 
                ? 'bg-cardBackground text-text border-borderColor' 
                : 'border border-gray-300'
            }`}
          />

          {/* Phone Number */}
          <label className={`block text-sm font-bold mb-2 ${
            colorScheme === 'dark' ? 'text-text' : ''
          }`}>
            Phone Number
          </label>
          <div className={`w-full p-3 mb-5 rounded-xl flex items-center ${
            colorScheme === 'dark' 
              ? 'bg-cardBackground text-text border-borderColor' 
              : 'border border-gray-300'
          }`}>
            <span className="opacity-70">{countryCode}</span>
            <span className="mx-3 text-xl opacity-70">|</span>
            <span className="opacity-30">{phoneNumber}</span>
          </div>

          {/* AI Response Verbosity */}
          <label className={`block text-sm font-bold mb-2 ${
            colorScheme === 'dark' ? 'text-text' : ''
          }`}>
            AI Response Verbosity
          </label>
          <select
            value={verbosity}
            onChange={(e) => setVerbosity(e.target.value)}
            className={`w-full p-3 mb-10 rounded-xl text-base ${
              colorScheme === 'dark' 
                ? 'bg-cardBackground text-text border-borderColor' 
                : 'border border-gray-300'
            }`}
          >
            <option value="Verbose">Verbose</option>
            <option value="Concise">Concise</option>
          </select>
        </div>
      </div>

      {/* Footer Buttons */}
      <div className={`p-5 md:static fixed bottom-[3em] left-0 right-0 ${
      colorScheme === 'dark' ? 'bg-background' : 'bg-white'
    }`}>
      <div className="flex gap-3 max-w-[400px] mx-auto">
        <button 
          onClick={() => navigate(-1)} 
          className={`flex-1 py-[10px] rounded-full border ${
            colorScheme === 'dark' 
              ? 'border-borderColor text-text bg-cardBackground' 
              : 'border-[#E6E6E6] text-black bg-white'
          }`}
        >
          Cancel
        </button>
        <button 
          onClick={handleSaveChanges} 
          className={`flex-1 py-[10px] rounded-full ${
            colorScheme === 'dark' 
              ? 'bg-white text-black'
              : 'bg-black text-white'
          }`}
        >
          Save Changes
        </button>
      </div>
    </div>
    </div>
  );
};

export default EditProfile;
