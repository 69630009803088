import React from 'react';

const ReportQuestionModal = ({ isOpened, onCancel, onReport }) => {
  const isResponsiveMobile =
    window.innerHeight <= 700 && window.innerWidth <= 400
      ? 'w-[23em]'
      : window.innerWidth > 400 && window.innerWidth < 900
      ? 'w-[26em]'
      : 'w-[28em]';

  if (!isOpened) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-end justify-center">
      <div className={`bg-cardBackground rounded-t-xl p-8 shadow-[0_-2px_10px_rgba(0,_0,_0,_0.1)] ${isResponsiveMobile}`}>
        <div className="flex justify-center items-center w-full">
          <span
            className="w-[89px] h-[6px] bg-[#E8E8E8] rounded-full cursor-pointer"
            onClick={onCancel}
          ></span>
        </div>
        <h2 className="text-xl font-semibold mt-3 mb-3 text-center">Report Question</h2>
        <p className="text-text mb-6 text-center text-base leading-6 tracking-wide">
          Are you sure you want to report this question?
        </p>
        <div className="flex justify-between gap-3">
          <button
            onClick={onCancel}
            className="flex-1 py-3 px-4 rounded-[25px] text-base font-medium text-text border border-border transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onReport}
            className="flex-1 py-3 px-4 rounded-[25px] text-base font-medium bg-[#e74c3c] text-white flex items-center justify-center hover:bg-[#c0392b] transition-colors"
          >
            <span className="bg-white text-[#e74c3c] w-5 h-5 rounded-full flex items-center justify-center font-bold mr-2 text-sm">
              !
            </span>
            Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportQuestionModal;
